//@ts-check
import TicketAction from "./TicketAction";
import TicketDefinition from "./TicketDefinition";
import DefaultStoreUtil from "../../util/DefaultStoreUtil";

const Ticket = {
    ticket: DefaultStoreUtil.defaultReducerFunction(
        TicketAction.CATEGORY,
        TicketDefinition.defaultState
    )
};

export default Ticket;
