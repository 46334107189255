//@ts-check
import DefaultStoreUtil from "../../util/DefaultStoreUtil";

class SmartviewAction {
    static CATEGORY = "SMARTVIEW";

    // #region overview types
    static ORDERS_LOADING = "SMARTVIEW_ORDER_LOADING";
    static ORDERS_DATATABLE = "SMARTVIEW_ORDER_DATATABLE";
    static ORDERS_CLEAR = "SMARTVIEW_ORDER_CLEAR";
    // #endregion

    // #region ticket actions
    static TICKET_LOADING = "SMARTVIEW_TICKET_LOADING";
    static TICKET_DATA = "SMARTVIEW_TICKET_DATA";
    static TICKET_TRAILER = "SMARTVIEW_TICKET_TRAILER";
    static TICKET_TRAILER_LOADING = "SMARTVIEW_TICKET_TRAILER_LOADING";
    static TICKET_CLEAR = "SMARTVIEW_TICKET_CLEAR";
    // #endregion

    // #region order actions
    static ORDER_LOADING = "SMARTVIEW_ORDER_LOADING";
    static ORDER_DATA = "SMARTVIEW_ORDER_DATA";
    static ORDER_TRAILER = "SMARTVIEW_ORDER_TRAILER";
    static ORDER_TRAILER_LOADING = "SMARTVIEW_ORDER_TRAILER_LOADING";
    static ORDER_CLEAR = "SMARTVIEW_ORDER_CLEAR";
    //#endregion

    // #region Action
    static create = DefaultStoreUtil.defaultCreateAction(SmartviewAction.CATEGORY);
    // #endregion
}

export default SmartviewAction;
