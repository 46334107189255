import DefaultStoreUtil from "../../util/DefaultStoreUtil";

class TasksAction {
    static CATEGORY = "TASK";

    static INFO = "TASK_INFO";

    // #region Tasks Overview
    static DATATABLE = "TASKS_DATATABLE";
    static SELECTION = "TASKS_SELECTION";
    static LOADING = "TASKS_LOADING";
    static CLEAR = "TASKS_CLEAR";
    // #endregion

    static TASKS_TASKS = "TASKS_TASKS";

    static create = DefaultStoreUtil.defaultCreateAction(TasksAction.CATEGORY);
}

export default TasksAction;
