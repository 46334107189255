class UserDefinition {
    static defaultDialogState = {
        roles: [],
        element: {
            uuid: null,
            email: "",
            password: null,
            firstname: "",
            lastname: "",
            role: "",
            active: 1,
            type: "",
            passwordExpired: 0,
            contactUUID: null
        }
    };

    static defaultFilter = null;

    static defaultState = {
        filter: UserDefinition.defaultFilter,
        sort: null,
        rows: 10,
        first: 0,
        totalRecords: 0,
        selection: "",
        entries: [],
        dialog: UserDefinition.defaultUserDialogState
    };
}

export default UserDefinition;
