import DefaultStoreUtil from "../../util/DefaultStoreUtil";

class DebtorAction {
    static CATEGORY = "DEBTOR";

    static LOADING = "DEBTOR_LOADING";
    static DATATABLE = "DEBTOR_DATATABLE";
    static SELECTION = "DEBTOR_SELECTION";
    static STATES = "DEBTOR_STATES";
    static CLEAR = "DEBTOR_CLEAR";

    // #region detail types
    static DETAIL_DEBTOR = "DEBTOR_DETAIL_DEBTOR";
    static DETAIL_DOCTYPES = "DEBTOR_DETAIL_DOCTYPES";
    static DETAIL_OPEN_ELEMENTS = "DEBTOR_DETAIL_OPEN_ELEMENTS";
    static DETAIL_IN_PROGRESS = "DEBTOR_DETAIL_IN_PROGRESS";
    static DETAIL_NO_BILLING = "DEBTOR_DETAIL_NO_BILLING";
    static DETAIL_CLOSE = "DEBTOR_DETAIL_CLOSE";
    static DETAIL_CANCEL = "DEBTOR_DETAIL_CANCEL";
    static DETAIL_LOADING = "DEBTOR_DETAIL_LOADING";
    static DETAIL_CLEAR = "DEBTOR_DETAIL_CLEAR";

    static create = DefaultStoreUtil.defaultCreateAction(DebtorAction.CATEGORY);
}

export default DebtorAction;
