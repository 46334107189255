class PartnerOrderDefinition {
    // #region Order Detail
    static defaultDetailState = {
        id: "",
        element: {
            internalID: 0,
            id: "",
            state: "",
            condition: {
                assigned: false,
                repairDone: false,
                done: false,
                reclaimed: false,
                canceled: false
            },
            licensePlate: "",
            vin: "",
            type: "",
            brand: "",
            partnerUUID: "",
            partnerContactUUID: "",
            dispoUserUUID: "",
            userUUID: "",
            workshopUUID: "",
            workshopContactUUID: "",
            assignDateTime: "",
            repairDateTime: "",
            completionDateTime: "",
            reminderDateTime: "",
            billingReference: "",
            note: "",
            sendAck: 0,
            newMsg: 0,
            currencyUUID: "",
            solvency: {
                amount: 0,
                date: "",
                critical: false
            },
            progress: {
                accepted: false,
                invoiceMail: false,
                invoiceMailStatus: 0,
                addressMail: false,
                addressMailStatus: 0,
                billingInternal: false,
                billingInternalStatus: 0,
                billingExternal: false,
                billingExternalStatus: 0
            },
            invoice: {
                number: "",
                date: "",
                amount: 0,
                vat: "",
                realCosts: 0,
                discount: "",
                billedExpenses: 0,
                billedExpensesEuro: 0,
                billedAmount: 0
            },
            breakdownInfo: {
                extent: 0,
                location: {
                    country: "",
                    place: "",
                    zip: "",
                    street: ""
                },
                drivingDirection: "",
                loadStatus: false,
                contactPhone: "",
                repairDate: "",
                internalNote: ""
            },
            createdAt: "",
            updatedAt: "",
            user: {
                uuid: "",
                email: "",
                active: 0,
                role: "",
                type: "",
                passwordExpired: 0,
                creDat: "",
                modDat: "",
                seenDat: "",
                contact: {
                    uuid: "",
                    addressUUID: "",
                    userUUID: "",
                    title: "",
                    firstname: "",
                    lastname: "",
                    location: "",
                    phone: "",
                    mobile: "",
                    email: "",
                    active: 0,
                    creDat: "",
                    modDat: ""
                }
            },
            dispoUser: {
                uuid: "",
                addressUUID: "",
                userUUID: "",
                title: "",
                firstname: "",
                lastname: "",
                location: "",
                phone: "",
                mobile: "",
                email: "",
                active: 0,
                creDat: "",
                modDat: ""
            },
            partner: {
                uuid: "",
                referenceID: "",
                customerType: "",
                locationOwner: "",
                name1: "",
                name2: "",
                name3: "",
                shortcut: "",
                phone: "",
                email: "",
                web: "",
                location: {
                    country: "",
                    place: "",
                    zip: "",
                    street: ""
                },
                coordinates: {
                    latitude: "",
                    longitude: ""
                },
                active: 0,
                note: "",
                creDat: "",
                modDat: ""
            },
            partnerContact: {
                uuid: "",
                addressUUID: "",
                userUUID: "",
                title: "",
                firstname: "",
                lastname: "",
                location: "",
                phone: "",
                mobile: "",
                email: "",
                active: 0,
                creDat: "",
                modDat: ""
            },
            damages: [],
            files: [],
            workshop: {
                uuid: "",
                referenceID: "",
                customerType: "",
                locationOwner: "",
                name1: "",
                name2: "",
                name3: "",
                shortcut: "",
                phone: "",
                email: "",
                web: "",
                location: {
                    country: "",
                    place: "",
                    zip: "",
                    street: ""
                },
                coordinates: {
                    latitude: "",
                    longitude: ""
                },
                active: 0,
                note: "",
                creDat: "",
                modDat: ""
            },
            workshopContact: {
                uuid: "",
                addressUUID: "",
                userUUID: "",
                title: "",
                firstname: "",
                lastname: "",
                location: "",
                phone: "",
                mobile: "",
                email: "",
                active: 0,
                creDat: "",
                modDat: ""
            },
            newMsgUser: {
                uuid: "",
                email: "",
                active: 0,
                role: "",
                type: "",
                passwordExpired: 0,
                creDat: "",
                modDat: "",
                seenDat: "",
                contact: {
                    uuid: "",
                    addressUUID: "",
                    userUUID: "",
                    title: "",
                    firstname: "",
                    lastname: "",
                    location: "",
                    phone: "",
                    mobile: "",
                    email: "",
                    active: 0,
                    creDat: "",
                    modDat: ""
                }
            },
            currency: {
                uuid: "",
                name: "",
                symbol: "",
                code: "",
                rate: 0
            }
        },
        changes: null
    };
    // #endregion

    // #region Order Overview
    static defaultFilter = null;

    static defaultState = {
        id: null,
        entries: null,
        selectionIDs: [],
        sort: {sortField: "id", sortOrder: -1},
        filter: PartnerOrderDefinition.defaultFilter,
        detail: PartnerOrderDefinition.defaultDetailState
    };
    // #endregion
}

export default PartnerOrderDefinition;
