import DefaultStoreUtil from "../../util/DefaultStoreUtil";

class ShipmentAction {
    static CATEGORY = "SHIPMENT";

    static LOADING = "SHIPMENT_LOADING";
    static DATATABLE = "SHIPMENT_DATATABLE";
    static SELECTION = "SHIPMENT_SELECTION";
    static CLEAR = "SHIPMENT_CLEAR";

    static create = DefaultStoreUtil.defaultCreateAction(ShipmentAction.CATEGORY);
}

export default ShipmentAction;
