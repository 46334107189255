import DefaultStoreUtil from "../../util/DefaultStoreUtil";

class NewsAction {
    static CATEGORY = "NEWS";

    // #region News Overview
    static DATATABLE = "NEWS_DATATABLE";
    static SELECTION = "NEWS_SELECTION";
    static LOADING = "NEWS_LOADING";
    static CLEAR = "NEWS_CLEAR";
    // #endregion

    // #region News Dialog
    static DIALOG_NEWS = "NEWS_DIALOG";
    static DIALOG_CLEAR = "NEWS:CLEAR";
    // #endregion

    // #region Actions
    static create = DefaultStoreUtil.defaultCreateAction(NewsAction.CATEGORY);
}

export default NewsAction;
