//@ts-check
import {Store} from "../store/Store";
import ObjectUtil from "./ObjectUtil";

const promiseHandlerMap = {};
let promiseId = 0;

/**
 * @typedef {Object} PromiseObject A PromiseObject is a wrapping container for a promising dispatch that holds all the relevant information.
 * Such as the id, action and promise itself.
 * @property {number} id - The id to identify the promise with
 * @property {Object} action - The action that was dispatched
 * @property {Promise} promise - The promise that was created by dispatchign the action. The result of the promise is an object containing the promiseObj itself and the results of the triggered action.
 */

class PromiseHandler {
    static create() {
        promiseHandlerMap[++promiseId] = new PromiseHandler();
        return promiseId;
    }

    add(changeCall) {
        if (this.processed) {
            console.error("Cannot add callbacks to the PromiseHandler once processed!");
            return;
        }
        if (!this.callbacks) {
            this.callbacks = [];
        }
        this.callbacks.push(Promise.resolve(changeCall));
    }

    async resolveAll() {
        this.processed = true;
        const resolution = await (this.callbacks ? Promise.all(this.callbacks) : Promise.resolve());
        return resolution;
    }
}

export function observeStore(selectionFunction, onChange, ...changeTypes) {
    let currentState;
    function handleChange() {
        const state = Store.getState();
        if (
            changeTypes.length === 0 ||
            (state.changeType && changeTypes.includes(state.changeType))
        ) {
            // forward state to listeners
            const nextState = selectionFunction(state);
            if (nextState !== currentState) {
                currentState = nextState;
                const promiseHandler = promiseHandlerMap[state.promise];
                if (onChange) {
                    const changeCall = onChange(currentState, state.actionType);
                    if (promiseHandler) {
                        promiseHandler.add(changeCall);
                    }
                } else {
                    console.warn(
                        "Lost call, since the onChange function seems not to be there yet... Action:" +
                            state.ActionType
                    );
                }
            }
        }
    }

    return {
        unsubscribe: Store.subscribe(handleChange),
        dispatch(action) {
            Store.dispatch(action);
        },
        /**
         * Returns a promise description object containing a promise, that will be resolved once all the observing functions are called (or fulfilled if they themselves return a promise)
         * @returns {PromiseObject} a promise object containing its id, the dispatched action and the promise itself.
         * @param {Object} action the action object that should be dispatched
         */
        promisingDispatch(action) {
            const promiseId = PromiseHandler.create();
            action.promise = promiseId;
            Store.dispatch(action);
            const promiseObj = {
                id: promiseId,
                action: action,
                promise: new Promise((resolve, reject) => {
                    try {
                        promiseHandlerMap[promiseId]
                            .resolveAll()
                            .then((results) =>
                                resolve({
                                    promiseObj: {id: promiseId, action: action},
                                    results: results
                                })
                            )
                            .catch((err) => reject(err));
                    } catch (err) {
                        reject(err);
                    }
                })
            };
            return promiseObj;
        },
        getState() {
            return selectionFunction(Store.getState());
        }
    };
}

export function copyState(draft, action) {
    let target = draft;
    if ("subState" in action) {
        target = draft[action.subState];
    }
    ObjectUtil.shallowCopyChildren(action.data, target);
}

export function getState(selectionFunction) {
    return selectionFunction(Store.getState());
}

export const sessionSelectionFunction = (state) => (state.session ? state.session : null);
