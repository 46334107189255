import React from "react";
import {Sidebar} from "primereact/sidebar";
import {Button} from "primereact/button";
import {ProgressBar} from "primereact/progressbar";
import MessagesUtil from "../../util/MessagesUtil";
import {Features, Permissions, hasPermission, isJWTDev, Roles} from "../../util/PermissionUtil";
import DropdownField from "../common/fields/DropdownField";
import InputField from "../common/fields/InputField";
import InputNumber from "../common/fields/InputNumber";
import config from "../../config";
import TicketRequest from "../../requests/TicketRequest";
import {Checkbox} from "primereact/checkbox";
import TranslationRequest from "../../requests/TranslationRequest";
import ObjectUtil from "../../util/ObjectUtil";
import ErrorUtil from "../../util/ErrorUtil";
import TranslationDialog from "../../views/management/translation/TranslationDialog";
import {observeStore, sessionSelectionFunction} from "../../util/StoreUtil";
import ChangeType from "../../store/changeType/ChangeType";
import {Store} from "../../store/Store";
import SessionAction from "../../store/session/SessionAction";
import UserSearchField from "../common/fields/autocomplete/UserSearchField";
import AddressRequest from "../../requests/AddressRequest";
import UserRequest from "../../requests/UserRequest";
import IdentifierDropdownField from "../common/fields/IdentifierDropdownField";

class TestToolbox extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            translationDialogVisible: false,
            messageType: MessagesUtil.TYPES.GLOBAL,
            messageSeverity: MessagesUtil.LEVELS.SUCCESS,
            messageTitle: "Black knight",
            messageText: "'Tis but a test!",
            email: "developer.fleet@deepscale.io",
            testCount: 1,
            loginTestFinished: 0,
            translation: {},
            translationLanguages: {},
            translationEditUUID: null,
            translationEditType: ""
        };
    }

    componentDidMount() {
        window.addEventListener("keydown", (e) => {
            if (hasPermission(Permissions.edit, Features.development)) {
                if (e.ctrlKey && e.key === "y") {
                    const selection = window
                        .getSelection()
                        .toString()
                        .replace(/:/g, "");
                    this.setState({
                        visible: selection.length ? true : !this.state.visible,
                        translation: {
                            label: selection
                        }
                    });
                }
            }
        });

        this.store = observeStore(sessionSelectionFunction, this.onStoreChange, ChangeType.UPDATE);
        this.loadContactInformation();
        this.unmounted = false;
    }

    onStoreChange = (state) => {
        if (!this.unmounted) {
            this.setState(
                {
                    session: state
                },
                this.loadContactInformation
            );
        }
    };

    loadContactInformation = () => {
        if (this.state.session && this.state.session.user) {
            AddressRequest.loadContact(this.state.session.user).then((response) =>
                this.setState({contact: response})
            );
        }
    };

    onChange = (changeEvent) => {
        const name = changeEvent.name || (changeEvent.target ? changeEvent.target.name : null);
        this.setState({
            [name]:
                changeEvent.checked ||
                changeEvent.value ||
                (changeEvent.target ? changeEvent.target.value : null)
        });
    };

    onTranslationChange = (changeEvent) => {
        const name = changeEvent.target.name;
        const value = changeEvent.target.value;
        if (name.includes("value_")) {
            const langs = ObjectUtil.deepDataCopy(this.state.translationLanguages);
            langs[name.split("_")[1]] = value;
            this.setState({translationLanguages: langs});
        } else {
            const translation = ObjectUtil.deepDataCopy(this.state.translation);
            translation[name] = value;
            this.setState({
                translation: translation
            });
        }
    };

    saveTranslation = (target = "test") => {
        const onErr = (err) => ErrorUtil.handleError("Error saving to " + target, err, true);
        const onSucc = (res) => {
            res &&
                MessagesUtil.send(
                    MessagesUtil.TYPES.GLOBAL,
                    MessagesUtil.LEVELS.SUCCESS,
                    "Successfully saved to " + target
                );
        };

        const translation = ObjectUtil.deepDataCopy(this.state.translation);
        translation.values = Object.keys(this.state.translationLanguages).map((key) => {
            return {
                language: key,
                value: this.state.translationLanguages[key]
            };
        });
        translation.namespace = "fleet";

        const filter = {
            filter: {
                label: {
                    value: translation.label,
                    matchMode: "="
                }
            }
        };

        TranslationRequest.loadTranslations(filter).then((res) => {
            if (res && res.data && res.data.length && res.data[0].uuid) {
                this.setState({
                    translationEditUUID: res.data[0].uuid,
                    translationEditType: target,
                    translationDialogVisible: true
                });
            } else {
                TranslationRequest.persistTranslation(null, translation)
                    .catch(onErr)
                    .then(onSucc);
            }
        });
    };

    render() {
        return (
            isJWTDev() && (
                <div>
                    <Sidebar
                        visible={this.state.visible}
                        style={{height: "300px"}}
                        position="bottom"
                        modal={false}
                        onHide={() => this.setState({visible: false})}
                    >
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between"
                            }}
                        >
                            {this.renderSessionTool()}
                            {this.renderMessageTest()}
                            {this.renderStressTest()}
                            {this.renderTranslationTool()}
                        </div>
                    </Sidebar>
                    <Button
                        icon="pi pi-md-developer-board"
                        style={{
                            position: "fixed",
                            width: "50px",
                            height: "50px",
                            left: "5px",
                            bottom: "5px",
                            zIndex: 100
                        }}
                        onClick={() => this.setState({visible: true})}
                    />
                    {this.state.translationDialogVisible && this.renderTranslationDialog()}
                </div>
            )
        );
    }

    renderTranslationDialog = () => {
        return (
            <TranslationDialog
                uuid={this.state.translationEditUUID}
                type={this.state.translationEditType}
                onHide={() => this.setState({translationDialogVisible: false})}
                edit
            />
        );
    };

    renderSessionTool = () => {
        return (
            <div style={{width: "18%"}}>
                <h3>Session-Tool:</h3>
                <UserSearchField
                    name="user"
                    value={this.state.session}
                    label="Change User"
                    onSelect={this.onChange}
                    edit
                />
                <InputField
                    name="userUUID"
                    label="User UUID"
                    value={this.state.user && this.state.user.userUUID}
                />
                <IdentifierDropdownField
                    name="role"
                    label="Role"
                    value={
                        this.state.role ||
                        (this.state.user && {id: this.state.user.role}) || {
                            id: this.state.session.role
                        }
                    }
                    identifier="id"
                    optionLabel="id"
                    options={Object.values(Roles)}
                    onChange={this.onChange}
                    edit
                />
                <Button
                    label="Update"
                    style={{width: "calc(100% - 50px)"}}
                    onClick={() => {
                        if (this.state.user && this.state.user.userUUID) {
                            const customRole = this.state.role && this.state.role.id;
                            Promise.all([
                                UserRequest.loadUser(this.state.user.userUUID),
                                AddressRequest.searchContact({
                                    name: "userUUID",
                                    value: this.state.user.userUUID,
                                    matchMode: "="
                                })
                            ]).then((response) => {
                                this.store.dispatch(
                                    SessionAction.create(
                                        ChangeType.UPDATE,
                                        SessionAction.INFORMATION,
                                        {
                                            user: response[0].uuid,
                                            firstname: response[0].firstname,
                                            lastname: response[0].lastname,
                                            email: response[0].email,
                                            addressUUID: response[0].addressUUID,
                                            type: response[0].type,
                                            role: customRole || response[0].role,
                                            contact:
                                                response[1] && response[1].length
                                                    ? response[1][0]
                                                    : null
                                        }
                                    )
                                );
                            });
                        } else if (this.state.role && this.state.role.id) {
                            this.store.dispatch(
                                SessionAction.create(ChangeType.UPDATE, SessionAction.INFORMATION, {
                                    role: this.state.role.id
                                })
                            );
                        }
                    }}
                    disabled={
                        (!this.state.user || !this.state.user.userUUID) &&
                        (!this.state.role || !this.state.role.id)
                    }
                />
                <div style={{height: "0.5em"}} />
                <Button
                    label="Reset"
                    style={{width: "calc(100% - 50px)"}}
                    onClick={() => {
                        this.store.dispatch(
                            SessionAction.create(
                                ChangeType.UPDATE,
                                SessionAction.LOGIN,
                                {jwt: null},
                                {jwt: Store.getState().session.jwt}
                            )
                        );
                        this.setState({user: null, role: null});
                    }}
                />
            </div>
        );
    };

    renderMessageTest = () => {
        return (
            <div style={{width: "18%"}}>
                <h3>Message-System:</h3>
                <DropdownField
                    name="messageType"
                    edit={true}
                    label="Message Type"
                    value={this.state.messageType}
                    options={Object.keys(MessagesUtil.TYPES).map((key) => {
                        return {
                            label: key,
                            value: MessagesUtil.TYPES[key]
                        };
                    })}
                    onChange={this.onChange}
                />
                <DropdownField
                    name="messageSeverity"
                    edit={true}
                    label="Message Severity"
                    value={this.state.messageSeverity}
                    options={Object.keys(MessagesUtil.LEVELS).map((key) => {
                        return {
                            label: key,
                            value: MessagesUtil.LEVELS[key]
                        };
                    })}
                    onChange={this.onChange}
                />
                <InputField
                    name="messageTitle"
                    edit={true}
                    label="Message Title"
                    value={this.state.messageTitle}
                    onChange={this.onChange}
                />
                <InputField
                    name="messageText"
                    edit={true}
                    label="Message Text"
                    value={this.state.messageText}
                    onChange={this.onChange}
                />
                <Button
                    label="Send"
                    style={{width: "calc(100% - 50px)"}}
                    onClick={() =>
                        MessagesUtil.send(
                            this.state.messageType,
                            this.state.messageSeverity,
                            this.state.messageTitle,
                            this.state.messageText
                        )
                    }
                />
            </div>
        );
    };

    renderStressTest = () => {
        return (
            <div style={{width: "36%", display: "flex"}} className="f-devtools">
                <div style={{flexBasis: "400px"}}>
                    <h3>Stress-Tests:</h3>
                    <InputField
                        name="email"
                        edit={false}
                        label="Email"
                        value={this.state.email}
                        onChange={this.onChange}
                    />
                    <InputField
                        name="password"
                        edit={!this.testOngoing()}
                        label="Password"
                        value={this.state.password}
                        onChange={this.onChange}
                    />
                    <InputNumber
                        name="testCount"
                        edit={!this.testOngoing()}
                        label="RequestCount"
                        value={this.state.testCount}
                        onChange={this.onChange}
                    />
                    <div
                        onClick={() => {
                            if (!this.testOngoing()) {
                                this.onChange({name: "logTest", value: !this.state.logTest});
                            }
                        }}
                    >
                        <Checkbox
                            name="logTest"
                            checked={this.state.logTest}
                            onChange={this.onChange}
                            disabled={this.testOngoing()}
                        />
                        <label htmlFor="all" className="p-checkbox-label">
                            Verbose log
                        </label>
                    </div>
                </div>
                <div style={{display: "flex", flexDirection: "column", flexGrow: "1"}}>
                    <div style={{height: "3em"}} />
                    {!this.state.loginStressTest ? (
                        <Button
                            label="Login only"
                            style={{width: "100%"}}
                            onClick={this.stressTestLogin}
                            disabled={this.state.testCount < 1}
                        />
                    ) : (
                        <ProgressBar
                            className="f-dynamic-progress"
                            value={(
                                (this.state.loginTestFinished / this.state.testCount) *
                                100
                            ).toFixed(0)}
                            showValue
                        />
                    )}
                    <div style={{height: "0.5em"}} />
                    {!this.state.ticketStressTest ? (
                        <Button
                            label="Login, then create and change ticket"
                            style={{width: "100%"}}
                            onClick={this.stressTestTicket}
                            disabled={this.state.testCount < 1}
                        />
                    ) : (
                        <ProgressBar
                            className="f-dynamic-progress"
                            value={(
                                (this.state.ticketTestFinished / this.state.testCount) *
                                100
                            ).toFixed(0)}
                            showValue
                        />
                    )}
                </div>
            </div>
        );
    };

    renderTranslationTool = () => {
        return (
            <div style={{width: "18%"}} className="f-devtools">
                <span
                    style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}
                >
                    <h3>Create Translation Label from Selection:</h3>
                    <span
                        className="pi-md-delete"
                        onClick={() =>
                            this.setState({
                                translation: {}
                            })
                        }
                        style={{cursor: "pointer"}}
                    />
                </span>
                <InputField
                    name="label"
                    label="Label"
                    value={this.state.translation.label || null}
                    onChange={this.onTranslationChange}
                    onLoaded={this.focusLabelField}
                />
                {["de", "en"].map((lang) => (
                    <InputField
                        key={lang}
                        name={"value_" + lang}
                        label={lang.toUpperCase()}
                        value={this.state.translationLanguages[lang] || ""}
                        onChange={this.onTranslationChange}
                    />
                ))}

                <InputField
                    name="note"
                    label="Note"
                    value={this.state.translation.note || ""}
                    onChange={this.onTranslationChange}
                />
                <Button
                    style={{width: "100%"}}
                    label="Save to Test DB"
                    onClick={() => this.saveTranslation("test")}
                    disabled={
                        !this.state.translation ||
                        !this.state.translation.label ||
                        !Object.keys(this.state.translationLanguages).length
                    }
                />
            </div>
        );
    };

    testOngoing = () => {
        return this.state.loginStressTest || this.state.ticketStressTest;
    };

    stressTestLogin = () => {
        this.setState({loginStressTest: true, loginTestFinished: 0});
        console.time("loginStressTest" + this.state.testCount);
        const test = [];
        for (let i = 0; i < this.state.testCount; i++) {
            test.push(
                this.triggerLogin(i).then(() => {
                    this.setState({loginTestFinished: this.state.loginTestFinished + 1});
                })
            );
        }
        Promise.all(test).then(() => {
            this.setState({loginStressTest: false});
            console.timeEnd("loginStressTest" + this.state.testCount);
        });
    };

    triggerLogin = this.triggerLogin.bind(this);
    async triggerLogin(i) {
        try {
            if (this.state.logTest) {
                console.log("Trigger login test " + i);
            }
            const res = await fetch(config.API_URL + "/user." + config.STACK_NAME + "/login", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    email: this.state.email,
                    password: this.state.password
                })
            });
            const json = res.ok ? await res.json() : null;
            if (!res.ok || (json && (json.error || !json.jwt))) {
                if (this.state.logTest) {
                    console.log("Login test " + i + " failed!");
                }
                return Promise.resolve();
            }
            if (this.state.logTest) {
                console.log("Login test " + i + " successfull!");
            }

            return Promise.resolve();
        } catch (e) {
            if (this.state.logTest) {
                console.log("Login test " + i + " failed!");
            }
            return Promise.resolve();
        }
    }

    stressTestTicket = () => {
        this.setState({ticketStressTest: true, ticketTestFinished: 0});
        console.time("ticketStressTest" + this.state.testCount);
        const test = [];
        for (let i = 0; i < this.state.testCount; i++) {
            test.push(
                this.triggerTicketTest(i).then(() => {
                    this.setState({ticketTestFinished: this.state.ticketTestFinished + 1});
                })
            );
        }
        Promise.all(test).then(() => {
            this.setState({ticketStressTest: false});
            console.timeEnd("ticketStressTest" + this.state.testCount);
        });
    };

    triggerTicketTest = this.triggerTicketTest.bind(this);
    async triggerTicketTest(testIndex) {
        try {
            if (this.state.logTest) {
                console.log("Trigger ticket test " + testIndex);
            }
            //Login
            const res = await fetch(config.API_URL + "/user." + config.STACK_NAME + "/login", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    email: this.state.email,
                    password: this.state.password
                })
            });
            const json = res.ok ? await res.json() : null;
            if (!res.ok || (json && (json.error || !json.jwt))) {
                if (this.state.logTest) {
                    console.log("Ticket test " + testIndex + " failed!");
                }
                return Promise.resolve();
            }
            //Create ticket
            const ticket = await TicketRequest.persistTicket(null, {
                // console.log({
                vin:
                    "STRESS" +
                    (Math.random() * 10000000000000)
                        .toFixed(0)
                        .slice(0, 14)
                        .padStart(14, "0"),
                licensePlate: "TEST1234",
                iluCode: "TEST0815",
                note: "STRESSTEST"
            });
            if (this.state.logTest) {
                console.log(testIndex + ": Created ticket " + ticket.uuid);
            }
            //Load possible states
            const states = await TicketRequest.loadTicketStates();
            //Switch through state changes
            for (let i = 0; i < states.length; i++) {
                const state = states[i];
                if (this.state.logTest) {
                    console.log(
                        testIndex +
                            ": Switch from ticket state" +
                            ticket.ticketState.label +
                            "to" +
                            state.label
                    );
                }
                ticket.ticketState = state;
                await TicketRequest.persistTicket(ticket.uuid, ticket);
            }
            if (this.state.logTest) {
                console.log(testIndex + ": Final ticket state: " + ticket.ticketState.label);
                console.log("Ticket test " + testIndex + " successfull!");
            }
            return Promise.resolve();
        } catch (e) {
            if (this.state.logTest) {
                console.log("Ticket test " + testIndex + " failed!");
            }
            return Promise.resolve();
        }
    }

    componentWillUnmount() {
        this.store.unsubscribe();
        this.unmounted = true;
    }
}

export default TestToolbox;
