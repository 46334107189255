import {Store} from "../store/Store";
import MessagesAction from "../store/messages/MessagesAction";

class MessagesUtil {
    static TYPES = {
        GLOBAL: "global",
        SITE: "site",
        DIALOG: "dialog"
    };

    static LEVELS = {
        SUCCESS: "success",
        INFO: "info",
        WARN: "warn",
        ERROR: "error"
    };

    static send(type, level, title, text, errorCode) {
        Store.dispatch(MessagesAction.send(type, level, title, text, errorCode));
    }
}

export default MessagesUtil;
