//@ts-check
import UriList from "../service/EndpointsList";
import {prioritizedRequest} from "../util/RequestUtil";

class TasksRequest {
    static async loadTasks(params) {
        const response = await prioritizedRequest(UriList.TASKS_TASKS, {
            params: params
        });
        return response;
    }

    static async loadNewTasks(params) {
        const response = await prioritizedRequest(UriList.TASKS_NEW_TASKS, {
            params: params
        });
        return response;
    }

    static async persistTasks(internalID, tasks) {
        const response = await prioritizedRequest(
            UriList.TASKS_TASKS + (internalID ? "/" + internalID : ""),
            {
                method: internalID ? "PUT" : "POST",
                body: JSON.stringify(tasks),
                formdata: true
            }
        );
        return response;
    }
}

export default TasksRequest;
