import {createStore, compose as origCompose, applyMiddleware, combineReducers} from "redux";
import thunk from "redux-thunk";
import {createBrowserHistory} from "history";
import {connectRouter, routerMiddleware} from "connected-react-router";
import REDUCERS from "./ReducerRegistry";

const lazyReducerEnhancer = (nextCreator) => {
    return (origReducer, preloadedState) => {
        let lazyReducers = {};

        function addReducers(newReducers) {
            this.replaceReducer(
                connectedRouter(
                    combineReducers((lazyReducers = Object.assign({}, lazyReducers, newReducers)))
                )
            );
        }

        return Object.assign({}, nextCreator(origReducer, preloadedState), {
            addReducers
        });
    };
};

const compose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || origCompose;

export const History = createBrowserHistory();

const connectedRouter = connectRouter(History);

export const Store = _createStore();

function _createStore() {
    console.info("Creating store...");
    if (localStorage.getItem("resetStore")) {
        console.info("Discarding saved store state...");
        localStorage.removeItem("store");
        localStorage.removeItem("resetStore");
    }
    const savedStoreState = JSON.parse(localStorage.getItem("store") || "null") || undefined;
    if (savedStoreState) {
        console.info("Retrieving saved store state:", savedStoreState);
    }
    const store = createStore(
        connectedRouter((state) => state),
        savedStoreState,
        compose(
            lazyReducerEnhancer,
            applyMiddleware(routerMiddleware(History), thunk)
        )
    );

    const reducerObj = REDUCERS.reduce((x, reducer) => Object.assign(reducer, x), {});
    console.info("Adding reducers:", reducerObj);
    store.addReducers(reducerObj);

    console.info("Store creation => DONE!");
    return store;
}
