import React from "react";
import PropTypes from "prop-types";
import {AutoComplete} from "primereact/autocomplete";
import config from "../../../../config";
import LabeledField from "../LabeledField";
import {withNamespaces as translate} from "react-i18next";
import FilterMatchModeSwitch from "../../../filter/FilterMatchModeSwitch";

class AutoCompleteField extends React.PureComponent {
    static propTypes = {
        t: PropTypes.func,
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        hideLabel: PropTypes.bool,
        className: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        autoSelect: PropTypes.bool,
        delay: PropTypes.number,
        edit: PropTypes.bool.isRequired,
        suffix: PropTypes.string,
        onSuffixClick: PropTypes.func,
        suffix2: PropTypes.string,
        onSuffixClick2: PropTypes.func,
        error: PropTypes.bool,
        suggestions: PropTypes.array,
        field: PropTypes.string,
        itemTemplate: PropTypes.func,
        onComplete: PropTypes.func.isRequired,
        onChange: PropTypes.func,
        onSelect: PropTypes.func,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        onReset: PropTypes.func,
        onDropdownClick: PropTypes.func,
        dropdown: PropTypes.bool,
        dropdownMode: PropTypes.oneOf(["blank", "current"]),
        required: PropTypes.bool,
        highlighted: PropTypes.bool,
        disableMatchModeSwitch: PropTypes.bool,
        defaultMatchMode: PropTypes.string,
        customMatchModes: PropTypes.array,
        onMatchModeChange: PropTypes.func,
        appendTo: PropTypes.any,
        clearable: PropTypes.bool,
        onClear: PropTypes.func,
        hideAddon: PropTypes.bool
    };

    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value,
            suggestions: this.props.suggestions
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.value !== this.props.value) {
            this.setState({value: this.props.value});
        }
        if (prevProps.suggestions !== this.props.suggestions) {
            this.setState({suggestions: this.props.suggestions, searching: false});
        }
        if (
            this.props.autoSelect &&
            this.props.suggestions &&
            prevProps.suggestions !== this.props.suggestions &&
            this.props.suggestions.length === 1
        ) {
            this.onSelect({value: this.props.suggestions[0], target: this.element});
            if (this.element) {
                this.element.hidePanel();
            }
        }
        if (this.element) {
            if (prevState.searching && !this.state.searching) {
                this.element.hideLoader();
                this.element.showPanel();
            }
        }
    }

    onFocus = (focusEvent) => {
        this.inFocus = true;
        // console.log(focusEvent);
        focusEvent.target.select();
        if (this.props.onFocus) {
            this.props.onFocus(focusEvent);
        }
    };

    onComplete = (completeEvent) => {
        if (this.props.onComplete && this.inFocus) {
            const event = {
                originalEvent: completeEvent,
                field: this.getField(),
                query: completeEvent.query
            };
            this.setState({suggestions: null, searching: true}, () => {
                if (this.element) {
                    this.element.showLoader();
                }
                this.props.onComplete(event);
            });
        }
    };

    onChange = (changeEvent) => {
        if (this.props.onChange) {
            this.props.onChange(changeEvent);
        }
        this.setState({value: changeEvent.target.value});
    };

    onSelect = (selectEvent) => {
        if (selectEvent.value && this.props.suggestions && this.props.suggestions.length > 0) {
            if (this.props.onSelect) {
                this.props.onSelect(selectEvent);
            }
            this.setState({value: selectEvent.value});
        }
    };

    onClear = () => {
        if (this.props.clearable) {
            if (this.props.onClear) {
                this.props.onClear();
            } else {
                if (this.props.onSelect) {
                    this.props.onSelect({name: this.props.name, value: {}});
                }
                this.setState({value: {}});
            }
        }
    };

    getField = () => {
        return (
            this.props.field ||
            (this.props.suggestions &&
            this.props.suggestions.length &&
            typeof this.props.suggestions[0] === "object"
                ? this.props.name
                : null)
        );
    };

    render() {
        const field = this.getField();
        const delay = this.props.delay || config.DELAY_AUTOCOMPLETE;
        return (
            <LabeledField
                name={this.props.name}
                label={this.props.label}
                hideLabel={this.props.hideLabel}
                className={
                    "f-autocomplete" + (this.props.className ? " " + this.props.className : "")
                }
                edit={this.props.edit}
                suffix={this.props.suffix}
                onSuffixClick={this.props.onSuffixClick}
                suffix2={this.props.suffix2}
                onSuffixClick2={this.props.onSuffixClick2}
                error={this.props.error || (this.props.required && !this.props.value)}
                formattedValue={this.props.value}
                onClear={this.props.clearable ? this.onClear : undefined}
                hideAddon={this.props.hideAddon}
            >
                {!this.props.disableMatchModeSwitch && this.props.onMatchModeChange ? (
                    <FilterMatchModeSwitch
                        t={this.props.t}
                        className="f-autocomplete-match-mode"
                        default={this.props.defaultMatchMode}
                        customMatchModes={this.props.customMatchModes}
                        onChange={this.props.onMatchModeChange}
                    />
                ) : (
                    ""
                )}
                <AutoComplete
                    className="f-autocomplete-input"
                    inputClassName={
                        this.state.highlighted || this.props.highlighted
                            ? " f-highlight-animation"
                            : ""
                    }
                    readonly={!this.props.edit}
                    name={this.props.name}
                    value={this.state.value}
                    delay={delay}
                    suggestions={
                        this.state.suggestions && this.state.suggestions.length > 0
                            ? this.state.suggestions
                            : [null]
                    }
                    field={field}
                    itemTemplate={
                        this.state.suggestions && this.state.suggestions.length > 0
                            ? this.props.itemTemplate
                            : this.emptyItemTemplate
                    }
                    completeMethod={this.onComplete}
                    onChange={this.onChange}
                    onSelect={this.onSelect}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    ref={(e) => (this.element = e)}
                    dropdown={this.props.dropdown}
                    dropdownMode={this.props.dropdownMode}
                    onDropdownClick={this.props.onDropdownClick}
                    appendTo={this.props.appendTo}
                />
            </LabeledField>
        );
    }

    emptyItemTemplate = () => {
        return this.state.searching ? (
            <div className="f-search-empty">
                <div className="f-search-entry">
                    <div className="f-search-entry-icon">
                        <div className="f-search-entry-icon-symbol pi-md-search" />
                    </div>
                    <div className="f-search-entry-content">
                        <div
                            className={"f-search-entry-text f-search-entry-highlight"}
                            style={{textAlign: "center"}}
                        >
                            {this.props.t("gen_searching")}
                        </div>
                    </div>
                </div>
            </div>
        ) : (
            <div className="f-search-empty">
                <div className="f-search-entry">
                    <div className="f-search-entry-icon">
                        <div className="f-search-entry-icon-symbol pi-md-cancel" />
                    </div>
                    <div className="f-search-entry-content">
                        <div
                            className={"f-search-entry-text f-search-entry-highlight"}
                            style={{textAlign: "center"}}
                        >
                            {this.props.t("gen_noResults")}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    onBlur = (blurEvent) => {
        this.inFocus = false;
        if (typeof this.props.onBlur === "function") {
            this.props.onBlur(blurEvent);
        }
        this.blurTimeout = setTimeout(() => {
            if (this.props.value !== this.state.value && !this.state.searching) {
                if (typeof this.props.onReset === "function") {
                    this.props.onReset(blurEvent);
                }
                if (this.element) {
                    this.element.hideLoader();
                    this.element.hidePanel();
                }
                this.setState({value: this.props.value, highlighted: true}, () => {
                    if (this.state.highlighted) {
                        this.highlightTimeout = setTimeout(
                            () => this.setState({highlighted: false}),
                            500
                        );
                    }
                });
            }
        }, 500);
    };

    componentWillUnmount() {
        clearTimeout(this.blurTimeout);
        clearTimeout(this.highlightTimeout);
    }
}

export default translate("fleet")(AutoCompleteField);
