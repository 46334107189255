import TranslationUtil from "./TranslationUtil";

export class DocumentTitleList {
    static FLEET = "FLEET";
    static SEPARATOR = " | ";

    static SUBPATHS = {
        management: "title_management",
        partner: "title_partner",
        translations: "title_translationOverview",
        users: "title_userOverview",
        shipment: "title_shipmentOverview",

        trailer: "title_trailerOverview",
        ticket: "title_ticketOverview",
        order: "title_orderOverview",
        inspection: "title_inspectionOverview",
        damage: "title_damageOverview",
        address: "title_addressOverview",
        debtor: "title_debtorOverview",

        dashboard: "title_dashboard"
    };

    static TICKET_DETAIL = "title_ticketDetail";
    static TRAILER_DETAIL = "title_trailerDetail";
    static ORDER_DETAIL = "title_orderDetail";
    static ADDRESS_DETAIL = "title_addressDetail";
    static DEBTOR_DETAIL = "title_debtorDetail";
    static SMARTVIEW = "title_smartview";
    static SMART_ORDEROVERVIEW = "title_smartOrderOverview";
    static SMART_ORDERDETAIL = "title_smartOrderDetail";
    static SMART_TICKET = "title_smartTicket";
    static PARTNER_TICKETDETAIL = "title_partnerTicketDetail";
    static PARTNER_ORDERDETAIL = "title_partnerOrderDetail";
}

class DocumentTitleUtil {
    static t = TranslationUtil.getT();

    // used by components that have to set their title themselves
    static generateTitle(featureTitle, id) {
        document.title =
            (id ? id + DocumentTitleList.SEPARATOR : "") +
            (DocumentTitleUtil.t(featureTitle) + DocumentTitleList.SEPARATOR || "") +
            DocumentTitleList.FLEET;
    }

    // called by App.js in reaction to any URL change
    static generateTitleFromURL() {
        if (localStorage.getItem("token")) {
            const path = window.location.hash;

            if (path.includes("detail")) {
                // detail components are skipped because they handle their titles themselves
                return;
            }

            const pathParts = path.includes("/") ? path.split("/") : "";
            const pathLength = pathParts ? pathParts.length : 0;

            let title = DocumentTitleList.FLEET;

            if (pathLength >= 1) {
                pathParts.forEach((p) => {
                    const pathTitle = DocumentTitleList.SUBPATHS[p]
                        ? DocumentTitleUtil.t(DocumentTitleList.SUBPATHS[p]) +
                          DocumentTitleList.SEPARATOR
                        : "";
                    title = pathTitle + title;
                });
            }
            document.title = title;
        } else {
            document.title =
                DocumentTitleUtil.t("title_login") +
                DocumentTitleList.SEPARATOR +
                DocumentTitleList.FLEET;
        }
    }
}

export default DocumentTitleUtil;
