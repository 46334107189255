import React from "react";
import {observeStore} from "../util/StoreUtil";
import ChangeType from "../store/changeType/ChangeType";
import {Store} from "../store/Store";

class BaseService extends React.PureComponent {
    // #region  Set-Up Lifecycle
    constructor(props, selectFunction) {
        super(props);
        this.store = observeStore(
            selectFunction,
            this.onStoreChange.bind(this),
            ChangeType.RELOAD,
            ChangeType.PERSIST
        );
        this.selectFunction = selectFunction;
        this.state = {};
    }

    componentDidMount() {
        if ("onServiceInit" in this) {
            this.onServiceInit(this.selectFunction(Store.getState()));
        } else {
            console.warn(this.constructor.name + ": Function 'onServiceInit' not implemented!");
        }
    }
    // #endregion

    // #region Store Callback
    async onStoreChange(state, actionType) {
        const changeType = Store.getState().changeType;
        if (changeType === ChangeType.PERSIST) {
            if ("onPersist" in this) {
                return Promise.resolve(this.onPersist(state, actionType));
            }
            console.info(this.constructor.name + ": Function 'onPersist' not implemented!");
        } else {
            if ("onReload" in this) {
                return Promise.resolve(this.onReload(state, actionType));
            }
            console.info(this.constructor.name + ": Function 'onReload' not implemented!");
        }
        return Promise.resolve();
    }
    // #endregion

    // #region Class Functions
    // onServiceInit(initialState) { }
    // onPersist(state,actionType) { }
    // onReload(state,actionType) { }
    // onServiceEnd(endState) { }
    // #endregion

    // #region Render Function
    render() {
        return "";
    }
    //#endregion

    // #region Tear-Down Lifecycle
    componentWillUnmount() {
        if ("onServiceEnd" in this) {
            this.onServiceEnd(this.selectFunction(Store.getState()));
        } else {
            console.warn(this.constructor.name + ": Function 'onServiceEnd' not implemented!");
        }
        this.store.unsubscribe();
    }
    // #endregion
}

export default BaseService;
