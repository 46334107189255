import DefaultStoreUtil from "../../util/DefaultStoreUtil";
import ShipmentAction from "./ShipmentAction";
import ShipmentDefinition from "./ShipmentDefinition";

const Shipment = {
    shipment: DefaultStoreUtil.defaultReducerFunction(
        ShipmentAction.CATEGORY,
        ShipmentDefinition.defaultState
    )
};

export default Shipment;
