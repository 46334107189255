import DefaultStoreUtil from "../../util/DefaultStoreUtil";

class UserAction {
    static CATEGORY = "USER";

    // #region Translation Overview
    static DATATABLE = "USER_DATATABLE";
    static SELECTION = "USER_SELECTION";
    static LOADING = "USER_LOADING";
    static CLEAR = "USER_CLEAR";
    // #endregion

    // #region Translation Dialog
    static DIALOG_ROLES = "USER_DIALOG_ROLES";
    static DIALOG_USER = "USER_DIALOG_USER";
    static DIALOG_CONTACTS = "USER_DIALOG_CONTACTS";
    static DIALOG_CLEAR = "USER_DIALOG_CLEAR";
    // #endregion

    static create = DefaultStoreUtil.defaultCreateAction(UserAction.CATEGORY);
}

export default UserAction;
