import BaseService from "../BaseService";
import SettingsAction from "../../store/settings/SettingsAction";
import ChangeType from "../../store/changeType/ChangeType";
import FileRequest from "../../requests/FileRequest";
import SettingsRequest from "../../requests/SettingsRequest";
import settingsRequest from "../../requests/SettingsRequest";

class SettingsService extends BaseService {
    // #region selection function
    static selectFunction = (state) => (state.settings ? state.settings : null);

    // #endregion

    constructor(props) {
        super(props, SettingsService.selectFunction);
    }

    onServiceInit = () => {
        this.onReload();
    };

    async onReload() {
        this.store.dispatch(
            SettingsAction.create(ChangeType.UPDATE, SettingsAction.LOADING, {loading: true})
        );

        await this.loadDocumentSettings();
        await this.loadInspectionSettings();
        await this.loadTireExchangeSettings();
        await this.loadMiscSettings();

        this.store.dispatch(
            SettingsAction.create(ChangeType.UPDATE, SettingsAction.LOADING, {
                loading: false
            })
        );
    }

    async loadDocumentSettings() {
        return new Promise((resolve, reject) => {
            const featureRequest = SettingsRequest.loadFeatures();
            const docTypesRequest = FileRequest.loadDocumentTypes({rows: 100});
            Promise.all([docTypesRequest, featureRequest])
                .then((response) => {
                    const documentTypes = response[0];
                    const features =
                        response[1].data && response[1].data.length ? response[1].data : [];
                    this.store.dispatch(
                        SettingsAction.create(ChangeType.UPDATE, SettingsAction.DOCUMENT, {
                            documentSettings: {
                                changed: false,
                                documentTypes: documentTypes,
                                documentFeatures: features
                            }
                        })
                    );
                    const requests = [];
                    features.forEach((entry) => {
                        requests.push(
                            SettingsRequest.loadDocumentSettingsForFeature(entry.uuid)
                                .then((docSettings) => {
                                    this.store.dispatch(
                                        SettingsAction.create(
                                            ChangeType.UPDATE,
                                            SettingsAction.DOCUMENT,
                                            {
                                                documentSettings: {
                                                    mapping: {
                                                        [entry.uuid]: {
                                                            changed: false,
                                                            data: docSettings.data
                                                        }
                                                    }
                                                }
                                            }
                                        )
                                    );
                                })
                                .catch(reject)
                        );
                    });
                    Promise.all(requests).then(() => {
                        resolve();
                    });
                })
                .catch(reject);
        });
    }

    async loadInspectionSettings() {
        return new Promise((resolve, reject) => {
            const inspectionSettingRequest = SettingsRequest.loadInspectionSettings();
            inspectionSettingRequest
                .then((result) => {
                    const mappedResult = result.data.reduce((map, entry) => {
                        (map[entry.country] = map[entry.country] || {})[entry.name] = entry;
                        return map;
                    }, {});
                    this.store.dispatch(
                        SettingsAction.create(ChangeType.UPDATE, SettingsAction.INSPECTION, {
                            inspectionSettings: {
                                mapping: mappedResult
                            }
                        })
                    );
                    resolve(mappedResult);
                })
                .catch((err) => reject(err));
        });
    }

    async loadTireExchangeSettings() {
        return new Promise((resolve, reject) => {
            const tireBrandsRequest = SettingsRequest.loadTireBrands();
            const tirePartnerReasonsRequest = SettingsRequest.loadTirePartnerReasons();
            const tireFleetReasonsRequest = SettingsRequest.loadTireFleetReasons();
            Promise.all([tireBrandsRequest, tirePartnerReasonsRequest, tireFleetReasonsRequest])
                .then((response) => {
                    const tireExchangeSettings = {
                        changed: false
                    };
                    tireExchangeSettings.brands =
                        response[0].data && response[0].data.length ? response[0].data : [];
                    tireExchangeSettings.partnerReasons =
                        response[1].data && response[1].data.length ? response[1].data : [];
                    tireExchangeSettings.fleetReasons =
                        response[2].data && response[2].data.length ? response[2].data : [];

                    this.store.dispatch(
                        SettingsAction.create(ChangeType.UPDATE, SettingsAction.TIRE_EXCHANGE, {
                            tireExchangeSettings: tireExchangeSettings
                        })
                    );
                    resolve(tireExchangeSettings);
                })
                .catch((err) => reject(err));
        });
    }

    async loadMiscSettings() {
        return new Promise((resolve, reject) => {
            const orderDeliveryMethod = SettingsRequest.loadOrderDeliveryMethod();
            const trailer4Sale = SettingsRequest.loadTrailer4Sale();
            const damageClose = SettingsRequest.loadDamageClose();
            const orderClose = SettingsRequest.loadOrderClose();
            const ticketClose = SettingsRequest.loadTicketClose();
            const creditRatingLimit = SettingsRequest.loadCreditRatingLimit();
            const creditRatingFuelCard = SettingsRequest.loadCreditRatingFuelCard();
            Promise.all([
                orderDeliveryMethod,
                trailer4Sale,
                damageClose,
                orderClose,
                ticketClose,
                creditRatingLimit,
                creditRatingFuelCard
            ])
                .then((response) => {
                    const miscSettings = {
                        changed: false,
                        saved: false
                    };
                    miscSettings.orderDeliveryMethod = response[0] ? response[0].data : [];
                    miscSettings.trailer4Sale = response[1] ? response[1].data : [];
                    miscSettings.damageClose = response[2] ? response[2].data : [];
                    miscSettings.orderClose = response[3] ? response[3].data : [];
                    miscSettings.ticketClose = response[4] ? response[4].data : [];
                    miscSettings.creditRatingLimit = response[5] ? response[5].data : [];
                    miscSettings.creditRatingFuelCard = response[6] ? response[6].data : [];
                    this.store.dispatch(
                        SettingsAction.create(ChangeType.UPDATE, SettingsAction.SETTINGS, {
                            miscSettings: miscSettings
                        })
                    );
                    resolve(miscSettings);
                })
                .catch((err) => reject(err));
        });
    }

    async onPersist(state) {
        this.store.dispatch(
            SettingsAction.create(ChangeType.UPDATE, SettingsAction.LOADING, {
                detail: {loading: true}
            })
        );
        try {
            const results = {};
            if (state.miscSettings.changed) {
                const requests = [];
                requests.push(
                    SettingsRequest.persistMiscSettings(state.miscSettings.orderDeliveryMethod)
                );
                requests.push(SettingsRequest.persistMiscSettings(state.miscSettings.damageClose));
                requests.push(SettingsRequest.persistMiscSettings(state.miscSettings.orderClose));
                requests.push(SettingsRequest.persistMiscSettings(state.miscSettings.ticketClose));
                requests.push(settingsRequest.persistTrailer4Sale(state.miscSettings.trailer4Sale));
                requests.push(settingsRequest.persistMiscSettings(state.miscSettings.creditRatingLimit));
                requests.push(settingsRequest.persistMiscSettings(state.miscSettings.creditRatingFuelCard));
                if (requests && requests.length) {
                    results.miscSettings = await Promise.all(requests).then(() => {
                        const miscSettings = {
                            saved: true
                        };
                        this.store.dispatch(
                            SettingsAction.create(ChangeType.UPDATE, SettingsAction.LOADING, {
                                miscSettings: miscSettings
                            })
                        );
                    });
                }
            }

            if (state.documentSettings.changed) {
                const requests = [];
                Object.keys(state.documentSettings.mapping).forEach((featureUUID) => {
                    const doctypeSettingMapping = state.documentSettings.mapping[featureUUID];
                    if (doctypeSettingMapping && doctypeSettingMapping.changed) {
                        requests.push(
                            SettingsRequest.persistDocumentSettings(
                                featureUUID,
                                doctypeSettingMapping.data
                            )
                        );
                    }
                });
                if (requests && requests.length) {
                    results.documentSettings = await Promise.all(requests);
                }
            }

            if (state.inspectionSettings.changed) {
                const requests = [];
                Object.keys(state.inspectionSettings.mapping).forEach((country) => {
                    const inspectionCountry = state.inspectionSettings.mapping[country];
                    if (inspectionCountry && inspectionCountry.changed) {
                        const inspectionCountrySettings = Object.keys(inspectionCountry)
                            .filter((key) => key !== "changed" && inspectionCountry[key].changed)
                            .map((key) => inspectionCountry[key]);
                        requests.push(
                            SettingsRequest.persistInspectionSettings(
                                country,
                                inspectionCountrySettings
                            )
                        );
                    }
                });
                if (requests && requests.length) {
                    results.inspectionSettings = await Promise.all(requests);
                }
            }
            if (state.tireExchangeSettings.changed) {
                const requests = [];
                const newBrands = state.tireExchangeSettings.brands.filter((b) => !b.internalID);
                if (newBrands.length) {
                    const brands = newBrands.map((brand) => brand.name);
                    requests.push(SettingsRequest.persistTireSettingsBrand(brands));
                }
                state.tireExchangeSettings.partnerReasons
                    .filter((r) => r.changed)
                    .forEach((r) =>
                        requests.push(
                            SettingsRequest.persistTireSettingsExchangeReason({
                                note: r.note,
                                mappedFleetCategoryIID: r.fleetReason.internalID
                            })
                        )
                    );

                if (requests && requests.length) {
                    results.tireExchangeSettings = await Promise.all(requests);
                }
            }
            if (
                state.documentSettings.changed ||
                state.inspectionSettings.changed ||
                state.tireExchangeSettings.changed ||
                state.miscSettings.changed
            ) {
                // console.log("changed");
                await this.store.promisingDispatch(
                    SettingsAction.create(
                        ChangeType.RELOAD,
                        SettingsAction.SETTINGS,
                        {
                            documentSettings: {
                                mapping: null
                            },
                            inspectionSettings: {
                                mapping: null
                            },
                            tireExchangeSettings: {
                                partnerReasons: null,
                                fleetReasons: null,
                                brands: null
                            }
                        },
                        {
                            documentSettings: {
                                mapping: {}
                            },
                            inspectionSettings: {
                                mapping: {}
                            },
                            tireExchangeSettings: {
                                partnerReasons: [],
                                fleetReasons: [],
                                brands: []
                            }
                        }
                    )
                ).promise;
            }
            // console.log(results);
            return Promise.resolve(results);
        } catch (err) {
            this.store.dispatch(
                SettingsAction.create(ChangeType.UPDATE, SettingsAction.LOADING, {
                    detail: {loading: false}
                })
            );
            return Promise.reject(err);
        }
    }

    onServiceEnd = () => {};
}

export default SettingsService;
