class TrailerDefinition {
    static tireProps = () => {
        return {
            winter: null,
            depth: null,
            pressure: null,
            brand: null,
            number: null
        };
    };

    static tirePropsValidation = () => {
        return {
            winter: {
                required: false,
                type: "number"
            },
            depth: {
                required: false,
                type: "number"
            },
            pressure: {
                required: false,
                type: "number"
            },
            brand: {
                required: false,
                type: "string"
            },
            number: {
                required: false,
                type: "string"
            }
        };
    };

    static inspectionProps = () => {
        return {
            uuid: null,
            vehicleUUID: null,
            inspectionUUID: null,
            last: null,
            next: null,
            name: null,
            label: null,
            creationDate: null,
            creationUser: null,
            modificationDate: null,
            modificationUser: null
        };
    };

    static defaultDetailState = {
        element: {
            trailer: {
                uuid: "",
                state: "",
                licensePlate: "",
                iluCode: "",
                modelCode: "",
                customerState: "",
                customerType: "",
                assetType: "",
                vin: "",
                belongsTo: "",
                owner: "",
                rentedBy: null,
                rentedFrom: null,
                rentedTo: null,
                ownerAddress: null,
                rentedByAddress: null,
                inspectionCountry: "",
                firstRegistration: null,
                lotNumber: 0,
                lotYear: 0,
                note: "",
                creationDate: null,
                creationUser: "",
                modificationDate: null,
                modificationUser: ""
            },
            equipment: {
                cranable: null,
                liftingRoof: null,
                boardWall: null,
                shipRings: null,
                palleteBand: null,
                lightConnections: null,
                inchCordLength: null,
                storageBox: null,
                palleteBox: null,
                palleteCount: null,
                toolbox: null,
                toolboxCount: null,
                hydraulicBackExtension: null,
                backDoorType: null,
                coilSkipLength: null,
                coilSkipDiameter: null,
                slidingRoofType: null,
                slidingRoofManufacturer: null,
                supportLegsType: null,
                telematicIMEI: null,
                telematicSupplier: null
            },
            lasi: {
                pluginPosts: null,
                postPockets: null,
                pluginPlatesType: null,
                pluginPlatesLength: null,
                pluginPlatesWidth: null,
                pluginPlatesHeight: null,
                pluginPlatesCount: null
            },
            rim: {
                rim_type: null,
                bolts: null,
                offset: null
            },
            plane: {
                color: null,
                specification: null,
                safeCurtain: null,
                fireProof: null
            },
            airbellow: {
                manufacturer: null,
                itemNumber: null
            },
            axle: {
                manufacturer: null,
                stackerAxleLoad: null,
                sattelLoad: null,
                load: null,
                number1: null,
                number2: null,
                number3: null,
                liftAxle1: null,
                liftAxle2: null,
                liftAxle3: null,
                liftAxle4: null,
                liftAxle5: null
            },
            brake: {
                brake_manufacturer: null,
                braking_system: null,
                braking_type: null
            },
            dimweight: {
                height: null,
                length: null,
                weight: null,
                width: null,
                backDoorLoadingHeight: null,
                backLoadingHeight: null,
                roofLoadingHeight: null,
                sideLoadingHeight: null,
                tareWeight: null,
                topWidth: null
            },
            tire: {
                spareWheels: null,
                spareWheelHolder: null,
                tireDimension: null,
                tire1ri: TrailerDefinition.tireProps(),
                tire2ri: TrailerDefinition.tireProps(),
                tire3ri: TrailerDefinition.tireProps(),
                tire1le: TrailerDefinition.tireProps(),
                tire2le: TrailerDefinition.tireProps(),
                tire3le: TrailerDefinition.tireProps()
            },
            legalInspection: null,
            inspectionMap: [],
            file: null,
            stateColor: null
        },
        validation: {
            trailer: {
                state: {
                    required: false,
                    type: "string"
                },
                licensePlate: {
                    required: true,
                    type: "string"
                },
                iluCode: {
                    required: true,
                    type: "string"
                },
                modelCode: {
                    required: false
                },
                customerState: {
                    required: true,
                    type: "string"
                },
                customerType: {
                    required: false,
                    type: "string"
                },
                assetType: {
                    required: true,
                    type: "string"
                },
                vin: {
                    required: true,
                    type: "string"
                },
                belongsTo: {
                    required: true,
                    type: "string"
                },
                owner: {
                    required: false,
                    type: "string"
                },
                rentedBy: {
                    required: true,
                    type: "string"
                },
                ownerAddress: {
                    required: false,
                    type: "object"
                },
                rentedByAddress: {
                    required: false,
                    type: "object"
                },
                rentedFrom: {
                    required: true,
                    type: "date"
                },
                rentedTo: {
                    required: false,
                    type: "date"
                },
                inspectionCountry: {
                    required: true,
                    type: "string"
                },
                firstRegistration: {
                    required: false,
                    type: "date"
                },
                lotNumber: {
                    required: false,
                    type: "number"
                },
                lotYear: {
                    required: false,
                    type: "number"
                },
                note: {
                    required: false,
                    type: "string"
                },
                modificationDate: {
                    required: false,
                    type: "date"
                },
                modificationUser: {
                    required: false,
                    type: "date"
                }
            },
            equipment: {
                cranable: {
                    required: false,
                    type: "number"
                },
                liftingRoof: {
                    required: false,
                    type: "number"
                },
                boardWall: {
                    required: false,
                    type: "number"
                },
                shipRings: {
                    required: false,
                    type: "number"
                },
                palleteBand: {
                    required: false,
                    type: "number"
                },
                lightConnections: {
                    required: false,
                    type: "string"
                },
                inchCordLength: {
                    required: false,
                    type: "number"
                },
                storageBox: {
                    required: false,
                    type: "number"
                },
                palleteBox: {
                    required: false,
                    type: "number"
                },
                palleteCount: {
                    required: false,
                    type: "number"
                },
                toolbox: {
                    required: false,
                    type: "number"
                },
                toolboxCount: {
                    required: false,
                    type: "number"
                },
                hydraulicBackExtension: {
                    required: false,
                    type: "number"
                },
                backDoorType: {
                    required: false,
                    type: "string"
                },
                coilSkipLength: {
                    required: false,
                    type: "number"
                },
                coilSkipDiameter: {
                    required: false,
                    type: "number"
                },
                slidingRoofType: {
                    required: false,
                    type: "string"
                },
                slidingRoofManufacturer: {
                    required: false,
                    type: "string"
                },
                supportLegsType: {
                    required: false,
                    type: "string"
                },
                telematicIMEI: {
                    required: false,
                    type: "string"
                },
                telematicSupplier: {
                    required: false,
                    type: "string"
                }
            },
            lasi: {
                pluginPosts: {
                    required: false,
                    type: "number"
                },
                postPockets: {
                    required: false,
                    type: "number"
                },
                pluginPlatesType: {
                    required: false,
                    type: "string"
                },
                pluginPlatesLength: {
                    required: false,
                    type: "number"
                },
                pluginPlatesWidth: {
                    required: false,
                    type: "number"
                },
                pluginPlatesHeight: {
                    required: false,
                    type: "number"
                },
                pluginPlatesCount: {
                    required: false,
                    type: "number"
                }
            },
            rim: {
                rimType: {
                    required: false,
                    type: "string"
                },
                bolts: {
                    required: false,
                    type: "number"
                },
                offset: {
                    required: false,
                    type: "string"
                }
            },
            plane: {
                color: {
                    required: false,
                    type: "string"
                },
                specification: {
                    required: false,
                    type: "string"
                },
                safeCurtain: {
                    required: false,
                    type: "number"
                },
                fireProof: {
                    required: false,
                    type: "string"
                }
            },
            airbellow: {
                manufacturer: {
                    required: false,
                    type: "string"
                },
                itemNumber: {
                    required: false,
                    type: "string"
                }
            },
            axle: {
                manufacturer: {
                    required: false,
                    type: "string"
                },
                stackerAxleLoad: {
                    required: false,
                    type: "number"
                },
                sattelLoad: {
                    required: false,
                    type: "number"
                },
                load: {
                    required: false,
                    type: "number"
                },
                number1: {
                    required: false,
                    type: "string"
                },
                number2: {
                    required: false,
                    type: "string"
                },
                number3: {
                    required: false,
                    type: "string"
                },
                liftAxle1: {
                    required: false,
                    type: "number"
                },
                liftAxle2: {
                    required: false,
                    type: "number"
                },
                liftAxle3: {
                    required: false,
                    type: "number"
                }
            },
            brake: {
                brakeManufacturer: {
                    required: false,
                    type: "string"
                },
                brakingSystem: {
                    required: false,
                    type: "string"
                },
                brakingType: {
                    required: false,
                    type: "string"
                }
            },
            dimweight: {
                height: {
                    required: false,
                    type: "number",
                    transform: (val) => val / 1000
                },
                length: {
                    required: false,
                    type: "number",
                    transform: (val) => val / 1000
                },
                weight: {
                    required: false,
                    type: "number"
                },
                width: {
                    required: false,
                    type: "number",
                    transform: (val) => val / 1000
                },
                backDoorLoadingHeight: {
                    required: false,
                    type: "number",
                    transform: (val) => val / 1000
                },
                backLoadingHeight: {
                    required: false,
                    type: "number",
                    transform: (val) => val / 1000
                },
                roofLoadingHeight: {
                    required: false,
                    type: "number",
                    transform: (val) => val / 1000
                },
                sideLoadingHeight: {
                    required: false,
                    type: "number",
                    transform: (val) => val / 1000
                },
                tareWeight: {
                    required: false,
                    type: "number"
                },
                topWidth: {
                    required: false,
                    type: "number",
                    transform: (val) => val / 1000
                }
            },
            tire: {
                spareWheels: {
                    required: false,
                    type: "number"
                },
                spareWheelHolder: {
                    required: false,
                    type: "number"
                },
                tireDimension: {
                    required: false,
                    type: "string"
                },
                tire1ri: TrailerDefinition.tirePropsValidation(),
                tire2ri: TrailerDefinition.tirePropsValidation(),
                tire3ri: TrailerDefinition.tirePropsValidation(),
                tire1le: TrailerDefinition.tirePropsValidation(),
                tire2le: TrailerDefinition.tirePropsValidation(),
                tire3le: TrailerDefinition.tirePropsValidation()
            },
            inspectionMap: {
                required: false,
                type: "object"
            }
        }
    };

    static defaultMultiEditState = {
        state: "",
        assetType: "",
        customerType: "",
        belongsTo: "",
        rentedBy: "",
        rentedFrom: "",
        rentedTo: "",
        customerState: "",
        inspectionCountry: "",
        lotNumber: 0,
        lotYear: 0,
        firstRegistration: null,
        legalInspection: null
    };
    //#endregion

    // #region Trailer Overview
    static defaultFilter = {
        licensePlate: {value: ""},
        iluCode: {value: ""},
        vin: {value: ""},
        rentedFrom: {
            name: "rentedFrom",
            value: {}
        },
        nextLegalInspection: {
            name: "nextLegalInspection",
            value: {}
        },
        customerState: {
            name: "customerState",
            value: ["N", "A"],
            matchMode: "in"
        },
        customerType: {
            name: "customerType",
            value: []
        }
    };

    static defaultState = {
        entries: [],
        selectionUUIDs: [],
        detail: TrailerDefinition.defaultDetailState,
        filter: TrailerDefinition.defaultFilter,
        trailerTypesMap: [],
        sort: {sortField: "licensePlate", sortOrder: 1},
        rows: 50,
        first: 0,
        totalRecords: 0
    };
}

export default TrailerDefinition;
