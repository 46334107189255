//@ts-check
import React from "react";
import {Dropdown} from "primereact/dropdown";
import ErrorUtil from "../../../util/ErrorUtil";
import TranslationRequest from "../../../requests/TranslationRequest";
import {observeStore, sessionSelectionFunction} from "../../../util/StoreUtil";
import ChangeType from "../../../store/changeType/ChangeType";
import SessionAction from "../../../store/session/SessionAction";
import TranslationUtil from "../../../util/TranslationUtil";

class SessionLanguageSelector extends React.PureComponent {
    constructor(props) {
        super(props);
        this.store = observeStore(sessionSelectionFunction, this.onStoreChange, ChangeType.UPDATE);
        this.state = {
            languages: [],
            language: this.store.getState().language
        };
    }

    componentDidMount() {
        this.mounted = true;
        this.loadLanguages();
    }

    async loadLanguages() {
        try {
            const languages = (await TranslationRequest.loadLanguages()).map((value) => {
                return {value: value.code, label: value.code.toUpperCase()};
            });
            if (this.mounted) {
                this.setState({languages: languages});
            }
        } catch (err) {
            ErrorUtil.handleError("Failed to load languages:", err);
        }
    }

    onStoreChange = (storeState) => {
        this.setState({language: storeState.language}, () => {
            TranslationUtil.changeLanguage(storeState.language);
            localStorage.setItem("language", storeState.language);
        });
    };

    render() {
        return (
            <div className="f-topbar-language">
                <Dropdown
                    options={this.state.languages}
                    value={this.state.language}
                    onChange={(e) => {
                        this.store.dispatch(
                            SessionAction.create(ChangeType.UPDATE, SessionAction.INFORMATION, {
                                language: e.value
                            })
                        );
                    }}
                    autoWidth={false}
                />
            </div>
        );
    }

    componentWillUnmount() {
        this.mounted = false;
        this.store.unsubscribe();
    }
}
export default SessionLanguageSelector;
