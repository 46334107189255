import React from "react";
import PropTypes from "prop-types";
import {withNamespaces as translate} from "react-i18next";
import {Dialog} from "primereact/dialog";
import {ProgressSpinner} from "primereact/progressspinner";
import AddressRequest from "../../../requests/AddressRequest";
import {Button} from "primereact/button";
import ErrorUtil from "../../../util/ErrorUtil";
import ViewUtil from "../../../util/ViewUtil";

class DialogAddress extends React.PureComponent {
    static propTypes = {
        t: PropTypes.func
    };

    state = {
        profile: null,
        uuid: null,
        visible: false
    };

    componentDidMount() {
        PrivateAddressDialog.instance = this;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.uuid !== this.state.uuid) {
            this.retrieveProfile();
        }
    }

    retrieveProfile = this.retrieveProfile.bind(this);
    async retrieveProfile() {
        if (this.state.uuid != null) {
            this.setState({loading: true});
            const profile = await AddressRequest.loadAddress(this.state.uuid);
            this.setState({profile: profile, loading: false});
        }
    }

    onHide = () => {
        this.setState({visible: false, uuid: null, profile: null});
    };

    render() {
        const {profile} = this.state;
        return (
            <Dialog
                className="f-profile-dialog"
                footer={this.renderFooter()}
                width="500px"
                closable={false}
                visible={this.state.visible}
                onHide={this.onHide}
                modal
                responsive
            >
                <div className="f-profile-header">
                    <div className="f-profile-image">
                        <i className="material-icons f-profile-icon">home</i>
                    </div>
                    <div className="f-profile-name">
                        {profile ? profile.name1 : ""}
                    </div>
                </div>
                <div className="f-profile-content">
                    <div className="f-profile-entry">
                        <div className="f-profile-entry-label">
                            {this.props.t("profile_phone") + ":"}
                        </div>
                        <div className="f-profile-entry-value">
                            {Boolean(profile && profile.phone) &&
                                ViewUtil.formatPhoneField(profile.phone)}
                        </div>
                    </div>
                    <div className="f-profile-entry">
                        <div className="f-profile-entry-label">
                            {this.props.t("profile_email") + ":"}
                        </div>
                        <div className="f-profile-entry-value">
                            {Boolean(profile && profile.email) &&
                                ViewUtil.formatEmailField(profile.email)}
                        </div>
                    </div>
                    <div className="f-profile-entry">
                        <div className="f-profile-entry-label">
                            {this.props.t("address_uid") + ":"}
                        </div>
                        <div className="f-profile-entry-value">
                            {Boolean(profile && profile.uid) && profile.uid}
                        </div>
                    </div>
                    <div className="f-profile-entry">
                        <div className="f-profile-entry-label">
                            {this.props.t("address_street") + ":"}
                        </div>
                        <div className="f-profile-entry-value">
                            {Boolean(profile && profile.street) && profile.street}
                        </div>
                    </div>
                    <div className="f-profile-entry">
                        <div className="f-profile-entry-label">
                            {this.props.t("address_zip") + ":"}
                        </div>
                        <div className="f-profile-entry-value">
                            {Boolean(profile && profile.zip) && profile.zip}
                        </div>
                    </div>
                    <div className="f-profile-entry">
                        <div className="f-profile-entry-label">
                            {this.props.t("address_place") + ":"}
                        </div>
                        <div className="f-profile-entry-value">
                            {Boolean(profile && profile.place) && profile.place}
                        </div>
                    </div>
                    <div className="f-profile-entry">
                        <div className="f-profile-entry-label">
                            {this.props.t("address_country") + ":"}
                        </div>
                        <div className="f-profile-entry-value">
                            {Boolean(profile && profile.country) && profile.country}
                        </div>
                    </div>
                </div>
                {/*Sending overlay*/}
                {this.state.loading ? (
                    <div className="f-executing-overlay">
                        <div className="f-executing-overlay-content">
                            <ProgressSpinner />
                        </div>
                    </div>
                ) : (
                    ""
                )}
            </Dialog>
        );
    }

    renderFooter = () => {
        return (
            <div>
                <Button
                    label={this.props.t("gen_close")}
                    icon="pi pi-times"
                    onClick={this.onHide}
                />
            </div>
        );
    };
}

class PrivateAddressDialog {
    static instance = null;
}

export default class AddressDialog {
    static component = translate("fleet")(DialogAddress);

    static displayForUUID(uuid) {
        if (!PrivateAddressDialog.instance) {
            ErrorUtil.handleError(
                "msg_AddressDialog",
                ErrorUtil.createError(
                    ErrorUtil.LEVEL.ERROR,
                    "No Profile Dialog instance could be found!"
                )
            );
            return;
        }
        // console.log("Display for:", uuid);
        PrivateAddressDialog.instance.setState({
            uuid: uuid,
            visible: true
        });
    }
}
