import DefaultStoreUtil from "../../util/DefaultStoreUtil";

class DamageAction {
    static CATEGORY = "DAMAGE";

    // #region Damage Overview
    static LOADING = "DAMAGE_LOADING";
    static DATATABLE = "DAMAGE_DATATABLE";
    static DAMAGETYPES = "DAMAGE_DAMAGETYPES";
    static DAMAGESTATES = "DAMAGE_DAMAGESTATES";
    static SELECTION = "DAMAGE_SELECTION";
    static CLEAR = "DAMAGE_CLEAR";
    static NO_REPAIR = "DAMAGE_NO_REPAIR";
    static DETAIL_CLOSED_ELEMENTS = "DAMAGE_DETAIL_CLOSED_ELEMENTS";
    // #endregion

    // #region Actions
    static create = DefaultStoreUtil.defaultCreateAction(DamageAction.CATEGORY);
}

export default DamageAction;
//#endregion
