import DefaultStoreUtil from "../../util/DefaultStoreUtil";

class PartnerTicketAction {
    static CATEGORY = "PARTNER_TICKET";

    // #region overview types
    static LOADING = "PARTNER_TICKET_LOADING";
    static DATATABLE = "PARTNER_TICKET_DATATABLE";
    static SELECTION = "PARTNER_TICKET_SELECTION";
    static TICKETSTATES = "PARTNER_TICKET_TICKETSTATES";
    static DAMAGETYPES = "PARTNER_TICKET_DAMAGETYPES";
    static COUNTRIES = "PARTNER_TICKET_COUNTRIES";
    static CLEAR = "PARTNER_TICKET_CLEAR";
    static DELETE = "PARTNER_TICKET_DELETE";
    // #endregion

    // #region detail types
    static DETAIL_TICKET = "PARTNER_TICKET_DETAIL_TICKET";
    static DETAIL_OPEN_ELEMENTS = "PARTNER_TICKET_DETAIL_OPEN_ELEMENTS";
    static DETAIL_LOADING = "PARTNER_TICKET_DETAIL_LOADING";
    static DETAIL_TRAILER = "PARTNER_TICKET_DETAIL_TRAILER";
    static DETAIL_COUNTRIES = "PARTNER_TICKET_DETAIL_COUNTRIES";
    static DETAIL_TRAILER_SUGGESTIONS = "PARTNER_TICKET_DETAIL_TRAILER_SUGGESTIONS";
    static DETAIL_USER_SUGGESTIONS = "PARTNER_TICKET_DETAIL_USER_SUGGESTIONS";
    static DETAIL_WORKSHOP_SUGGESTIONS = "PARTNER_TICKET_DETAIL_WORKSHOP_SUGGESTIONS";
    static DETAIL_TRUCKER_NAME_SUGGESTIONS = "PARTNER_DETAIL_TRUCKER_NAME_SUGGESTIONS";
    static DETAIL_DEBTOR_SUGGESTIONS = "PARTNER_DETAIL_DEBTOR_SUGGESTIONS";
    static DETAIL_TICKETSTATES = "PARTNER_TICKET_DETAIL_TICKETSTATES";
    static DETAIL_CLEAR = "PARTNER_TICKET_DETAIL_CLEAR";
    static DETAIL_WORKSHOPS = "PARTNER_TICKET_DETAIL_WORKSHOPS";
    static DETAIL_NO_REPAIR = "PARTNER_TICKET_DETAIL_NO_REPAIR";
    static DETAIL_REPAIR_LATER = "PARTNER_TICKET_DETAIL_REPAIR_LATER";
    static DETAIL_CHANGE_LOCATION = "PARTNER_TICKET_DETAIL_CHANGE_LOCATION";
    static DETAIL_FORWARD_FM = "PARTNER_TICKET_DETAIL_FORWARD_FM";
    static DETAIL_FORWARD_TM = "PARTNER_TICKET_DETAIL_FORWARD_TM";
    static DETAIL_REOPEN = "PARTNER_TICKET_DETAIL_REOPEN";
    // #endregion

    static create = DefaultStoreUtil.defaultCreateAction(PartnerTicketAction.CATEGORY);
}

export default PartnerTicketAction;
