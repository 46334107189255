//@ts-check
import UriList from "../service/EndpointsList";
import {prioritizedRequest} from "../util/RequestUtil";

class UserRequest {
    static async loadUsers(params) {
        const response = await prioritizedRequest(UriList.USER_USER, {
            params: params
        });
        return response;
    }

    static async loadUser(uuid) {
        const response = await prioritizedRequest(UriList.USER_USER + "/" + uuid);
        return response;
    }

    static async loadRoles() {
        const response = await prioritizedRequest(UriList.USER_ROLE);
        return response.role;
    }

    static async loadContacts(params) {
        const response = await prioritizedRequest(UriList.USER_CONTACT, {
            params: params
        });
        return response;
    }

    static async changePassword(uuid, password) {
        const response = await prioritizedRequest(
            UriList.USER_PASSWORD + (uuid ? "/" + uuid : ""),
            {
                method: "PUT",
                body: JSON.stringify(password),
                formdata: true
            }
        );
        return response;
    }

    static async persistUser(uuid, user) {
        const response = await prioritizedRequest(UriList.USER_USER + (uuid ? "/" + uuid : ""), {
            method: uuid ? "PUT" : "POST",
            body: JSON.stringify(user),
            formdata: true
        });
        return response;
    }
}
export default UserRequest;
