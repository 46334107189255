import DefaultStoreUtil from "../../util/DefaultStoreUtil";

class ReportAction {
    static CATEGORY = "REPORT";

    // #region Trailer Overview
    static LOADING = "REPORT_LOADING";
    static DATATABLE = "REPORT_DATATABLE";
    static DAMAGETYPES = "TRAILER_DAMAGETYPES";
    static TRAILERTYPES = "TRAILER_TRAILERTYPES";
    static ORDERSTATES = "ORDER_STATES";
    static SELECTION = "REPORT_SELECTION";
    static OWNERS = "TRAILER_OWNERS";
    static INSPECTION_COUNTRIES = "TRAILER_INSPECTION_COUNTRIES";
    static CLEAR = "REPORT_CLEAR";
    static DELETE = "REPORT_DELETE";
    // #endregion

    // #region Actions
    static create = DefaultStoreUtil.defaultCreateAction(ReportAction.CATEGORY);
}

export default ReportAction;
