import DefaultStoreUtil from "../../util/DefaultStoreUtil";

class TicketAction {
    static CATEGORY = "TICKET";

    // #region overview types
    static LOADING = "TICKET_LOADING";
    static DATATABLE = "TICKET_DATATABLE";
    static SELECTION = "TICKET_SELECTION";
    static TICKETSTATES = "TICKET_TICKETSTATES";
    static DAMAGETYPES = "TICKET_DAMAGETYPES";
    static CLEAR = "TICKET_CLEAR";
    static DELETE = "TICKET_DELETE";
    // #endregion

    // #region detail types
    static DETAIL_TICKET = "TICKET_DETAIL_TICKET";
    static DETAIL_OPEN_ELEMENTS = "TICKET_DETAIL_OPEN_ELEMENTS";
    static DETAIL_LOADING = "TICKET_DETAIL_LOADING";
    static DETAIL_TRAILER = "TICKET_DETAIL_TRAILER";
    static DETAIL_TRAILER_SUGGESTIONS = "TICKET_DETAIL_TRAILER_SUGGESTIONS";
    static DETAIL_USER_SUGGESTIONS = "TICKET_DETAIL_USER_SUGGESTIONS";
    static DETAIL_WORKSHOP_SUGGESTIONS = "TICKET_DETAIL_WORKSHOP_SUGGESTIONS";
    static DETAIL_TRUCKER_NAME_SUGGESTIONS = "DETAIL_TRUCKER_NAME_SUGGESTIONS";
    static DETAIL_DEBTOR_SUGGESTIONS = "DETAIL_DEBTOR_SUGGESTIONS";
    static DETAIL_TICKETSTATES = "TICKET_DETAIL_TICKETSTATES";
    static DETAIL_CLEAR = "TICKET_DETAIL_CLEAR";
    static DETAIL_WORKSHOPS = "TICKET_DETAIL_WORKSHOPS";
    static DETAIL_NO_REPAIR = "TICKET_DETAIL_NO_REPAIR";
    static DETAIL_REPAIR_LATER = "TICKET_DETAIL_REPAIR_LATER";
    static DETAIL_CHANGE_LOCATION = "TICKET_DETAIL_CHANGE_LOCATION";
    static DETAIL_FORWARD_FM = "TICKET_DETAIL_FORWARD_FM";
    static DETAIL_FORWARD_TM = "TICKET_DETAIL_FORWARD_TM";
    static DETAIL_REOPEN = "TICKET_DETAIL_REOPEN";

    // #endregion

    // #region Actions
    static create = DefaultStoreUtil.defaultCreateAction(TicketAction.CATEGORY);
}

export default TicketAction;
// #endregion
