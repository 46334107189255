import PartnerOrderAction from "./PartnerOrderAction";
import PartnerOrderDefinition from "./PartnerOrderDefinition";
import DefaultStoreUtil from "../../util/DefaultStoreUtil";

const PartnerOrder = {
    partnerOrder: DefaultStoreUtil.defaultReducerFunction(
        PartnerOrderAction.CATEGORY,
        PartnerOrderDefinition.defaultState
    )
};

export default PartnerOrder;
