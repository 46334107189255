//@ts-check
import MessagesUtil from "./MessagesUtil";

class ErrorUtil {
    static LEVEL = {
        DEBUG: "debug",
        WARN: "warn",
        INFO: "info",
        ERROR: "error"
    };

    static createUiError = (level, msg, code) => {
        return ErrorUtil.createError(level, msg, code, false);
    };

    static createError = (level, msg, code, hidden = true) => {
        return {
            level: level || ErrorUtil.LEVEL.DEBUG,
            msg: msg,
            code: code,
            hidden: hidden
        };
    };

    static handleError = (errorLabel, errObj, showMessage) => {
        if (errObj && errObj.level) {
            switch (errObj.level) {
                case "debug":
                    console.debug(errorLabel, errObj.msg);
                    break;
                case "info":
                    console.info(errorLabel, errObj.msg);
                    break;
                case "warn":
                    console.warn(errorLabel, errObj.msg);
                    break;
                default:
                    console.error(errorLabel, errObj.msg);
            }
        } else {
            console.error(errorLabel, errObj);
        }
        if (showMessage) {
            //@ts-ignore
            if (errObj && Object.values(MessagesUtil.LEVELS).includes(errObj.level)) {
                const msg = !errObj.hidden ? errObj.msg : "";
                const code = !errObj.hidden ? errObj.code : "";
                MessagesUtil.send(MessagesUtil.TYPES.GLOBAL, errObj.level, errorLabel, msg, code);
            } else if (!errObj || errObj.level !== ErrorUtil.LEVEL.DEBUG) {
                MessagesUtil.send(MessagesUtil.TYPES.GLOBAL, MessagesUtil.LEVELS.ERROR, errorLabel);
            }
        }
    };
}
export default ErrorUtil;
