//@ts-check

import {prioritizedRequest, simpleRequest} from "../util/RequestUtil";
import UriList from "../service/EndpointsList";
import ErrorUtil from "../util/ErrorUtil";
import {reject} from "q";

class AddressRequest {
    static async loadAddress(uuid) {
        if (!uuid) {
            return reject(ErrorUtil.createError(ErrorUtil.LEVEL.ERROR, "Missing UUID"));
        }
        const response = await simpleRequest(UriList.ADDRESS_ADDRESS + "/" + uuid);
        return response;
    }

    static async loadBusinessHours(uuid) {
        if (!uuid) {
            return reject(ErrorUtil.createError(ErrorUtil.LEVEL.ERROR, "Missing UUID"));
        }
        return await simpleRequest(UriList.ADDRESS_BUSINESS_HOURS + "/" + uuid);
    }

    static async loadNote(uuid) {
        if (!uuid) {
            return reject(ErrorUtil.createError(ErrorUtil.LEVEL.ERROR, "Missing UUID"));
        }
        return await simpleRequest(UriList.ADDRESS_NOTE + "/" + uuid);
    }

    static async loadAddresses(params) {
        const response = await prioritizedRequest(UriList.ADDRESS_ADDRESS, {
            params: params
        });
        return response;
    }

    static async loadAgreementOverview(uuid, params) {
        if (!uuid) {
            return reject(ErrorUtil.createError(ErrorUtil.LEVEL.ERROR, "Missing UUID"));
        }
        return await prioritizedRequest(UriList.ADDRESS_AGREEMENT_OVERVIEW + "/" + uuid, {
            params: params
        });
    }

    static async loadAgreement(id) {
        if (!id) {
            return reject(ErrorUtil.createError(ErrorUtil.LEVEL.ERROR, "Missing ID"));
        }
        return await prioritizedRequest(UriList.ADDRESS_AGREEMENT + "/" + id);
    }

    static async loadContact(uuid) {
        if (!uuid) {
            return reject(ErrorUtil.createError(ErrorUtil.LEVEL.ERROR, "Missing UUID"));
        }
        const response = await simpleRequest(UriList.ADDRESS_CONTACT + "/" + uuid);
        return response;
    }

    static async loadCurrencies() {
        const response = await prioritizedRequest(UriList.ADDRESS_CURRENCY);
        return response;
    }

    static async loadCountries() {
        const response = await prioritizedRequest(UriList.ADDRESS_COUNTRY);
        return response;
    }

    static async loadCreditRating(pnr) {
        if (!pnr) {
            return reject(ErrorUtil.createError(ErrorUtil.LEVEL.ERROR, "Missing PNR"));
        }
        const response = await simpleRequest(UriList.ADDRESS_CREDIT_RATING + "/" + pnr);
        return response;
    }

    static async loadOwners() {
        const options = {
            params: {
                type: "owner"
            }
        };
        const response = await prioritizedRequest(UriList.ADDRESS_ADDRESS_SEARCH, options);
        return response;
    }

    static async persistFleetParams(uuid, fleetParams) {
        if (!fleetParams) {
            return Promise.reject(
                ErrorUtil.createError(ErrorUtil.LEVEL.ERROR, "Cannot persist empty data!")
            );
        }
        return await prioritizedRequest(UriList.ADDRESS_ADDRESS + "/" + uuid, {
            method: "PUT",
            body: JSON.stringify(fleetParams),
            formdata: true
        });
    }

    static async persistAddressBusinessHours(uuid, businessHours) {
        if (!businessHours) {
            return Promise.reject(
                ErrorUtil.createError(ErrorUtil.LEVEL.ERROR, "Cannot persist empty data!")
            );
        }
        const body = {
            businessHours: businessHours
        };
        return await prioritizedRequest(UriList.ADDRESS_BUSINESS_HOURS + "/" + uuid, {
            method: "PUT",
            body: JSON.stringify(body),
            formdata: true
        });
    }

    static async persistAddressNote(uuid, address_note) {
        if (!address_note) {
            return Promise.reject(
                ErrorUtil.createError(ErrorUtil.LEVEL.ERROR, "Cannot persist empty data!")
            );
        }
        const body = {
            internalID: address_note.internalID,
            note: address_note.note
        };
        return await prioritizedRequest(UriList.ADDRESS_NOTE + "/" + uuid, {
            method: "PUT",
            body: JSON.stringify(body),
            formdata: true
        });
    }

    static async persistFreeAddress(freeAddress) {
        if (!freeAddress) {
            return Promise.reject(
                ErrorUtil.createError(ErrorUtil.LEVEL.ERROR, "Cannot persist empty data!")
            );
        }
        return await prioritizedRequest(UriList.ADDRESS_ADDRESS, {
            method: "POST",
            body: JSON.stringify(freeAddress),
            formdata: true
        });
    }

    static async deleteFreeAddress(uuid) {
        if (!uuid) {
            return Promise.reject(
                ErrorUtil.createError(ErrorUtil.LEVEL.ERROR, "Cannot delete without uuid!")
            );
        }
        return await prioritizedRequest(UriList.ADDRESS_ADDRESS + "/" + uuid + "/freeAddress", {
            method: "DELETE"
        });
    }

    static async persistAddressAgreement(element, id) {
        if (!element) {
            return Promise.reject(
                ErrorUtil.createError(ErrorUtil.LEVEL.ERROR, "Cannot persist empty data!")
            );
        }
        if (id) {
            return await prioritizedRequest(UriList.ADDRESS_AGREEMENT + "/" + id, {
                method: "PUT",
                body: JSON.stringify(element),
                formdata: true
            });
        }
        return await prioritizedRequest(UriList.ADDRESS_AGREEMENT, {
            method: "PUT",
            body: JSON.stringify(element),
            formdata: true
        });
    }

    static async searchAddress(filter, params) {
        const options = {
            params: {
                rows: 37,
                ...params
            }
        };
        if (filter) {
            options.params.filter = filter;
            options.params.sort = [{sortField: filter.name, sortOrder: 1}];
        }
        return await prioritizedRequest(UriList.ADDRESS_ADDRESS_SEARCH, options);
    }

    static async searchFeighter(filter) {
        const options = {
            params: {
                rows: 37,
                customerType: "Fraechter"
            }
        };
        if (filter) {
            options.params.filter = filter;
            options.params.sort = [{sortField: filter.name, sortOrder: 1}];
        }
        return await prioritizedRequest(UriList.ADDRESS_ADDRESS_SEARCH, options);
    }

    static async searchUserContact(filter) {
        const options = {
            params: {
                rows: 37,
                type: "user"
            }
        };
        if (filter) {
            options.params.filter = filter;
            options.params.sort = [{sortField: filter.name, sortOrder: 1}];
        }
        const response = await prioritizedRequest(UriList.ADDRESS_CONTACT_SEARCH, options);
        return response;
    }

    static async searchUserInternal(filter) {
        const options = {
            params: {
                rows: 37,
                type: "internal"
            }
        };
        if (filter) {
            options.params.filter = filter;
            options.params.sort = [{sortField: filter.name, sortOrder: 1}];
        }
        const response = await prioritizedRequest(UriList.ADDRESS_CONTACT_SEARCH, options);
        return response;
    }

    static async searchContact(filter, additionalFilters) {
        const options = {
            params: {
                rows: 37
            }
        };
        if (filter) {
            options.params.filter = additionalFilters
                ? additionalFilters.concat(filter).reduce((filterObj, filter) => {
                      filterObj[filter.name] = {...filter};
                      return filterObj;
                  }, {})
                : filter;
            options.params.sort = [{sortField: filter.name, sortOrder: 1}];
        }
        const response = await prioritizedRequest(UriList.ADDRESS_CONTACT_SEARCH, options);
        return response;
    }

    static async searchBusiness(filter) {
        const options = {
            params: {
                rows: 37
            }
        };
        if (filter) {
            options.params.filter = filter;
            options.params.sort = [{sortField: filter.name, sortOrder: 1}];
        }
        const response = await prioritizedRequest(UriList.ADDRESS_BUSINESS, options);
        return response;
    }

    static async searchWorkshop(filter) {
        const options = {
            params: {
                rows: 37,
                customerType: "Werkstatt"
            }
        };
        if (filter) {
            options.params.filter = filter;
            options.params.sort = [{sortField: filter.name, sortOrder: 1}];
        }
        const response = await prioritizedRequest(UriList.ADDRESS_ADDRESS_SEARCH, options);
        return response;
    }

    static async searchOwner(filter) {
        const options = {
            params: {
                rows: 37,
                type: "owner"
            }
        };
        if (filter) {
            options.params.filter = filter;
            options.params.sort = [{sortField: filter.name, sortOrder: 1}];
        }
        const response = await prioritizedRequest(UriList.ADDRESS_ADDRESS_SEARCH, options);
        return response;
    }
}

export default AddressRequest;
