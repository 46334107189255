import DefaultStoreUtil from "../../util/DefaultStoreUtil";

class PartnerOrderAction {
    static CATEGORY = "PARTNER_ORDER";

    // #region overview types
    static LOADING = "PARTNER_ORDER_LOADING";
    static STATES = "PARTNER_ORDER_STATES";
    static TYPES = "PARTNER_ORDER_TYPES";
    static DATATABLE = "PARTNER_ORDER_DATATABLE";
    static SELECTION = "PARTNER_ORDER_SELECTION";
    static CLEAR = "PARTNER_ORDER_CLEAR";
    static DELETE = "PARTNER_ORDER_DELETE";
    // #endregion

    // #region detail types
    static DETAIL_ORDER = "PARTNER_ORDER_DETAIL_ORDER";
    static DETAIL_OPEN_ELEMENTS = "PARTNER_ORDER_DETAIL_OPEN_ELEMENTS";
    static DETAIL_LOADING = "PARTNER_ORDER_DETAIL_LOADING";
    static DETAIL_CLEAR = "PARTNER_ORDER_DETAIL_CLEAR";
    // #endregion

    static create = DefaultStoreUtil.defaultCreateAction(PartnerOrderAction.CATEGORY);
}

export default PartnerOrderAction;
