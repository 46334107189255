import DefaultStoreUtil from "../../util/DefaultStoreUtil";
import DebtorDefinition from "./DebtorDefinition";
import DebtorAction from "./DebtorAction";

const Debtor = {
    debtor: DefaultStoreUtil.defaultReducerFunction(
        DebtorAction.CATEGORY,
        DebtorDefinition.defaultState
    )
};

export default Debtor;
