import PartnerTicketAction from "./PartnerTicketAction";
import PartnerTicketDefinition from "./PartnerTicketDefinition";
import DefaultStoreUtil from "../../util/DefaultStoreUtil";

const PartnerTicket = {
    partnerTicket: DefaultStoreUtil.defaultReducerFunction(
        PartnerTicketAction.CATEGORY,
        PartnerTicketDefinition.defaultState
    )
};

export default PartnerTicket;
