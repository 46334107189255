import DefaultStoreUtil from "../../util/DefaultStoreUtil";

class InspectionAction {
    static CATEGORY = "INSPECTION";

    // #region Inspection Overview
    static LOADING = "INSPECTION_LOADING";
    static DATATABLE = "INSPECTION_DATATABLE";
    static INSPECTIONSTATES = "INSPECTION_INSPECTIONSTATES";
    static SELECTION = "INSPECTION_SELECTION";
    static CLEAR = "INSPECTION_CLEAR";

    static DIALOG_LOADING = "INSPECTION_DIALOG_LOADING";
    static DIALOG_INSPECTION = "INSPECTION_DIALOG_INSPECTION";
    static DIALOG_CLEAR = "INSPECTION_DIALOG_CLEAR";
    // #endregion

    // #region Actions
    static create = DefaultStoreUtil.defaultCreateAction(InspectionAction.CATEGORY);
}

export default InspectionAction;
//#endregion
