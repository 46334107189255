//@ts-check
import config from "../config";
import ErrorUtil from "./ErrorUtil";
import {Store} from "../store/Store";
import SessionAction from "../store/session/SessionAction";
import ChangeType from "../store/changeType/ChangeType";

class RequestCounter {
    static counterMap = {};
    static drawNumber(path, method = "GET") {
        if (!RequestCounter.counterMap[method + path]) {
            RequestCounter.counterMap[method + path] = 0;
        }
        return ++RequestCounter.counterMap[method + path];
    }

    static getCounter(path, method = "GET") {
        return RequestCounter.counterMap[method + path] || 0;
    }
}

export function prioritizedRequest(path, options) {
    return new Promise(async (resolve, reject) => {
        try {
            const method = options ? options.method || "GET" : "GET";
            const requestNumber = RequestCounter.drawNumber(path, method);
            const res = await request(path, options);
            if (RequestCounter.getCounter(path, method) !== requestNumber) {
                reject(
                    ErrorUtil.createError(
                        ErrorUtil.LEVEL.DEBUG,
                        "Higher priority request ongoing..."
                    )
                );
            }
            if (res.status === 204) {
                resolve({});
            }
            const json = await res.json();
            if (RequestCounter.getCounter(path, method) !== requestNumber) {
                reject(
                    ErrorUtil.createError(
                        ErrorUtil.LEVEL.DEBUG,
                        "Higher priority request ongoing..."
                    )
                );
            }
            resolve(json);
        } catch (err) {
            reject(typeof err === "string" ? ErrorUtil.createError("error", err) : err);
        }
    });
}

export function simpleRequest(path, options) {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await request(path, options);
            if (res.status === 204) {
                resolve({});
            }
            const json = await res.json();
            resolve(json);
        } catch (err) {
            reject(typeof err === "string" ? ErrorUtil.createError("error", err) : err);
        }
    });
}

export function request(path, options) {
    if (!path.includes("login")) {
        console.info(
            "REQUEST: Url(" + path + ")\nOptions:\n",
            typeof options !== "undefined" ? options : "None"
        );
    } else {
        console.info("Logging in...");
    }
    options = options || {};
    const method = options.method || "GET";

    // resolve url
    let url = options.url || config.API_URL;
    if (path === "") {
        return Promise.reject("pathEmpty");
    }

    let params = "";
    if ("params" in options && options.params) {
        params = mapParams(options.params);
    }
    url = url + path + params;

    // resolve auth header // TO UPDATE
    const resolvedHeaders = {};

    if (!options.noAuthorization) {
        //const user = userSelector(store.getState());
        const jwt = localStorage.getItem("token");

        if (jwt !== null && jwt !== undefined) {
            resolvedHeaders["Authorization"] = `Bearer ${jwt}`;
        }
    }

    if (!options.headers || !options.headers.Accept) {
        resolvedHeaders["Accept"] = "application/json";
    }

    // resolve body
    let body = null;
    if (method === "POST" || method === "PUT") {
        if (options.formdata) {
            body = options.body;
            resolvedHeaders["Content-Type"] = "application/json";
        } else {
            resolvedHeaders["Content-Type"] = "application/x-www-form-urlencoded";

            let query = options.body;
            if (typeof query === "object") {
                query = Object.keys(query)
                    .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(query[k]))
                    .join("&");
            }

            if (query) {
                body = query;
            }
        }
    }

    // build options
    const reqOptions = {
        method: method,
        headers: Object.assign({}, resolvedHeaders, options.headers || {})
    };

    if (body !== null) {
        reqOptions["body"] = body;
    }

    // return promise for the request
    return new Promise(async (resolve, reject) => {
        try {
            const res = await fetch(url, reqOptions);
            if (!res.ok || (res.status !== 204 && res.body === null)) {
                throw res;
            }

            resolve(res);
        } catch (e) {
            await handleError(e, reject);
        }
    });
}

function mapParams(paramData) {
    let params = "";
    let first = true;
    Object.keys(paramData).forEach((key) => {
        if (paramData[key]) {
            const mapping = {
                key: key,
                value: asString(paramData[key])
            };
            switch (key) {
                case "rows":
                    mapping.key = "limit";
                    break;
                case "first":
                    mapping.key = "offset";
                    break;
                case "sort":
                    const sorts = paramData.sort.map((value) => {
                        return {
                            name: value.sortField,
                            direction: value.sortOrder === 1 ? "asc" : "desc"
                        };
                    });
                    mapping.value = sorts && sorts.length > 0 ? JSON.stringify(sorts) : "";
                    break;
                case "filter":
                    const filters = [];
                    parseFilter(filters, paramData[key], null);
                    mapping.value = filters && filters.length > 0 ? JSON.stringify(filters) : "";
                    break;
                default:
                    //In the rare case that there is no mapping to be done, since UI value and backend
                    //value are the same, add the key to this array to avoid triggering the warning.
                    const exceptions = [
                        "customerType",
                        "type",
                        "uuid",
                        "damageTypeUUID",
                        "orderUUID"
                    ];
                    if (!exceptions.includes(key)) {
                        console.warn("No mapping for '" + key + "' found! Is this correct?");
                    }
                    break;
            }
            if (mapping.value && mapping.value.trim() !== "") {
                if (first) {
                    params += "?";
                    first = false;
                } else {
                    params += "&";
                }
                params += mapping.key + "=" + mapping.value;
            }
        }
    });
    return params;

    function asString(data) {
        return typeof data === "string" ? data : JSON.stringify(data);
    }
}

function parseFilter(filterFlatMap, filterObject, key) {
    if (!filterObject) {
        return;
    }
    const {name, names, value, matchMode} = filterObject;
    if (
        (names || name || key) &&
        value &&
        (typeof value !== "object" || Object.keys(value).length) &&
        (typeof value !== "string" || value.trim() !== "") &&
        matchMode
    ) {
        filterFlatMap.push({
            [names ? "names" : "name"]: names || name || key,
            value: Array.isArray(value) ? value.toString() : value,
            operator: matchMode
        });
    } else if (value && typeof value === "object") {
        Object.keys(value).forEach((subFilter) => {
            parseFilter(filterFlatMap, value[subFilter], subFilter);
        });
    } else {
        Object.keys(filterObject).forEach((subFilter) => {
            if (typeof filterObject[subFilter] === "object") {
                parseFilter(filterFlatMap, filterObject[subFilter], subFilter);
            }
        });
    }
}

async function handleError(err, reject) {
    try {
        const json = err && typeof err.json === "function" && (await err.json());
        if (json && json.message && json.severity) {
            reject(ErrorUtil.createError(json.severity, json.message));
            return;
        }
        if (err.status === 400 && json && json.message === "missing or malformed jwt") {
            handleUnauthorizedError(reject);
            return;
        }
    } catch (e) {
        // console.log("Got caught...");
        reject("txt_unknownError");
        return;
    }

    if (typeof err === "string") {
        reject(err);
        return;
    }

    if (err.status === 401) {
        handleUnauthorizedError(reject);
        return;
    }

    if (err.status === 404) {
        reject("404-NotFound");
        return;
    }

    if (err.status === 500) {
        reject("500-InternalServerError");
        return;
    }

    if (err.json === undefined) {
        reject("responseJsonUndefined");
        return;
    }

    try {
        const errJson = await err.json();
        if (errJson.error) {
            reject(errJson.error);
        } else {
            reject("txt_unknownError");
        }
    } catch (_) {
        reject("txt_unknownError");
    }
}

function handleUnauthorizedError(reject) {
    localStorage.removeItem("token");
    Store.dispatch(
        SessionAction.create(ChangeType.UPDATE, SessionAction.LOGIN, {
            jwt: null
        })
    );
    reject("unauthorized");
}
