//@ts-check
import {prioritizedRequest, simpleRequest} from "../util/RequestUtil";
import UriList from "../service/EndpointsList";
import ErrorUtil from "../util/ErrorUtil";

class PartnerTicketRequest {
    static async loadTicket(id) {
        if (!id) {
            return Promise.reject(ErrorUtil.createError(ErrorUtil.LEVEL.ERROR, "Missing ID!"));
        }
        const response = await prioritizedRequest(UriList.PARTNER_TICKET + "/" + id);
        return response;
    }

    static async persistTicket(id, changes) {
        if (!changes) {
            return Promise.reject(
                ErrorUtil.createError(
                    ErrorUtil.LEVEL.ERROR,
                    "Cannot persist if there are no changes!"
                )
            );
        }
        const request = id ? prioritizedRequest : simpleRequest;
        const response = await request(UriList.PARTNER_TICKET + (id ? "/" + id : ""), {
            method: id ? "PUT" : "POST",
            body: JSON.stringify(changes),
            formdata: true
        });
        return response;
    }

    static async loadTickets(params) {
        const response = await prioritizedRequest(UriList.PARTNER_TICKETS, {params: params});
        return response;
    }

    static async loadNewTickets() {
        return await prioritizedRequest(UriList.PARTNER_NEW_TICKETS);
    }

    static async createOrder(ticketID, changes) {
        if (!ticketID) {
            return Promise.reject(
                ErrorUtil.createError(ErrorUtil.LEVEL.ERROR, "Missing ticket ID!")
            );
        }
        const response = await prioritizedRequest(UriList.PARTNER_TICKET_CREATE_ORDER(ticketID), {
            method: "POST",
            body: JSON.stringify(changes),
            formdata: true
        });
        return response;
    }

    static async closeWithoutRepair(ticketID, changes) {
        if (!ticketID) {
            return Promise.reject(
                ErrorUtil.createError(ErrorUtil.LEVEL.ERROR, "Missing ticket ID!")
            );
        }
        const response = await prioritizedRequest(
            UriList.PARTNER_TICKET_CLOSE_WITHOUT_REPAIR(ticketID),
            {
                method: "POST",
                body: JSON.stringify(changes),
                formdata: true
            }
        );
        return response;
    }

    // static async applyTicketAction(uuid, action) {
    //     if (!uuid) {
    //         return Promise.reject(ErrorUtil.createError(ErrorUtil.LEVEL.ERROR, "Missing UUID!"));
    //     }
    //     if (!action) {
    //         return Promise.reject(ErrorUtil.createError(ErrorUtil.LEVEL.ERROR, "Missing action!"));
    //     }
    //     let uri;
    //     switch (action) {
    //         case TicketAction.DETAIL_NO_REPAIR:
    //             uri = UriList.TICKET_TICKET_NO_REPAIR(uuid);
    //             break;
    //         case TicketAction.DETAIL_REPAIR_LATER:
    //             uri = UriList.TICKET_TICKET_REPAIR_LATER(uuid);
    //             break;
    //         case TicketAction.DETAIL_CHANGE_LOCATION:
    //             uri = UriList.TICKET_TICKET_CHANGE_LOCATION(uuid);
    //             break;
    //         case TicketAction.DETAIL_FORWARD_TM:
    //             uri = UriList.TICKET_TICKET_FORWARD_TM(uuid);
    //             break;
    //         case TicketAction.DETAIL_REOPEN:
    //             uri = UriList.TICKET_TICKET_REOPEN(uuid);
    //             break;
    //         default:
    //             return false;
    //     }

    //     const response = await prioritizedRequest(uri, {method: "PUT"});
    //     return response;
    // }
}

export default PartnerTicketRequest;
