import SessionAction from "./SessionAction";
import SessionDefinition from "./SessionDefinition";
import DefaultStoreUtil from "../../util/DefaultStoreUtil";

const Session = {
    session: DefaultStoreUtil.defaultReducerFunction(
        SessionAction.CATEGORY,
        SessionDefinition.defaultState
    )
};

export default Session;
