//@ts-check
import DefaultStoreUtil from "../../util/DefaultStoreUtil";

class SettingsAction {
    static CATEGORY = "SETTINGS";

    // #region types
    static LOADING = "SETTINGS_LOADING";
    static SETTINGS = "SETTINGS_SETTINGS";
    static DOCUMENT = "SETTINGS_DOCUMENT";
    static INSPECTION = "SETTINGS_INSPECTION";
    static TIRE_EXCHANGE = "SETTINGS_TIRE_EXCHANGE";
    // #endregion

    // #region Action
    static create = DefaultStoreUtil.defaultCreateAction(SettingsAction.CATEGORY);
    // #endregion
}

export default SettingsAction;
