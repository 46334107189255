import TrailerAction from "./TrailerAction";
import TrailerDefinition from "./TrailerDefinition";
import DefaultStoreUtil from "../../util/DefaultStoreUtil";

const Trailer = {
    trailer: DefaultStoreUtil.defaultReducerFunction(
        TrailerAction.CATEGORY,
        TrailerDefinition.defaultState
    )
};

export default Trailer;
