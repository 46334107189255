import React, {Component} from "react";
import {withNamespaces as translate} from "react-i18next";
import PropTypes from "prop-types";
import classNames from "classnames";
import {ScrollPanel} from "primereact/components/scrollpanel/ScrollPanel";
import {observeStore, sessionSelectionFunction} from "./util/StoreUtil";
import ChangeType from "./store/changeType/ChangeType";
import config from "./config";
import ClipboardUtil from "./util/ClipboardUtil";

class AppRightPanel extends Component {
    static defaultProps = {
        onContentClick: null,
        expanded: false
    };

    static propTypes = {
        t: PropTypes.func,
        onContentClick: PropTypes.func.isRequired,
        expanded: PropTypes.bool
    };

    constructor() {
        super();
        this.state = {
            userJWT: "",
            userRole: null,
            userUUID: ""
        };
        this.store = observeStore(sessionSelectionFunction, this.onStoreChange, ChangeType.UPDATE);
    }

    componentDidMount() {
        setTimeout(() => {
            this.rightPanelMenuScroller.moveBar();
        }, 100);
    }

    onStoreChange = (state) => {
        if (state !== null && state.jwt !== undefined) {
            this.setState({
                userJWT: state.jwt,
                userRole: state.role,
                userUUID: state.user
            });
        }
    };

    forceReload = () => {
        console.log("force reload page");
        if ("caches" in window) {
            caches.keys().then((names) => {
                // Delete all the cache files
                names.forEach((name) => {
                    caches.delete(name);
                });
            });
        }
        window.location.reload();
    };

    render() {
        const className = classNames("layout-rightpanel", {
            "layout-rightpanel-active": this.props.expanded
        });

        return (
            <div className={className} onClick={this.props.onContentClick}>
                <ScrollPanel
                    ref={(el) => (this.rightPanelMenuScroller = el)}
                    style={{height: "100%"}}
                >
                    <div className="right-panel-scroll-content">
                        <div className="layout-rightpanel-header">
                            <div>Developer Infos:</div>
                        </div>

                        <div className="layout-rightpanel-content">
                            <div className="f-debug">
                                <strong>JWT:</strong>
                                {this.state.userJWT}
                                <i
                                    className="material-icons"
                                    style={{cursor: "pointer"}}
                                    onClick={() => ClipboardUtil.copyString(this.state.userJWT)}
                                >
                                    content_copy
                                </i>
                                <br />
                                <strong>Role:</strong>
                                {this.state.userRole
                                    ? this.props.t("role_" + this.state.userRole)
                                    : "N/A"}
                                <br />
                                <strong>UUID:</strong>
                                {this.state.userUUID}
                                <br />
                                <strong>Version:</strong>
                                {config.VERSION}
                                <br />
                                <strong onClick={this.forceReload} style={{cursor: "pointer"}}>
                                    Force Reload
                                </strong>
                            </div>
                        </div>
                    </div>
                </ScrollPanel>
            </div>
        );
    }

    componentWillUnmount() {
        this.store.unsubscribe();
    }
}

export default translate("fleet")(AppRightPanel);
