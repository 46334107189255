import React from "react";
import {withNamespaces as translate} from "react-i18next";
import {PropTypes} from "prop-types";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {Password} from "primereact/password";
import {Message} from "primereact/message";
import UserRequest from "../../../requests/UserRequest";
import {Store} from "../../../store/Store";
import ErrorUtil from "../../../util/ErrorUtil";
import config from "../../../config";
import MessagesUtil from "../../../util/MessagesUtil";
import SessionAction from "../../../store/session/SessionAction";
import ChangeType from "../../../store/changeType/ChangeType";

class DialogChangePassword extends React.PureComponent {
    static propTypes = {
        t: PropTypes.func,
        i18n: PropTypes.func,
        onHide: PropTypes.func,
        forceChange: PropTypes.bool
    };

    // #region Set-Up Lifecycle
    constructor(props) {
        super(props);
        this.state = {
            passwordCurrent: "",
            passwordNew: "",
            passwordConfirm: "",
            validationMsg: ""
        };
    }

    componentDidMount() {
        this.validationTimeout = setTimeout(this.validate, config.DELAY_VALIDATION);
        this.props.i18n.changeLanguage(localStorage.getItem("language"))
    }
    // #endregion

    // #region UI Callbacks
    onChange = (e) => {
        this.setState({[e.target.id]: e.target.value});
        this.validationTimeout = setTimeout(this.validate, config.DELAY_VALIDATION);
    };

    onSave = () => {
        const password = {
            oldPassword: this.state.passwordCurrent,
            newPassword: this.state.passwordNew
        };
        UserRequest.changePassword(Store.getState().session.user, password)
            .then((res) => {
                if (res.severity === "Error") {
                    this.setState({
                        passwordCurrent: "",
                        validationMsg: "val_errorUserWrongPassword"
                    });
                    return;
                }
                Store.dispatch(
                    SessionAction.create(ChangeType.UPDATE, SessionAction.LOGIN, {
                        jwt: res.data.jwt
                    })
                );
                MessagesUtil.send(
                    MessagesUtil.TYPES.GLOBAL,
                    MessagesUtil.LEVELS.SUCCESS,
                    "msg_actionSuccess",
                    "msg_passwordChanged"
                );
                this.props.onHide();
            })
            .catch((err) => {
                ErrorUtil.handleError("msg_passwordSaveFailed", err, true);
            });
    };
    // #endregion

    // #region Class Functions
    isSaveDisabled = () => {
        return (
            !this.state ||
            this.state.validationMsg ||
            !this.state.passwordCurrent ||
            !this.state.passwordNew ||
            this.state.passwordCurrent === this.state.passwordNew
        );
    };

    validate = () => {
        if (!this.state.passwordCurrent || !this.state.passwordNew) {
            this.setState({validationMsg: "val_infoUserPasswordMissing"});
            return true;
        } else if (this.state.passwordCurrent === this.state.passwordNew) {
            this.setState({validationMsg: "val_errorUserPasswordNeedsChange"});
            return true;
        } else if (
            this.state.passwordNew &&
            this.state.passwordNew !== this.state.passwordConfirm
        ) {
            this.setState({validationMsg: "val_warnUserPasswordNoMatch"});
            return true;
        }
        this.setState({validationMsg: null});
        return false;
    };
    // #endregion

    // #region Render Function
    render() {
        const {t} = this.props;
        return (
            <Dialog
                header={
                    this.props.forceChange ? t("gen_changePasswordForced") : t("gen_changePassword")
                }
                showHeader
                footer={this.renderFooter(t)}
                width="500px"
                modal
                responsive
                visible
                onHide={this.props.onHide}
                closable={!this.props.forceChange}
            >
                <div className="p-grid p-fluid">
                    {this.renderValidationMessage(t)}
                    <div
                        className="p-grid form-group"
                        style={{lineHeight: "2.5em", marginBottom: "20px"}}
                    >
                        <this.passwordField
                            key="passwordCurrent"
                            id="passwordCurrent"
                            value={this.state.passwordCurrent}
                            t={t}
                            feedback={false}
                        />
                        <this.passwordField
                            key="passwordNew"
                            id="passwordNew"
                            value={this.state.passwordNew}
                            t={t}
                        />
                        <this.passwordField
                            key="passwordConfirm"
                            id="passwordConfirm"
                            value={this.state.passwordConfirm}
                            t={t}
                            feedback={false}
                            disabled={!this.state.passwordNew}
                        />
                    </div>
                </div>
            </Dialog>
        );
    }
    // #endregion

    // #region Render Co-Functions
    renderFooter = (t) => {
        return (
            <div>
                {!this.props.forceChange ? (
                    <Button
                        label={t("gen_cancel")}
                        icon="pi pi-times"
                        onClick={this.props.onHide}
                    />
                ) : (
                    ""
                )}
                <Button
                    label={t("gen_save")}
                    icon="pi pi-check"
                    disabled={this.isSaveDisabled()}
                    onClick={() => {
                        this.onSave();
                    }}
                />
            </div>
        );
    };

    renderValidationMessage = (t) => {
        if (this.state.validationMsg) {
            const validationPrefix = this.state.validationMsg.substring(0, 12);
            let severity = "";
            switch (validationPrefix) {
                case "val_warn":
                    severity = "warn";
                    break;
                case "val_error":
                    severity = "error";
                    break;
                default:
                    severity = "info";
            }
            return (
                <div className="p-col-12" style={{marginBottom: "20px"}}>
                    <Message severity={severity} text={" " + t(this.state.validationMsg)} />
                </div>
            );
        }
        return null;
    };
    // #endregion

    // #region React function components
    passwordField = (props) => {
        return (
            <div className="p-col-12">
                <span className="p-float-label">
                    <Password
                        id={props.id}
                        value={props.value}
                        onChange={this.onChange}
                        feedback={props.feedback}
                        disabled={props.disabled}
                        promptLabel={props.t("gen_passwordPrompt")}
                        weakLabel={props.t("gen_passwordWeak")}
                        mediumLabel={props.t("gen_passwordMedium")}
                        strongLabel={props.t("gen_passwordStrong")}
                    />
                    <label htmlFor="label">{props.t("user_" + props.id)}</label>
                </span>
            </div>
        );
    };
    // #endregion
}
export default translate("fleet")(DialogChangePassword);
