import AddressDefinition from "./AddressDefinition";
import AddressAction from "./AddressAction";
import DefaultStoreUtil from "../../util/DefaultStoreUtil";

const Address = {
    address: DefaultStoreUtil.defaultReducerFunction(
        AddressAction.CATEGORY,
        AddressDefinition.defaultState
    )
};

export default Address;
