//@ts-check
class AddressDefinition {
    static dayMap = [
        {
            day: 1,
            open: false,
            morningOpen: "",
            morningClose: "",
            afternoonOpen: "",
            afternoonClose: "",
            note: ""
        },
        {
            day: 2,
            open: false,
            morningOpen: "",
            morningClose: "",
            afternoonOpen: "",
            afternoonClose: "",
            note: ""
        },
        {
            day: 3,
            open: false,
            morningOpen: "",
            morningClose: "",
            afternoonOpen: "",
            afternoonClose: "",
            note: ""
        },
        {
            day: 4,
            open: false,
            morningOpen: "",
            morningClose: "",
            afternoonOpen: "",
            afternoonClose: "",
            note: ""
        },
        {
            day: 5,
            open: false,
            morningOpen: "",
            morningClose: "",
            afternoonOpen: "",
            afternoonClose: "",
            note: ""
        },
        {
            day: 6,
            open: false,
            morningOpen: "",
            morningClose: "",
            afternoonOpen: "",
            afternoonClose: "",
            note: ""
        },
        {
            day: 7,
            open: false,
            morningOpen: "",
            morningClose: "",
            afternoonOpen: "",
            afternoonClose: "",
            note: ""
        }
    ];

    static dayMapLabel = [
        "address_businessDayMonday",
        "address_businessDayTuesday",
        "address_businessDayWednesday",
        "address_businessDayThursday",
        "address_businessDayFriday",
        "address_businessDaySaturday",
        "address_businessDaySunday"
    ];

    static defaultDetailState = {
        element: {
            uuid: null,
            name1: "",
            name2: "",
            name3: "",
            referenceID: "",
            referenceExt: "",
            country: "",
            street: "",
            zip: "",
            place: "",
            phone: "",
            email: "",
            active: "",
            customerType: "",
            business: "",
            shortcut: "",
            ownerLocation: "",
            ownerType: 0,
            docRequestEmail: "",
            docRequestPartner: {
                partnerUUID: null,
                partnerName1: "",
                partnerReferenceID: 0
            },
            businessHours: {
                openWeekdays: false,
                openWeekdaysMorningOpen: "",
                openWeekdaysMorningClose: "",
                openWeekdaysAfternoonOpen: "",
                openWeekdaysAfternoonClose: "",
                businessHours: AddressDefinition.dayMap
            },
            openWeekdaysMorningOpen: "",
            openWeekdaysMorningClose: "",
            openWeekdaysAfternoonOpen: "",
            openWeekdaysAfternoonClose: ""
        }
    };
    // #endregion

    // #region Address Overview
    static defaultFilter = {
        name1: {value: ""},
        referenceID: {value: ""},
        referenceExt: {value: ""},
        country: {value: []},
        street: {value: ""},
        zip: {value: ""},
        place: {value: ""},
        phone: {value: ""},
        email: {value: ""},
        customerType: {value: ""},
        business: {value: ""}
    };

    static defaultState = {
        entries: [],
        selectionUUIDs: [],
        detail: AddressDefinition.defaultDetailState,
        filter: AddressDefinition.defaultFilter,
        sort: {sortField: "name1", sortOrder: -1},
        rows: 50,
        first: 0,
        totalRecords: 0
    };
}

export default AddressDefinition;
