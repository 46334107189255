import ReportAction from "./ReportAction";
import ReportDefinition from "./ReportDefinition";
import DefaultStoreUtil from "../../util/DefaultStoreUtil";

const Report = {
    report: DefaultStoreUtil.defaultReducerFunction(
        ReportAction.CATEGORY,
        ReportDefinition.defaultState
    )
};

export default Report;
