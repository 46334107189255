import DefaultStoreUtil from "../../util/DefaultStoreUtil";

class SessionAction {
    static CATEGORY = "SESSION";

    static LOGIN = "SESSION_LOGIN";
    static LOGOUT = "SESSION_LOGOUT";
    static INFORMATION = "SESSION_INFORMATION";

    static create = DefaultStoreUtil.defaultCreateAction(SessionAction.CATEGORY);
}
export default SessionAction;
