//@ts-check
import SmartviewAction from "./SmartviewAction";
import SmartviewDefinition from "./SmartviewDefinition";
import DefaultStoreUtil from "../../util/DefaultStoreUtil";

const Smartview = {
    smartview: DefaultStoreUtil.defaultReducerFunction(
        SmartviewAction.CATEGORY,
        SmartviewDefinition.defaultState
    )
};

export default Smartview;
