import ObjectUtil from "../../util/ObjectUtil";
import OrderDefinition from "../order/OrderDefinition";
import TicketDefinition from "../ticket/TicketDefinition";

//@ts-check
class SmartviewDefinition {
    static defaultTicketState = {
        trailer: null,
        element: ObjectUtil.deepDataCopy(TicketDefinition.defaultDetailState.element)
    };

    static defaultOrderState = {
        trailer: null,
        element: ObjectUtil.deepDataCopy(OrderDefinition.defaultDetailState.element)
    };

    static defaultState = {
        entries: null,
        ticket: SmartviewDefinition.defaultTicketState,
        order: SmartviewDefinition.defaultOrderState
    };
}
export default SmartviewDefinition;
