//@ts-check
class SessionDefinition {
    static defaultState = {
        user: null,
        expires: null,
        email: null,
        firstname: null,
        lastname: null,
        addressUUID: null,
        type: null,
        role: null,
        jwt: null,
        language: localStorage.getItem("language") || navigator.language.split("-")[0],
        languageLoaded: false,
        passwordExpired: false
    };
}
export default SessionDefinition;
