import React from "react";
import PropTypes from "prop-types";
import {Messages} from "primereact/messages";
import {observeStore} from "../../util/StoreUtil";
import ObjectUtil from "../../util/ObjectUtil";
import MessagesAction from "../../store/messages/MessagesAction";
import MessagesUtil from "../../util/MessagesUtil";
import {withNamespaces as translate} from "react-i18next";

class PrivateMessagesConsumer {
    static idCounter = 0;
}

class MessagesConsumer extends React.PureComponent {
    static propTypes = {
        t: PropTypes.func,
        messageType: PropTypes.oneOf([
            MessagesUtil.TYPES.GLOBAL,
            MessagesUtil.TYPES.DIALOG,
            MessagesUtil.TYPES.SITE
        ]).isRequired,
        parseMessage: PropTypes.func.isRequired,
        path: PropTypes.string,
        clearOnUrlChange: PropTypes.bool,
        translate: PropTypes.bool
    };

    constructor(props) {
        super(props);
        this.store = observeStore((storeState) => storeState.messages, this.onStoreChange);
        this.state = {
            messages: []
        };
        this.id = ++PrivateMessagesConsumer.idCounter;
    }

    componentDidUpdate(prevProps) {
        if (this.props.clearOnUrlChange && prevProps.path !== this.props.path) {
            this.getMessageSystem().clear();
        }
    }

    onStoreChange = (storeState, actionType) => {
        const stateMessages = ObjectUtil.deepDataCopy(this.state.messages);
        if (
            actionType === MessagesAction.SEND &&
            storeState[this.props.messageType] &&
            storeState[this.props.messageType].length
        ) {
            storeState[this.props.messageType].forEach((message) => {
                stateMessages.push(message);
            });
            const messages = storeState[this.props.messageType].map(this.props.parseMessage);
            //Translate and add colon
            messages.map((message) => {
                if (
                    (message.summary && message.summary.includes(":")) ||
                    (message.detail && message.detail.includes(":"))
                ) {
                    console.warn(
                        "Messages are not supposed to contain ':'! Message might not be displayed correctly!"
                    );
                }
                if (this.props.translate) {
                    message.summary = this.props.t(message.summary);
                    message.detail = this.props.t(message.detail);
                }
                if (message.summary && message.detail) {
                    message.summary = message.summary + ":";
                }
                return message;
            });
            this.getMessageSystem().show(messages);
            this.store.dispatch(MessagesAction.clear(this.props.messageType));
        }
        this.setState({messages: stateMessages});
    };

    render() {
        return (
            <Messages className="f-messages" ref={(el) => (this["messageSystem" + this.id] = el)} />
        );
    }

    getMessageSystem = () => {
        return this["messageSystem" + this.id];
    };

    componentWillUnmount() {
        this.store.unsubscribe();
    }
}

export default translate("fleet")(MessagesConsumer);
