import MessagesUtil from "../../util/MessagesUtil";
import {Store} from "../../store/Store";
import TasksAction from "../../store/task/TasksAction";
import ChangeType from "../../store/changeType/ChangeType";
import moment from "moment";
import ErrorUtil from "../../util/ErrorUtil";
import TaskMonitor from "./TaskMonitor";
import ObjectUtil from "../../util/ObjectUtil";

/**
 * @typedef {Object} TaskObject A TaskObject is a describing container that holds all the relevant information to handle tasks.
 * It will copy the information of the taskDefinition as given to monitorTask or monitorSequentialTask and append various information used by the Task Manager.
 * @property {number} id - The id of the task.
 * @property {Promise} promise - The actual promise that is monitored
 * @property {boolean} ongoing - Whether the task is currently being processed
 * @property {boolean} done - Whether the task is done. (Success case)
 * @property {boolean} failed - Whether the task failed. (Failure case)
 * @property {number} progress - How much of the task is done [Currently not used]
 * @property {Date} start - When the task was started
 * @property {Date} end - When the task was done or failed.
 * @property {Object} error - The error object if the task failed
 * @property {[TaskObject]} subTasks - The subtasks of the Task
 */

class PrivateTaskManager {
    static id = 0;
    static tasks = [];

    static onCompletion(taskObject, isSubTask) {
        return () => {
            taskObject.ongoing = false;
            taskObject.done = true;
            taskObject.end = moment().toDate();
            if (!isSubTask && !taskObject.hideMessages) {
                MessagesUtil.send(
                    MessagesUtil.TYPES.GLOBAL,
                    MessagesUtil.LEVELS.SUCCESS,
                    "msg_taskDone",
                    taskObject.title
                );
            }
            PrivateTaskManager.updateStoreInfo();
        };
    }

    static onFailure(taskObject, isSubTask) {
        return (err) => {
            taskObject.ongoing = false;
            taskObject.failed = true;
            taskObject.end = moment().toDate();
            taskObject.error = err;
            ErrorUtil.handleError(taskObject.title, err, false);
            if (!isSubTask && !taskObject.hideMessages) {
                MessagesUtil.send(
                    MessagesUtil.TYPES.GLOBAL,
                    MessagesUtil.LEVELS.ERROR,
                    "msg_taskFailed",
                    taskObject.title
                );
            }
            PrivateTaskManager.updateStoreInfo();
        };
    }

    static updateStoreInfo() {
        Store.dispatch(
            TasksAction.create(ChangeType.UPDATE, TasksAction.INFO, {
                activeTasks: PrivateTaskManager.tasks.reduce((count, task) => {
                    count += !task.ongoing ? 0 : 1;
                    return count;
                }, 0),
                taskObjects: ObjectUtil.deepDataCopy(PrivateTaskManager.tasks)
            })
        );
    }

    static initTaskRecursively(taskDefinition, id, isSubTask = false) {
        const taskObject = this.createTask(taskDefinition, id, taskDefinition.promise, isSubTask);
        if (taskDefinition.subTasks && taskDefinition.subTasks.length) {
            taskObject.subTasks = taskDefinition.subTasks.map((subTask, index) =>
                PrivateTaskManager.initTaskRecursively(subTask, id + "-" + index, true)
            );
        }
        return taskObject;
    }

    /**
     * Creates a task object out of the task definition and given information
     * @param {Object} taskDefinition the task definition
     * @param {number} id a unique id to identify by
     * @param {Promise} [promise] a promise to monitor, if given it will be bound to the monitoring functionality.
     * @param {boolean} [isSubTask] default value is false
     * @returns {TaskObject} a task object
     */
    static createTask(taskDefinition, id, promise, isSubTask = false) {
        const taskObject = {
            ...taskDefinition,
            id: id,
            ongoing: true,
            done: false,
            failed: false,
            progress: 0,
            start: moment().toDate()
        };
        if (promise instanceof Promise) {
            PrivateTaskManager.bindMonitoring(taskObject, promise, isSubTask);
        }
        return taskObject;
    }

    static bindMonitoring(taskObject, promise, isSubTask = false) {
        promise
            .then(PrivateTaskManager.onCompletion(taskObject, isSubTask))
            .catch(PrivateTaskManager.onFailure(taskObject, isSubTask));
    }

    static async sequentialExecution(taskObject, steps) {
        taskObject.subTasks = [];
        const results = [];
        for (let i = 0; i < steps.length; i++) {
            const step = steps[i];
            const promise = step.createPromise();
            taskObject.subTasks.push(
                PrivateTaskManager.createTask(step, taskObject.id + "-" + i, promise, true)
            );
            results.push(await promise);
        }
        return results;
    }
}

export default class TaskManager {
    /**
     * Monitor a task and display relevant information on its progress.
     * @param {Object} taskDefinition An object containing information about the task, means to display it and monitor it.
     * @param {string} taskDefinition.title
     * @param {Promise} taskDefinition.promise
     * @param {Object[]} [taskDefinition.subTasks]
     * @param {string} taskDefinition.subTasks.title
     * @param {Promise} taskDefinition.subTasks.promise
     * @param {Object[]} [taskDefinition.subTasks.subTasks]
     * @param {function} [taskDefinition.subTasks.render]
     * @param {function} [taskDefinition.render]
     * @param {Boolean} [taskDefinition.hideMessages]
     * @param {string} title a label to be displayed as the title of the task
     * @param {Promise} promise the promise of the task used for monitoring.
     * @param {TaskObject} [subTasks] a array of sub-tasks than can themselves again have an array of sub-tasks (!AVOID CYCLIC OBJECTS!)
     * @param {function} [render] a function returning a JSX-Element to render, will be called with the taskObject as parameter
     * @param {Boolean} [hideMessages] if set to true, there will no messages be displayed on monitoring start and end.
     * @returns {TaskObject} a task object containing the given parameters and additional information set by the task manager.
     */
    static monitorTask(taskDefinition) {
        const taskObject = PrivateTaskManager.initTaskRecursively(
            taskDefinition,
            PrivateTaskManager.id++
        );
        PrivateTaskManager.tasks.push(taskObject);
        if (!taskObject.hideMessages) {
            MessagesUtil.send(
                MessagesUtil.TYPES.GLOBAL,
                MessagesUtil.LEVELS.INFO,
                "msg_taskStarted",
                taskObject.title
            );
        }
        PrivateTaskManager.updateStoreInfo();
        return taskObject;
    }

    /**
     * Enqueue a task with steps for sequential execution, monitoring and display relevant information on its progress.
     * @param {Object} taskDefinition An object containing information about the task, means to display it and monitor it.
     * @param {string} taskDefinition.title
     * @param {Object[]} taskDefinition.steps
     * @param {string} taskDefinition.steps.title
     * @param {function} taskDefinition.steps.createPromise
     * @param {function} [taskDefinition.steps.render]
     * @param {function} [taskDefinition.render]
     * @param {Boolean} [taskDefinition.hideMessages]
     * @param {string} title a label to be displayed as the title of the task
     * @param {Object[]} steps an array of steps and defining sequential steps for the task to take and monitor.
     * @param {function} [createPromise] a function that initializes a step and returns a promise for monitoring
     * @param {function} [render] a function returning a JSX-Element to render, will be called with the taskObject (respectively subTaskObjet) as parameter
     * @param {Boolean} [hideMessages] if set to true, there will no messages be displayed on monitoring start and end.
     * @returns {TaskObject} a task object containing the given parameters and additional information set by the task manager.
     */
    static monitorSequentialTask(taskDefinition) {
        if (!taskDefinition.steps || !taskDefinition.steps.length) {
            ErrorUtil.handleError(
                "Failed to monitor task",
                ErrorUtil.createError(
                    ErrorUtil.LEVEL.ErrorUtil,
                    "There are no steps defined for the sequential task!"
                )
            );
            return null;
        }
        if (taskDefinition.steps.length < 2) {
            return TaskManager.monitorTask({
                ...taskDefinition,
                promise: taskDefinition.steps[0].createPromise()
            });
        }
        const taskObject = PrivateTaskManager.createTask(taskDefinition, PrivateTaskManager.id++);
        taskObject.promise = PrivateTaskManager.sequentialExecution(
            taskObject,
            taskDefinition.steps
        );
        PrivateTaskManager.bindMonitoring(taskObject, taskObject.promise);
        PrivateTaskManager.tasks.push(taskObject);
        if (!taskObject.hideMessages) {
            MessagesUtil.send(
                MessagesUtil.TYPES.GLOBAL,
                MessagesUtil.LEVELS.INFO,
                "msg_taskStarted",
                taskObject.title
            );
        }
        PrivateTaskManager.updateStoreInfo();
        return taskObject;
    }

    /**
     * @returns the tasks in the task list with their "ongoing"-Attribute === true
     */
    static getActiveTasks() {
        return PrivateTaskManager.tasks
            .filter((task) => task.ongoing)
            .sort((a, b) => (moment(a.start).isBefore(b.start) ? 1 : -1));
    }

    /**
     * @returns all the tasks in the task list
     */
    static getTasks() {
        return PrivateTaskManager.tasks.sort((a, b) =>
            moment(a.start).isBefore(b.start) ? 1 : -1
        );
    }

    /**
     * Opens the sidebar of the taskManager
     */
    static openSidebar() {
        TaskMonitor.openSidebar();
    }
}
