class NewsDefinition {
    static defaultState = {
        filter: null,
        sort: null,
        rows: 10,
        first: 0,
        totalRecords: 0,
        selection: "",
        entries: []
    };
    static defaultFilter = null;
    static defaultDetailState = {};
    static defaultDialogState = {};
}

export default NewsDefinition;
