import MessagesAction from "./MessagesAction";
import ObjectUtil from "../../util/ObjectUtil";
import MessagesUtil from "../../util/MessagesUtil";

const defaultState = {
    [MessagesUtil.TYPES.GLOBAL]: [],
    [MessagesUtil.TYPES.SITE]: [],
    [MessagesUtil.TYPES.DIALOG]: []
};

const Messages = {
    messages: (messageState = defaultState, action) => {
        if (action.category === MessagesAction.CATEGORY) {
            const stateCopy = ObjectUtil.deepDataCopy(messageState);
            if (action.type === MessagesAction.SEND) {
                stateCopy[action.data.messageType].push(action.data.messageData);
                return stateCopy;
            } else if (action.data.messageType) {
                stateCopy[action.data.messageType] = ObjectUtil.deepDataCopy(
                    defaultState[action.data.messageType]
                );
                return stateCopy;
            }
            return ObjectUtil.deepDataCopy(defaultState);
        }
        return messageState;
    }
};

export default Messages;
