import React from "react";
import PropTypes from "prop-types";
import {withNamespaces as translate} from "react-i18next";
import {Dialog} from "primereact/dialog";
import {ProgressSpinner} from "primereact/progressspinner";
import AddressRequest from "../../../requests/AddressRequest";
import {Button} from "primereact/button";
import ErrorUtil from "../../../util/ErrorUtil";
import {hasPermission, Features, Permissions} from "../../../util/PermissionUtil";
import ViewUtil from "../../../util/ViewUtil";
import RedirectUtil from "../../../util/RedirectUtil";

class DialogProfile extends React.PureComponent {
    static propTypes = {
        t: PropTypes.func
    };

    state = {
        profile: null,
        uuid: null,
        visible: false
    };

    componentDidMount() {
        PrivateProfileDialog.instance = this;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.uuid !== this.state.uuid) {
            this.retrieveProfile();
        }
    }

    retrieveProfile = this.retrieveProfile.bind(this);
    async retrieveProfile() {
        if (this.state.uuid != null) {
            this.setState({loading: true});
            const profile = await AddressRequest.loadContact(this.state.uuid);
            this.setState({profile: profile, loading: false});
        }
    }

    onHide = () => {
        this.setState({visible: false, uuid: null, profile: null});
    };

    render() {
        const {profile} = this.state;
        return (
            <Dialog
                className="f-profile-dialog"
                footer={this.renderFooter()}
                width="500px"
                closable={false}
                visible={this.state.visible}
                onHide={this.onHide}
                modal
                responsive
            >
                <div className="f-profile-header">
                    <div className="f-profile-image">
                        <i className="material-icons f-profile-icon">person</i>
                    </div>
                    <div className="f-profile-name">
                        {profile ? profile.lastName + " " + profile.firstName : ""}
                    </div>
                </div>
                <div className="f-profile-content">
                    <div className="f-profile-entry">
                        <div className="f-profile-entry-label">
                            {this.props.t("profile_company") + ":"}
                        </div>
                        <div className="f-profile-entry-value">
                            {profile ? profile.company : ""}
                        </div>
                    </div>
                    <div className="f-profile-entry">
                        <div className="f-profile-entry-label">
                            {this.props.t("profile_phone") + ":"}
                        </div>
                        <div className="f-profile-entry-value">
                            {Boolean(profile && profile.phone) &&
                                ViewUtil.formatPhoneField(profile.phone)}
                        </div>
                        {hasPermission(Permissions.view, Features.profile_intern) &&
                            Boolean(profile && profile.extension) && (
                                <div className="f-profile-phone-intern">
                                    <a
                                        href={"tel:" + profile.extension}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            RedirectUtil.triggerProtocolUrl(
                                                "tel:" + profile.extension
                                            );
                                        }}
                                    >
                                        {"(" + this.props.t("profile_intern") + ")"}
                                    </a>
                                </div>
                            )}
                    </div>
                    <div className="f-profile-entry">
                        <div className="f-profile-entry-label">
                            {this.props.t("profile_mobile") + ":"}
                        </div>
                        <div className="f-profile-entry-value">
                            {Boolean(profile && profile.mobile) &&
                                ViewUtil.formatPhoneField(profile.mobile)}
                        </div>
                    </div>
                    <div className="f-profile-entry">
                        <div className="f-profile-entry-label">
                            {this.props.t("profile_email") + ":"}
                        </div>
                        <div className="f-profile-entry-value">
                            {Boolean(profile && profile.Email) &&
                                ViewUtil.formatEmailField(profile.Email)}
                        </div>
                    </div>
                </div>
                {/*Sending overlay*/}
                {this.state.loading ? (
                    <div className="f-executing-overlay">
                        <div className="f-executing-overlay-content">
                            <ProgressSpinner />
                        </div>
                    </div>
                ) : (
                    ""
                )}
            </Dialog>
        );
    }

    renderFooter = () => {
        return (
            <div>
                <Button
                    label={this.props.t("gen_close")}
                    icon="pi pi-times"
                    onClick={this.onHide}
                />
            </div>
        );
    };
}

class PrivateProfileDialog {
    static instance = null;
}

export default class ProfileDialog {
    static component = translate("fleet")(DialogProfile);

    static displayForUUID(uuid) {
        if (!PrivateProfileDialog.instance) {
            ErrorUtil.handleError(
                "msg_profileDialog",
                ErrorUtil.createError(
                    ErrorUtil.LEVEL.ERROR,
                    "No Address Dialog instance could be found!"
                )
            );
            return;
        }
        // console.log("Display for:", uuid);
        PrivateProfileDialog.instance.setState({
            uuid: uuid,
            visible: true
        });
    }
}
