import React from "react";
import PropTypes from "prop-types";

import LabeledField from "./LabeledField";
import {InputText} from "primereact/inputtext";
import MessagesUtil from "../../../util/MessagesUtil";

class InputNumber extends React.PureComponent {
    static propTypes = {
        name: PropTypes.string.isRequired,
        label: PropTypes.string,
        className: PropTypes.string,
        value: PropTypes.number,
        minValue: PropTypes.number,
        maxValue: PropTypes.number,
        edit: PropTypes.bool,
        required: PropTypes.bool,
        decimal: PropTypes.bool,
        negative: PropTypes.bool,
        suffix: PropTypes.string,
        error: PropTypes.bool,
        onChange: PropTypes.func,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func
    };

    state = {
        value: 0
    };

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState(
                {
                    value: this.parseValue(this.props.value || 0)
                },
                () => {
                    if (this.state.isFocused) {
                        this.inputEl.element.select();
                    }
                }
            );
        }
    }

    onChange = (changeEvent) => {
        //Remove leading 0s
        const value = (changeEvent.target.value || "").replace(/^0(?![,.])/, "") || "0";
        if (
            (!this.props.negative && !this.props.decimal) ||
            (this.props.decimal && this.props.negative && /^-?[0-9]*([,.][0-9]*)?$/.test(value)) ||
            (this.props.decimal && !this.props.negative && /^[0-9]*([,.][0-9]*)?$/.test(value)) ||
            (!this.props.decimal && this.props.negative && /^-?[0-9]*?$/.test(value))
        ) {
            this.setState({value: value});
        }
    };

    onBlur = (blurEvent) => {
        if (this.props.onBlur) {
            this.props.onBlur(blurEvent);
        }
        const validValue =
            this.state.value > this.props.maxValue
                ? this.props.maxValue
                : this.state.value < this.props.minValue
                ? this.props.minValue
                : this.state.value;

        if (this.state.value > this.props.maxValue) {
            MessagesUtil.send(
                MessagesUtil.TYPES.GLOBAL,
                MessagesUtil.LEVELS.WARN,
                "msg_valueAboveMax"
            );
        } else if (this.state.value < this.props.minValue) {
            MessagesUtil.send(
                MessagesUtil.TYPES.GLOBAL,
                MessagesUtil.LEVELS.WARN,
                "msg_valueBelowMin"
            );
        }
        const parsedValue = this.parseValue(validValue);
        if (this.props.onChange && validValue !== this.props.value) {
            this.props.onChange({name: this.props.name, value: parsedValue});
        }
        this.setState(
            {
                value: this.formatValue(parsedValue),
                isFocused: false,
                highlighted:
                    this.state.value > this.props.maxValue || this.state.value < this.props.minValue
            },
            () => {
                if (this.state.highlighted) {
                    this.highlightTimeout = setTimeout(
                        () => this.setState({highlighted: false}),
                        500
                    );
                }
            }
        );
    };

    parseValue = (value = this.state.value) => {
        if (!isNaN(value)) {
            return this.parseNumber(value);
        }
        const deLocalized = value.replace(",", ".");
        if (!isNaN(deLocalized)) {
            return this.parseNumber(deLocalized);
        }
        return 0;
    };

    formatValue = (number = this.parseValue()) => {
        const result = this.props.decimal
            ? number.toLocaleString("de", {minimumFractionDigits: 2, maximumFractionDigits: 2})
            : number.toLocaleString("de", {maximumFractionDigits: 0});
        return result;
    };

    parseNumber(value) {
        const parsedNumber = this.props.decimal ? Number(Number(value).toFixed(2)) : Number(value);
        return parsedNumber;
    }

    render() {
        const {
            name,
            className,
            label,
            edit = true,
            required = false,
            decimal = false,
            negative = false,
            suffix = "",
            error = false
        } = this.props;
        const formattedValue = this.formatValue(
            this.parseValue(this.props.value || this.state.value)
        );
        const keyfilter =
            decimal && negative
                ? /[0-9,.-]/
                : !decimal && !negative
                ? /[0-9]/
                : decimal
                ? /[0-9,.]/
                : /[0-9-]/;
        return (
            <LabeledField
                name={name}
                className={className}
                label={label}
                edit={edit}
                suffix={suffix}
                error={error || (required && typeof this.state.value === "undefined")}
                formattedValue={formattedValue}
            >
                <InputText
                    className={
                        "f-number-input" + (this.state.highlighted ? " f-highlight-animation" : "")
                    }
                    name={name}
                    value={this.state.isFocused ? this.state.value : formattedValue}
                    onChange={this.onChange}
                    keyfilter={keyfilter}
                    onFocus={(focusEvent) => {
                        if (this.props.onFocus) {
                            this.props.onFocus(focusEvent);
                        }
                        this.setState(
                            {
                                isFocused: true,
                                value: this.parseValue(this.props.value || 0)
                            },
                            () => this.inputEl.element.select()
                        );
                    }}
                    onBlur={this.onBlur}
                    ref={(el) => (this.inputEl = el)}
                />
            </LabeledField>
        );
    }
}

export default InputNumber;
