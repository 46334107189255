// @ts-check
class DamageDefinition {
    // #region Damage Overview
    static defaultFilter = {
        licensePlate: {value: ""},
        iluCode: {value: ""},
        damageID: {value: ""},
        ticketID: {value: ""},
        ticketDat: {value: null},
        damageState: {
            value: ["damage_state0", "damage_state1", "damage_state2"],
            matchMode: "In"
        }
    };

    static defaultState = {
        entries: [],
        selectionUUIDs: [],
        filter: DamageDefinition.defaultFilter,
        sort: {sortField: "ticketDat", sortOrder: -1},
        rows: 50,
        first: 0,
        totalRecords: 0
    };
    //#endregion
}
export default DamageDefinition;
