import React from "react";
import MessagesUtil from "../../util/MessagesUtil";
import MessagesConsumer from "./MessagesConsumer";

class OnSiteMessages extends React.PureComponent {
    parseMessage = (message) => {
        return {
            severity: message.level,
            summary: message.title,
            detail: message.text,
            life: 20000
        };
    };

    render() {
        return (
            <MessagesConsumer
                messageType={MessagesUtil.TYPES.SITE}
                parseMessage={this.parseMessage}
                translate
                {...this.props}
            />
        );
    }
}

export default OnSiteMessages;
