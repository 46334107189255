import DamageDefinition from "./DamageDefinition";
import DamageAction from "./DamageAction";
import DefaultStoreUtil from "../../util/DefaultStoreUtil";

const Damage = {
    damage: DefaultStoreUtil.defaultReducerFunction(
        DamageAction.CATEGORY,
        DamageDefinition.defaultState
    )
};
export default Damage;
// #endregion
