// #region Reducer
class TranslationDefinition {
    static defaultDialogState = {
        languages: ["en", "de"],
        element: null
    };

    static defaultFilter = null;

    static defaultState = {
        filter: TranslationDefinition.defaultFilter,
        sort: null,
        rows: 10,
        first: 0,
        totalRecords: 0,
        selection: "",
        entries: [],
        dialog: TranslationDefinition.defaultDialogState
    };
}
export default TranslationDefinition;
