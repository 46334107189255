import {prioritizedRequest, simpleRequest} from "../util/RequestUtil";
import UriList from "../service/EndpointsList";

class SystemStatusRequest {
    static async loadStatus() {
        return simpleRequest(UriList.MONITOR_STATUS);
    }

    static async loadOverview() {
        return simpleRequest(UriList.MONITOR_OVERVIEW);
    }

    static async loadDetail(id) {
        return prioritizedRequest(UriList.MONITOR_DETAIL + "/" + id);
    }
}

export default SystemStatusRequest;
