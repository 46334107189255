//@ts-check
import OrderAction from "./OrderAction";
import OrderDefinition from "./OrderDefinition";
import DefaultStoreUtil from "../../util/DefaultStoreUtil";

const Order = {
    order: DefaultStoreUtil.defaultReducerFunction(
        OrderAction.CATEGORY,
        OrderDefinition.defaultState
    )
};

export default Order;
