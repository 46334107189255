//@ts-check

import {prioritizedRequest} from "../util/RequestUtil";
import UriList from "../service/EndpointsList";

class ChatRequest {
    static async loadMessages(uuid, params) {
        const response = await prioritizedRequest(UriList.CHAT_CHAT + "/" + uuid, {
            params: {sort: [{sortField: "creDat", sortOrder: 0}], ...params}
        });
        return response;
    }

    static async loadNewChats(age, uuid, role) {
        const response = await prioritizedRequest(
            UriList.CHAT_NEW + "?age=" + age + "&uuid=" + uuid + "&role=" + role
        );
        return response;
    }

    static async loadNewPVMChats(age, uuid, role) {
        const response = await prioritizedRequest(
            UriList.PARTNER_CHAT_NEW + "?age=" + age + "&uuid=" + uuid + "&role=" + role
        );
        return response;
    }

    static async sendMessage(uuid, reference, message) {
        const response = await prioritizedRequest(UriList.CHAT_CHAT + "/" + uuid, {
            method: "POST",
            body: JSON.stringify({message: message, reference: reference}),
            formdata: true
        });
        return response;
    }

    static async loadInternalMessages(uuid, params) {
        const response = await prioritizedRequest(UriList.CHAT_CHAT + "/" + uuid, {
            params: {type: "internal", sort: [{sortField: "creDat", sortOrder: 0}], ...params}
        });
        return response;
    }

    static async sendInternalMessage(uuid, reference, message) {
        const response = await prioritizedRequest(UriList.CHAT_CHAT + "/" + uuid, {
            method: "POST",
            body: JSON.stringify({message: message, reference: reference, type: "internal"}),
            formdata: true
        });
        return response;
    }
}

export default ChatRequest;
