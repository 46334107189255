class TicketDefinition {
    // #region Ticket Detail
    static defaultDetailState = {
        uuid: "",
        token: "",
        fixedAction: undefined,
        element: {
            uuid: null,
            version: 0,
            ticketID: null,
            ticketDate: null,
            ticketLocation: null,
            ticketCondition: null,
            ticketSource: null,
            ticketReferenceID: null,
            locationOwner: null,
            truckerName: null,
            truckerKey: null,
            truckerReferenceID: null,
            truckerUUID: null,
            driverName: null,
            vehicleUUID: null,
            vin: null,
            licensePlate: null,
            iluCode: null,
            firstRegistration: null,
            axleManufacturer: null,
            tpms: null,
            customerType: null,
            userUUID: null,
            userFirstname: null,
            userLastname: null,
            dispoUserUUID: null,
            dispoUserFullname: null,
            dispoUserFirstname: null,
            dispoUserLastname: null,
            stateColor: null,
            latitude: null,
            longitude: null,
            causerAddressUUID: null,
            causerAddressName1: null,
            note: null,
            ticketAddress: null,
            addressTXT: null,
            modificationUser: null,
            modificationDate: null,
            creationUser: null,
            creationDate: null,
            continueTransport: null,
            ticketState: {
                uuid: "d567a842-ad0a-418e-a919-7a4fa8ae6282",
                label: "ticket_state0",
                note: null
            },
            ticketType: {
                uuid: null,
                label: null,
                note: null
            },
            ticketFile: null,
            ticketDamage: [
                {
                    damageGroupUUID: "5bb12843-03e9-428f-98ec-4ec4f4e7103f",
                    damageGroupLabel: "damage_group00",
                    damageType: [
                        {
                            damageTypeUUID: "b1015807-f204-4557-a134-282ccbb2a8f7",
                            typeLabel: "damage_type01",
                            selected: false
                        },
                        {
                            damageTypeUUID: "0ff2be6c-a299-4c9a-815a-3e8bd6e1d7fb",
                            typeLabel: "damage_type02",
                            selected: false
                        },
                        {
                            damageTypeUUID: "8f9bc930-288f-41ab-a54e-c40809afe2e1",
                            typeLabel: "damage_type03",
                            selected: false
                        },
                        {
                            damageTypeUUID: "51339784-f845-471a-bad8-6a06e8063910",
                            typeLabel: "damage_type04",
                            selected: false
                        },
                        {
                            damageTypeUUID: "b0d46ade-8ae8-4178-9b16-b9326a9f08d4",
                            typeLabel: "damage_type05",
                            selected: false
                        },
                        {
                            damageTypeUUID: "73b4faff-6b7a-44fc-b40f-5303ac12fe6f",
                            typeLabel: "damage_type06",
                            selected: false
                        }
                    ]
                },
                {
                    damageGroupUUID: "173c41d9-6377-4faf-805a-401bc21427b2",
                    damageGroupLabel: "damage_group20",
                    damageType: [
                        {
                            damageTypeUUID: "f07b233b-dbec-4592-8d16-c9e77f9d8455",
                            typeLabel: "damage_type20",
                            selected: false
                        }
                    ]
                },
                {
                    damageGroupUUID: "c5a456ce-e6df-42f3-8b41-5a84c74ca5a1",
                    damageGroupLabel: "damage_group30",
                    damageType: [
                        {
                            damageTypeUUID: "b3b27cc8-6fcc-4dcc-ba51-67d527051ed1",
                            typeLabel: "damage_type30",
                            selected: false
                        }
                    ]
                },
                {
                    damageGroupUUID: "d4c0a1cb-1439-45c6-bbfa-29675cf3de28",
                    damageGroupLabel: "damage_group40",
                    damageType: [
                        {
                            damageTypeUUID: "44e7b1a7-6065-4fc6-bcc0-5f84560a1450",
                            typeLabel: "damage_group40",
                            selected: false
                        }
                    ]
                },
                {
                    damageGroupUUID: "cfa97549-c924-4dde-a02b-7a2001c57ff8",
                    damageGroupLabel: "damage_group50",
                    damageType: [
                        {
                            damageTypeUUID: "c92f12e5-dbc0-40b7-8867-6b203122a241",
                            typeLabel: "damage_type51",
                            selected: false
                        },
                        {
                            damageTypeUUID: "80f8a2ce-e606-4986-b894-87a361740ff9",
                            typeLabel: "damage_type52",
                            selected: false
                        },
                        {
                            damageTypeUUID: "f44f3c31-40a4-4667-9206-9ea91ce9eac0",
                            typeLabel: "damage_type53",
                            selected: false
                        }
                    ]
                },
                {
                    damageGroupUUID: "b9c14061-f1bd-4054-810d-2f11e87810f3",
                    damageGroupLabel: "damage_group60",
                    damageType: [
                        {
                            damageTypeUUID: "fbd95f51-4340-46c7-9966-e8ad93066a4c",
                            typeLabel: "damage_type61",
                            selected: false
                        },
                        {
                            damageTypeUUID: "b620c199-7f6d-4efc-b992-8fd63390b3ed",
                            typeLabel: "damage_type62",
                            selected: false
                        }
                    ]
                },
                {
                    damageGroupUUID: "b737e993-5d3d-4966-855d-0f2cac986da3",
                    damageGroupLabel: "damage_group70",
                    damageType: [
                        {
                            damageTypeUUID: "89803d10-2f96-4143-b65e-15e0ae7b8e69",
                            typeLabel: "damage_type31",
                            selected: false
                        },
                        {
                            damageTypeUUID: "74663b51-088d-45d7-9302-160b317991d1",
                            typeLabel: "damage_type71",
                            selected: false
                        },
                        {
                            damageTypeUUID: "d8d674fb-6716-4008-848c-84b69162d2bc",
                            typeLabel: "damage_type72",
                            selected: false
                        },
                        {
                            damageTypeUUID: "f4fa9686-2365-4ecd-972e-67c17fccdccb",
                            typeLabel: "damage_type73",
                            selected: false
                        },
                        {
                            damageTypeUUID: "4fff0e12-1ba6-4afe-a56a-0b0802ea8666",
                            typeLabel: "damage_type75",
                            selected: false
                        }
                    ]
                },
                {
                    damageGroupUUID: "4308d753-76a4-403b-b302-335ede2c9bd1",
                    damageGroupLabel: "damage_group80",
                    damageType: [
                        {
                            damageTypeUUID: "ec4dedf0-f5d7-44be-8dd4-e23483ec7dc6",
                            typeLabel: "damage_group80",
                            selected: false
                        }
                    ]
                },
                {
                    damageGroupUUID: "2d88185d-d735-4c54-a298-049da5be5351",
                    damageGroupLabel: "damage_group90",
                    damageType: [
                        {
                            damageTypeUUID: "d8226dc2-fd6a-4335-ab0e-682f7dc7240e",
                            typeLabel: "damage_type90",
                            selected: false
                        }
                    ]
                },
                {
                    damageGroupUUID: "6647c5f7-bb66-4156-8e76-2b2108ea9914",
                    damageGroupLabel: "damage_group95",
                    damageType: [
                        {
                            damageTypeUUID: "1f43b44e-2b2a-4ef9-99f5-ec8e991699d6",
                            typeLabel: "damage_type951",
                            selected: false
                        },
                        {
                            damageTypeUUID: "6121e81f-f8d9-47d5-80b2-ab7378fc0849",
                            typeLabel: "damage_type952",
                            selected: false
                        },
                        {
                            damageTypeUUID: "d8ff831d-8389-4c0a-9b64-05fb371aeda9",
                            typeLabel: "damage_type953",
                            selected: false
                        },
                        {
                            damageTypeUUID: "ef618e00-3080-457e-8c97-daf566fe4173",
                            typeLabel: "damage_type954",
                            selected: false
                        }
                    ]
                },
                {
                    damageGroupUUID: "b8f0a288-10ed-48fd-a522-214323a60140",
                    damageGroupLabel: "damage_typeOK",
                    damageType: [
                        {
                            damageTypeUUID: "cb5b217e-b446-4ef0-b7ec-4aa51be6152f",
                            typeLabel: "damage_typeOK",
                            selected: false
                        }
                    ]
                }
            ],
            ticketForward: null
        }
    };
    // #endregion

    // #region Ticket Overview
    static defaultFilter = {
        ticketID: {value: ""},
        ticketDat: {
            name: "ticketDat",
            value: {}
        },
        locationOwner: {value: []},
        truckerName: {value: ""},
        truckerKey: {value: ""},
        ticketState: {
            value: ["ticket_state0", "ticket_state1", "ticket_state2"],
            matchMode: "in"
        },
        damages: {value: []},
        user: {value: null},
        dispoUser: {value: null},
        stateColor: {value: ""},
        licensePlate: {value: ""},
        vin: {value: ""}
    };

    static defaultState = {
        entries: [],
        selectionUUIDs: [],
        detail: TicketDefinition.defaultDetailState,
        filter: TicketDefinition.defaultFilter,
        sort: {sortField: "ticketDat", sortOrder: -1},
        rows: 50,
        first: 0,
        totalRecords: 0
    };
    // #endregion
}
export default TicketDefinition;
