import React from "react";
import PropTypes from "prop-types";
import AddressRequest from "../../../../requests/AddressRequest";
import AutoRequestField from "./AutoRequestField";
import {hasPermission, Features, Permissions} from "../../../../util/PermissionUtil";
import RedirectUtil from "../../../../util/RedirectUtil";
import ProfileDialog from "../../dialogs/DialogProfile";

class UserSearchField extends React.PureComponent {
    static propTypes = {
        name: PropTypes.string.isRequired,
        label: PropTypes.string,
        hideLabel: PropTypes.bool,
        className: PropTypes.string,
        defaultValue: PropTypes.shape({
            name1: PropTypes.string,
            referenceID: PropTypes.string,
            userUUID: PropTypes.string,
            addressUUID: PropTypes.string
        }),
        edit: PropTypes.bool,
        request: PropTypes.func,
        onSelect: PropTypes.func,
        icon: PropTypes.string,
        required: PropTypes.bool
    };

    openAddress = () => {
        RedirectUtil.openInNewWindow(
            RedirectUtil.pathList.DATA_ADDRESS_DETAIL,
            this.props.defaultValue.addressUUID,
            "view"
        );
    };

    reset = (value = this.props.defaultValue) => {
        this.searchField.reset(value);
    };

    // #region Lifecycle
    render() {
        return (
            <AutoRequestField
                name={this.props.name}
                label={this.props.label}
                hideLabel={this.props.hideLabel}
                className={this.props.className}
                field="fullname"
                defaultValue={this.props.defaultValue}
                itemTemplate={this.itemTemplate}
                edit={this.props.edit}
                request={this.props.request ? this.props.request : AddressRequest.searchUserContact}
                onSelect={this.props.onSelect}
                onSuffixClick={
                    this.props.defaultValue
                        ? this.props.defaultValue.userUUID &&
                          hasPermission(Permissions.view, Features.profile)
                            ? () => ProfileDialog.displayForUUID(this.props.defaultValue.userUUID)
                            : this.props.defaultValue.addressUUID &&
                              hasPermission(Permissions.view, Features.address)
                            ? this.openAddress
                            : undefined
                        : undefined
                }
                required={this.props.required}
                suffix={"i:account_circle"}
                ref={(el) => (this.searchField = el)}
                autoSelect
            />
        );
    }
    // #endregion

    // #region UI-Callbacks
    itemTemplate = (item) => {
        // console.log(item);
        return (
            <div key={item.uuid}>
                <div className="f-search-entry">
                    <div className="f-search-entry-icon">
                        <div
                            className={
                                "f-search-entry-icon-symbol pi-md-" +
                                (this.props.icon || "account-circle")
                            }
                        />
                    </div>
                    <div className="f-search-entry-content">
                        <div className={"f-search-entry-text f-search-entry-highlight"}>
                            {item.fullname}
                        </div>
                        <div className={"f-search-entry-text"}>{item.email}</div>
                    </div>
                </div>
            </div>
        );
    };
    // #endregion
}

export default UserSearchField;
