class ChangeType {
    static PERSIST = "PERSIST";
    static UPDATE = "UPDATE";
    static RELOAD = "RELOAD";

    static Reducer = {
        // eslint-disable-next-line no-unused-vars
        changeType: (changeTypeState = null, action) => {
            return action.changeType || null;
        }
    };
}

export default ChangeType;
