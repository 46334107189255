import React from "react";
import DateUtil from "./DateUtil";
import TranslationUtil from "./TranslationUtil";
import RedirectUtil from "./RedirectUtil";
import ColorUtil from "./ColorUtil";
import moment from "moment";

class PrivateViewUtil {
    static parsePhoneNumber(phone) {
        const filterTextStart = /^[^0-9+-.]{2,}/;
        const textStart = filterTextStart.exec(phone) || "";
        phone = textStart !== "" ? phone.replace(filterTextStart, "") : phone;

        const filterTextEnd = /[^0-9+-.]{2,}$/;
        const textEnd = filterTextEnd.exec(phone) || "";
        phone = textEnd !== "" ? phone.replace(filterTextEnd, "") : phone;

        phone = phone.replace(/[^0-9+]/g, "");

        phone = phone.replace(/^(0{2})/, "+");
        if (/^[^+]/.test(phone) && /^[^0]+/.test(phone)) {
            phone = "+" + phone;
        }

        return {
            textStart: textStart,
            phone: phone,
            textEnd: textEnd
        };
    }
}

class ViewUtil {
    // #region Geometry (calculate dimensions for content)
    static geometry(offset) {
        const height =
            window.innerHeight ||
            document.documentElement.clientHeight ||
            document.body.clientHeight;
        return height - offset;
    }

    static geometryHeight(offset) {
        return String(ViewUtil.geometry(offset)) + "px";
    }

    static geometryHeight2(offset) {
        return String(ViewUtil.geometry(offset)) / 1.9 + "px";
    }

    static calculateRows(offset) {
        const x = ViewUtil.geometry(offset) / 35;
        return Math.round(x) - 2;
    }

    static calculateRowsForHeight(containerHeight, rowHeight) {
        const x = containerHeight / rowHeight;
        const r = Math.round(x) - 1;
        return r;
    }

    static calculateDatatableHeight(dataTableRef) {
        if (dataTableRef) {
            const tableContainer = (dataTableRef.datatable || dataTableRef).container;
            let elements = tableContainer.getElementsByClassName("p-datatable-scrollable-body");
            let header = [];
            if (!elements || !elements.length) {
                elements = tableContainer.getElementsByClassName("p-datatable-wrapper");
                header = tableContainer.getElementsByClassName("p-datatable-thead");
            }
            if (elements && elements.length) {
                return (
                    elements[0].scrollHeight -
                    (header && header.length ? header[0].scrollHeight : 0)
                );
            }
        }
        return 100;
    }

    static calculateRowCount(dataTableRef, rowHeight, minRowCount) {
        if (rowHeight) {
            // const tableContainer = (dataTableRef.datatable || dataTableRef).container;
            // const elements = tableContainer.getElementsByClassName("p-datatable-row");
            // if (elements && elements.length) {
            //     return Math.floor(datatableHeight / elements[0].scrollHeight);
            // } else if (rowHeight) {
            const datatableHeight = ViewUtil.calculateDatatableHeight(dataTableRef);
            const rowCount = Math.floor(datatableHeight / rowHeight);
            return minRowCount ? Math.max(minRowCount, rowCount) : rowCount;
            // }
        }
        return 10;
    }

    // #region currently unused
    // static geometryHeight2(offset) {
    //     return String(ViewUtil.geometry(offset) / 2) + "px";
    // }

    // static geometryWidth(offset) {
    //     const width =
    //         window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    //     const r = String(width - offset) + "px";
    //     return r;
    // }
    // #endregion
    // #endregion

    // #region Transformers (formatting fields)
    static damagesView(data, t) {
        const c = data.damageCount;
        let d = "";
        if (data.damages !== "") {
            d = " (" + ViewUtil.splitAndTranslate(data.damages, t) + ")";
        }
        return c > 0 ? c + d : "";
    }

    static partnerDamagesView(data, t) {
        if (!data.damages || !data.damages.trim().length) {
            return "";
        }
        const damagesArray = data.damages.split(",");
        const d = " (" + damagesArray.map((damage) => t(damage)).join(", ") + ")";
        return damagesArray.length + d;
    }

    static licensePlate(data) {
        const style = {
            padding: "0.2em 0.5em"
        };
        if (data.stateColor !== "") {
            style.backgroundColor = data.stateColor;
            if (ColorUtil.calculateBrightness(data.stateColor) < 0.45) {
                style.color = "white";
            }
        }
        return <div style={style}>{data.licensePlate}</div>;
    }

    static forSaleDateField(date, timeDisplaySetting, trailerStatus) {
        const style = {
            padding: "0.2em 0"
        };
        if (trailerStatus && trailerStatus.readyForSale) {
            style.backgroundColor = trailerStatus.readyForSaleColor;
            if (ColorUtil.calculateBrightness(trailerStatus.readyForSaleColor) < 0.45) {
                style.color = "white";
            }
        }
        return <div style={style}>{DateUtil.dbDateToCellJSX(date, timeDisplaySetting)}</div>;
    }

    static getInspectionStyle(date, inspectionSetting) {
        const dateDiff = moment(date).diff(
            moment(),
            inspectionSetting.triggerPrecision === "M" ? "months" : "days"
        );
        const color =
            inspectionSetting.errorTrigger >= dateDiff
                ? inspectionSetting.errorColor
                : inspectionSetting.warningTrigger >= dateDiff
                ? inspectionSetting.warningColor
                : inspectionSetting.informationColor;
        return date && inspectionSetting.informationTrigger >= dateDiff
            ? {
                  backgroundColor: color,
                  color: ColorUtil.calculateBrightness(color) > 0.45 ? "black" : "white"
              }
            : {};
    }

    static nextInspection(data, t) {
        //exit on empty date
        if (data.nextLegalInspection === "") {
            return "";
        }
        let d = "";
        if (data.inspectionType !== "") {
            d = " (" + ViewUtil.splitAndTranslate(data.inspectionType, t) + ")";
        }
        const r = data.nextLegalInspection;
        return r !== "00.00.0000" ? DateUtil.formatDbDate(r) + d : "";
    }

    static splitAndTranslate(s, t) {
        if (s.includes(",")) {
            const p = s.split(",");
            let r = "";
            p.forEach((p) => {
                r = r + t(p) + ", ";
            });
            return r.slice(0, -2);
        }
        return t(s);
    }

    static formatPhoneField(phone) {
        if (!phone) {
            return "";
        }
        const phoneObj = PrivateViewUtil.parsePhoneNumber(phone);
        const phoneUrl =
            "tel:" + (phoneObj.phone.startsWith("+") ? phoneObj.phone : "0" + phoneObj.phone);
        return (
            <span>
                {phoneObj.textStart}
                <a
                    href={phoneUrl}
                    onClick={(e) => {
                        e.preventDefault();
                        RedirectUtil.triggerProtocolUrl(phoneUrl);
                    }}
                >
                    {phoneObj.phone}
                </a>
                {phoneObj.textEnd}
            </span>
        );
    }

    static formatPhoneString(phone) {
        if (!phone) {
            return "";
        }
        const phoneObj = PrivateViewUtil.parsePhoneNumber(phone);
        return phoneObj.textStart + phoneObj.phone + phoneObj.textEnd;
    }

    static formatEmailField(email) {
        if (!email) {
            return "";
        }

        const validEmail = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

        const html = validEmail.test(email) ? (
            <a
                href={"mailto:" + email}
                onClick={(e) => {
                    e.preventDefault();
                    RedirectUtil.triggerProtocolUrl("mailto:" + email);
                }}
            >
                {email}
            </a>
        ) : (
            email
        );

        return <span>{html}</span>;
    }

    static formatWebField(url) {
        if (!url) {
            return "";
        }

        const validUrl = /^(http[s]?:\/\/(www\.)?|www\.){1}([0-9A-Za-z-.@:%_+~#=]+)+((\.[a-zA-Z]{2,3})+)(\/(.)*)?(\?(.)*)?/;
        let fullUrl = url;
        const isValid = validUrl.test(url);

        if (isValid && url.indexOf("http") !== 0) {
            fullUrl = "http://" + url;
        }

        const html = isValid ? <a href={fullUrl}>{url}</a> : url;
        return <span>{html}</span>;
    }

    static formatAddress(address) {
        return address
            ? address.street +
                  " " +
                  address.streetNumber +
                  "\n" +
                  (address.country ? address.country + "-" : "") +
                  address.zip +
                  "\n" +
                  address.place +
                  (address.region ? " (" + address.region + ")" : "")
            : "";
    }

    static formatThousandSeparator(value) {
        return String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    static formatDecimal(value) {
        const roundDown = (v) => Math.floor(v * 100) / 100;
        return roundDown(parseFloat(value.toString().replace(",", ".")))
            .toFixed(2)
            .replace(".", ",");
    }
    // #endregion

    // #region Commonly used functions/elements
    static paginatorTotal(_this) {
        return _this.state.loadingEntries
            ? _this.props.t("gen_loadingRecords")
            : _this.props.t("gen_showRecords") +
                  " " +
                  (_this.state.totalRecords ? _this.state.first + 1 : 0) +
                  "-" +
                  (_this.state.rows
                      ? Math.min(_this.state.first + _this.state.rows, _this.state.totalRecords)
                      : _this.state.totalRecords) +
                  " (" +
                  _this.props.t("gen_total") +
                  " " +
                  _this.state.totalRecords +
                  ")";
    }

    static noRecordsMessage(isLoading) {
        if (!isLoading) {
            return "gen_noRecords";
        }
        return "gen_loadingRecords";
    }

    static classContent(left, small) {
        if (small && left) {
            return "f-input-content content-small";
        }
        if (small && !left) {
            return "f-input-content content-small content-right";
        }
        if (!small && !left) {
            return "f-input-content content-right";
        }
        return "f-input-content";
    }

    static buildIcon(name) {
        const p = name.split(":");
        return <i className="material-icons">{p[1]}</i>;
    }

    static colHeader(label, title = label) {
        const t = TranslationUtil.getT();
        const parsedLabel = Array.isArray(label) ? label.map((l) => t(l)).join(" ") : t(label);
        const parsedTitle =
            title === label
                ? parsedLabel
                : Array.isArray(title)
                ? title.map((l) => t(l)).join(" ")
                : t(title);
        return (
            <div className="f-table-header" title={parsedTitle}>
                {parsedLabel}
            </div>
        );
    }
    // #endregion
}

export default ViewUtil;
