class ReportDefinition {
    static defaultState = {
        filter: null,
        sort: null,
        rows: 10,
        first: 0,
        totalRecords: 0,
        selection: "",
        entries: []
    };
    static defaultFilter = null;
    static defaultDetailState = {
        damageTotalCost: 0
    };
}

export default ReportDefinition;
