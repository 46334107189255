//@ts-check
import {prioritizedRequest, simpleRequest} from "../util/RequestUtil";
import UriList from "../service/EndpointsList";
import TicketAction from "../store/ticket/TicketAction";
import {reject} from "q";
import ErrorUtil from "../util/ErrorUtil";

class TicketRequest {
    static async loadTicket(uuid) {
        if (!uuid) {
            return reject(ErrorUtil.createError(ErrorUtil.LEVEL.ERROR, "Missing UUID!"));
        }
        const response = await prioritizedRequest(UriList.TICKET_TICKET + "/" + uuid);
        return response;
    }

    static async persistTicket(uuid, ticket) {
        if (!ticket) {
            return reject(
                ErrorUtil.createError(ErrorUtil.LEVEL.ERROR, "Cannot persist a null ticket!")
            );
        }
        ticket.version++;
        const request = uuid ? prioritizedRequest : simpleRequest;
        const response = await request(UriList.TICKET_TICKET + (uuid ? "/" + uuid : ""), {
            method: uuid ? "PUT" : "POST",
            body: JSON.stringify(ticket),
            formdata: true
        });
        return response;
    }

    static async loadTickets(params) {
        const response = await prioritizedRequest(UriList.TICKET_TICKET, {params: params});
        return response;
    }

    static async loadTicketStates(params) {
        const response = await prioritizedRequest(UriList.TICKET_TICKETSTATE, params);
        return response;
    }

    static async forwardTicketToFM(uuid, data) {
        if (!uuid) {
            return reject(ErrorUtil.createError(ErrorUtil.LEVEL.ERROR, "Missing UUID!"));
        }
        if (!data) {
            return reject(
                ErrorUtil.createError(
                    ErrorUtil.LEVEL.ERROR,
                    "Cannot forward ticket without forwarding data!"
                )
            );
        }
        const response = await prioritizedRequest(UriList.TICKET_TICKET_FORWARD_FM(uuid), {
            method: "POST",
            body: JSON.stringify(data),
            formdata: true
        });
        return response ? true : false;
    }

    static async applyTicketAction(uuid, action) {
        if (!uuid) {
            return reject(ErrorUtil.createError(ErrorUtil.LEVEL.ERROR, "Missing UUID!"));
        }
        if (!action) {
            return reject(ErrorUtil.createError(ErrorUtil.LEVEL.ERROR, "Missing action!"));
        }
        let uri;
        switch (action) {
            case TicketAction.DETAIL_NO_REPAIR:
                uri = UriList.TICKET_TICKET_NO_REPAIR(uuid);
                break;
            case TicketAction.DETAIL_REPAIR_LATER:
                uri = UriList.TICKET_TICKET_REPAIR_LATER(uuid);
                break;
            case TicketAction.DETAIL_CHANGE_LOCATION:
                uri = UriList.TICKET_TICKET_CHANGE_LOCATION(uuid);
                break;
            case TicketAction.DETAIL_FORWARD_TM:
                uri = UriList.TICKET_TICKET_FORWARD_TM(uuid);
                break;
            case TicketAction.DETAIL_REOPEN:
                uri = UriList.TICKET_TICKET_REOPEN(uuid);
                break;
            default:
                return false;
        }

        const response = await prioritizedRequest(uri, {method: "PUT"});
        return response;
    }
}

export default TicketRequest;
