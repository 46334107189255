import React from "react";
import PropTypes from "prop-types";
import {withNamespaces as translate} from "react-i18next";
import {Menu} from "primereact/menu";

export const FILTER_MODES = {
    startswith: {
        operator: "startswith",
        label: "gen_filterModeStartswith",
        icon: "mdi mdi-contain-start"
    },
    endswith: {
        operator: "endswith",
        label: "gen_filterModeEndswith",
        icon: "mdi mdi-contain-end"
    },
    like: {
        operator: "like",
        label: "gen_filterModeLike",
        icon: "mdi mdi-contain"
    },
    notlike: {
        operator: "notlike",
        label: "gen_filterModeNotlike",
        icon: "mdi mdi-cancel"
    },
    equals: {
        operator: "=",
        label: "gen_filterModeEquals",
        icon: "mdi mdi-equal"
    }
};

class FilterMatchModeSwitch extends React.PureComponent {
    static propTypes = {
        t: PropTypes.func,
        className: PropTypes.string,
        default: PropTypes.string,
        customMatchModes: PropTypes.array,
        onChange: PropTypes.func.isRequired
    };

    state = {
        modes: this.props.customMatchModes || FILTER_MODES,
        matchMode: this.props.default || "startswith"
    };

    generateActions = (t) => {
        const modes = this.state.modes;

        return Object.keys(modes).map((m) => ({
            label: t(modes[m].label),
            icon: modes[m].icon,
            command: () =>
                this.setState({matchMode: m}, () => this.props.onChange(modes[m].operator))
        }));
    };

    render() {
        return (
            <span className={this.props.className} style={{fontSize: "0.8em"}}>
                <i
                    onClick={(e) => {
                        this.modeMenu.toggle(e);
                    }}
                    className={"material-icons " + this.state.modes[this.state.matchMode].icon}
                    tooltip="test"
                />

                <Menu
                    model={this.generateActions(this.props.t)}
                    style={{
                        border: "none"
                    }}
                    ref={(el) => (this.modeMenu = el)}
                    popup={true}
                    appendTo={document.body}
                />
            </span>
        );
    }
}

export default translate("fleet")(FilterMatchModeSwitch);
