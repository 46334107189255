import React from "react";
import {withNamespaces as translate} from "react-i18next";
import {PropTypes} from "prop-types";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {Sidebar} from "primereact/sidebar";
import ChangeType from "../../store/changeType/ChangeType";
import moment from "moment";
import {ProgressSpinner} from "primereact/progressspinner";
import {Fieldset} from "primereact/fieldset";
import {observeStore} from "../../util/StoreUtil";
import DateUtil from "../../util/DateUtil";
import TaskManager from "./TaskManager";

class TaskMonitor extends React.PureComponent {
    static propTypes = {
        t: PropTypes.func
    };

    static instance = null;

    static openSidebar() {
        TaskMonitor.instance.setState({sidebarVisible: true});
    }

    // taskTest = () => {
    // const taskCount = 20; //Math.ceil(Math.random() * 2);
    // for (let i = 0; i < taskCount; i++) {
    //     const waitTime = (10 + Math.ceil(Math.random() * 50)) * 1000;
    //     TaskManager.monitorTask({
    //         title: waitTime / 1000 + " Sekunden",
    //         promise: new Promise((resolve, reject) => {
    //             setTimeout(() => {
    //                 if (Math.random() * 2 > 1) {
    //                     reject();
    //                 } else {
    //                     resolve();
    //                 }
    //             }, waitTime);
    //         })
    //     });
    // }
    // };

    // #region Set-Up Lifecycle
    constructor(props) {
        super(props);
        // setInterval(this.taskTest, 2000);
        TaskMonitor.instance = this;
        this.state = {
            dialogVisible: false,
            sidebarVisible: false
        };

        this.taskStore = observeStore(
            (state) => state.tasks,
            this.onStoreChange,
            ChangeType.UPDATE
        );
    }

    componentDidMount() {
        // this.taskTest();
    }
    // #endregion

    // #region UI Callbacks
    onStoreChange = (newState) => this.setState({...newState});
    // #endregion

    // #region Class Functions
    isChangeOngoing = () => {
        return false;
    };

    onHideDialog = () => {
        this.setState({dialogVisible: false});
    };
    onHideSidebar = () => {
        this.setState({sidebarVisible: false});
    };
    // #endregion

    // #region Render Function
    render() {
        return (
            <div className="f-task">
                <Sidebar
                    className="f-task-sidebar"
                    visible={this.state.sidebarVisible}
                    position="right"
                    onHide={this.onHideSidebar}
                >
                    <div>{this.renderSidebar()}</div>
                </Sidebar>
                <Dialog
                    header={this.props.t("gen_tasks")}
                    showHeader
                    footer={
                        <div>
                            <Button
                                label={this.props.t("gen_ok")}
                                icon="pi pi-check"
                                disabled={this.isChangeOngoing()}
                                onClick={() => {
                                    this.onHideDialog();
                                }}
                            />
                        </div>
                    }
                    width="500px"
                    modal
                    responsive
                    visible={this.state.dialogVisible}
                    onHide={this.onHideDialog}
                    closable={false}
                >
                    <div className="f-task-dialog">{this.renderSidebar()}</div>
                </Dialog>
            </div>
        );
    }
    // #endregion

    // #region Render Co-Functions
    renderSidebar = () => {
        const tasks = TaskManager.getTasks().sort((a, b) => {
            if (a.ongoing !== b.ongoing) {
                return a.ongoing ? -1 : 1;
            }
            const aDate = a.end || a.start;
            const bDate = b.end || b.start;
            return moment(aDate).isBefore(bDate) ? 1 : -1;
        });
        const taskStatistic = tasks.reduce(
            (statistic, task) => {
                if (task.ongoing) {
                    statistic.active++;
                } else if (task.done) {
                    statistic.done++;
                } else {
                    statistic.failed++;
                }
                return statistic;
            },
            {active: 0, done: 0, failed: 0}
        );
        return (
            <div className="f-task-area">
                <div className="f-task-title">{this.props.t("gen_tasks") + ":"}</div>
                <div className="f-task-summary">
                    <div className="f-task-summary-entry f-task-active">
                        {taskStatistic.active + " " + this.props.t("gen_activeTasks")}
                    </div>
                    <div className="f-task-summary-entry f-task-done">
                        {taskStatistic.done + " " + this.props.t("gen_doneTasks")}
                    </div>
                    <div className="f-task-summary-entry f-task-failed">
                        {taskStatistic.failed + " " + this.props.t("gen_failedTasks")}
                    </div>
                </div>
                {this.renderTasks(tasks)}
            </div>
        );
    };

    renderTasks = (tasks) => {
        return (
            <div className="f-task-container">
                {tasks && tasks.length ? (
                    tasks.map((task) => {
                        return this.renderTask(task);
                    })
                ) : (
                    <div className="f-task-no-items">
                        {" "}
                        <div className="f-task-no-items-text">
                            {this.props.t("gen_noTaskItems")}{" "}
                        </div>
                    </div>
                )}
            </div>
        );
    };

    renderTask = (task, id, depth = 1) => {
        return (
            <div
                key={"task" + (id || task.id)}
                className={
                    "f-task-item" +
                    (task.done ? " f-task-done" : task.failed ? " f-task-failed" : " f-task-active")
                }
            >
                <div className="f-task-item-date">{this.renderTime(task, depth)}</div>
                <div className="f-task-item-description">
                    <div className="f-task-item-icon">
                        {task.ongoing ? (
                            <ProgressSpinner className="f-task-spinner" strokeWidth="7" />
                        ) : task.done ? (
                            <div className="pi-md-check" />
                        ) : (
                            <div className="pi-md-cancel" />
                        )}
                    </div>
                    <div className="f-task-item-content">
                        <div className="f-task-item-title">{this.props.t(task.title)}</div>
                        {typeof task.render === "function" && (
                            <div className="f-task-item-render">{task.render(task)}</div>
                        )}
                        {task.err && (
                            <div className="f-task-item-error">
                                {typeof task.err === "string"
                                    ? task.err
                                    : task.err.label
                                    ? this.props.t(task.err.label)
                                    : this.props.t("gen_unkownError")}
                            </div>
                        )}
                    </div>
                </div>
                {depth < 3 && task.subTasks && task.subTasks.length && (
                    <div className="f-task-item-subtasks">
                        <Fieldset legend={this.props.t("gen_subTasks")} toggleable collapsed>
                            {task.subTasks.map((subTask, index) => {
                                return this.renderTask(subTask, depth + "-" + index, depth + 1);
                            })}
                        </Fieldset>
                    </div>
                )}
            </div>
        );
    };

    renderTime = (task, depth) => {
        if (depth > 1) {
            const millis = moment(task.end).diff(moment(task.start), "milliseconds");
            if (millis > 60000) {
                return (millis / 60000 + "m").replace(".", ",");
            }
            if (millis > 1000) {
                return (millis / 1000 + "s").replace(".", ",");
            }
            return millis + "ms";
        }
        return DateUtil.formatDateTimeSeconds(task.end || task.start);
    };
    // #endregion

    // #region tear-down lifecycle
    componentWillUnmount() {
        this.taskStore.unsubscribe();
    }
    // #endregion
}

export default translate("fleet")(TaskMonitor);
