import {PropTypes} from "prop-types";
import BaseService from "../BaseService";
import TranslationAction from "../../store/translation/TranslationAction";
import ChangeType from "../../store/changeType/ChangeType";
import TranslationDefinition from "../../store/translation/TranslationDefinition";
import TranslationRequest from "../../requests/TranslationRequest";
import ErrorUtil from "../../util/ErrorUtil";
import MessagesUtil from "../../util/MessagesUtil";
import {hasPermission, Features, Permissions} from "../../util/PermissionUtil";

const PROD_URL = "https://fleet-router.styletronic.eu";

class TranslationDialogService extends BaseService {
    // #region selection function
    static selectFunction = (state) =>
        state.translation && state.translation.dialog ? state.translation.dialog : null;
    // #endregion
    static propTypes = {
        uuid: PropTypes.string,
        type: PropTypes.string
    };

    // #region  Set-Up Lifecycle
    constructor(props) {
        super(props, TranslationDialogService.selectFunction);
        this.state = {
            uuid: props.uuid,
            options:
                props.type === "prod" && hasPermission(Permissions.edit, Features.development)
                    ? {url: PROD_URL}
                    : null
        };
    }

    onServiceInit() {
        if (this.state.uuid) {
            this.loadTranslation();
        }
        this.loadLanguages();
    }
    // #endregion

    // #region Store Callback
    onPersist(state, actionType) {
        if (
            actionType === TranslationAction.DIALOG_TRANSLATION ||
            actionType === TranslationAction.DIALOG_TRANSLATION_PRODUCTIVE
        ) {
            TranslationRequest.persistTranslation(
                this.props.uuid,
                state.element,
                this.state.options
            )
                .catch((err) => {
                    if (this.props.uuid) {
                        ErrorUtil.handleError("msg_translationUpdateFailed", err, true);
                    } else {
                        ErrorUtil.handleError("msg_translationCreateFailed", err, true);
                    }
                })
                .then((response) => {
                    if (response) {
                        MessagesUtil.send(
                            MessagesUtil.TYPES.GLOBAL,
                            MessagesUtil.LEVELS.SUCCESS,
                            "msg_saveSuccess"
                        );
                        this.store.dispatch(
                            TranslationAction.create(
                                ChangeType.RELOAD,
                                TranslationAction.DATATABLE,
                                {
                                    entries: []
                                }
                            )
                        );
                    }
                });
        }
    }
    // #endregion

    // #region Class Functions
    async loadTranslation() {
        try {
            const element = await TranslationRequest.loadTranslation(
                this.state.uuid,
                this.state.options
            );
            this.store.dispatch(
                TranslationAction.create(ChangeType.UPDATE, TranslationAction.DIALOG_TRANSLATION, {
                    dialog: {
                        element: element
                    }
                })
            );
        } catch (err) {
            ErrorUtil.handleError("Failed to load translation:", err);
        }
    }

    async loadLanguages() {
        try {
            const languages = (await TranslationRequest.loadLanguages()).map((value) => value.code);
            this.store.dispatch(
                TranslationAction.create(ChangeType.UPDATE, TranslationAction.DIALOG_LANGUAGES, {
                    dialog: {
                        languages: languages
                    }
                })
            );
        } catch (err) {
            ErrorUtil.handleError("Failed to load languages:", err);
        }
    }
    // #endregion

    // #region Tear-Down Lifecycle
    onServiceEnd() {
        this.store.dispatch(
            TranslationAction.create(ChangeType.UPDATE, TranslationAction.DIALOG_CLEAR, {
                dialog: TranslationDefinition.defaultDialogState
            })
        );
    }
    // #endregion
}

export default TranslationDialogService;
