import {Store} from "../Store";
import ChangeType from "../changeType/ChangeType";

class PrivateReturnPoint {
    static SET = "RETURN_POINT_SET";
    static CLEAR = "RETURN_POINT_CLEAR";

    // #region Action
    static action = (changeType, actionType, url) => {
        return {
            changeType: changeType,
            type: actionType,
            url: url
        };
    };
    // #endregion
}

class ReturnPoint {
    static set(url) {
        Store.dispatch(PrivateReturnPoint.action(ChangeType.UPDATE, PrivateReturnPoint.SET, url));
    }

    static get() {
        return Store.getState().returnPoint;
    }

    static clear() {
        Store.dispatch(
            PrivateReturnPoint.action(ChangeType.UPDATE, PrivateReturnPoint.CLEAR, null)
        );
    }

    static Reducer = {
        returnPoint: (originalState = null, action) => {
            if ([PrivateReturnPoint.SET, PrivateReturnPoint.CLEAR].includes(action.type)) {
                originalState = action.url || null;
            }
            return originalState;
        }
    };
}

export default ReturnPoint;
