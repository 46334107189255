import moment from "moment";
import config from "../config";

let hiddenLog = "";

function writeToHiddenLog(type, logArgs) {
    hiddenLog =
        hiddenLog +
        (hiddenLog ? "\n" : "") +
        (type === "ERROR"
            ? "!!!!====== ERROR ======!!!!\n"
            : "========== " + type + " ==========\n") +
        moment().toISOString() +
        ":\n" +
        Object.keys(logArgs).reduce((str, key) => {
            const arg = logArgs[key];
            return str + (typeof arg === "string" ? arg : JSON.stringify(arg, null, "\t")) + "\n";
        }, "") +
        "====================\n";
    // fs.appendFile(
    //     "consoleLog-" + moment().format("YYYYMMDD") + ".txt",
    //     moment().toISOString +
    //         "-" +
    //         type +
    //         ":\n" +
    //         Object.keys(logArgs).reduce((str, key) => {
    //             const arg = logArgs[key];
    //             return str + (typeof arg === "string" ? arg : JSON.stringify(arg)) + "\n";
    //         }, ""),
    //     function(err) {
    //         if (err) throw err;
    //     }
    // );
}

(function() {
    // console log will not be written to hidden log...
    // REMOVE ALL CONSOLE.LOG BEFORE PRODUCTION BUILD
    //
    // const oldLog = console.log;
    // console.log = function() {
    //     writeToHiddenLog("LOG", arguments);
    //     if (config.LOG_LEVEL < 1) {
    //         oldLog.apply(console, arguments);
    //     }
    // };
    const oldDebug = console.debug;
    console.debug = function() {
        writeToHiddenLog("DEBUG", arguments);
        if (config.LOG_LEVEL < 1) {
            oldDebug.apply(console, arguments);
        }
    };
    const oldInfo = console.info;
    console.info = function() {
        writeToHiddenLog("INFO", arguments);
        if (config.LOG_LEVEL < 2) {
            oldInfo.apply(console, arguments);
        }
    };
    const oldWarn = console.warn;
    console.warn = function() {
        writeToHiddenLog("WARN", arguments);
        if (config.LOG_LEVEL < 3) {
            oldWarn.apply(console, arguments);
        }
    };
    const oldError = console.error;
    console.error = function() {
        writeToHiddenLog("ERROR", arguments);
        oldError.apply(console, arguments);
    };
})();

export default class ConsoleUtil {
    static getLog() {
        return "" + hiddenLog;
    }
}
