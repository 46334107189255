import UserAction from "./UserAction";
import UserDefinition from "./UserDefinition";
import DefaultStoreUtil from "../../util/DefaultStoreUtil";

const User = {
    user: DefaultStoreUtil.defaultReducerFunction(UserAction.CATEGORY, UserDefinition.defaultState)
};

export default User;
// #endregion
