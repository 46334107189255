import "./util/ConsoleUtil";
import React from "react";
import ReactDOM from "react-dom";
import "babel-polyfill";
import App from "./App";
import ScrollToTop from "./ScrollToTop";
import {HashRouter} from "react-router-dom";
import "./util/TranslationUtil";
import * as serviceWorker from "./serviceWorker";

//import registerServiceWorker from './registerServiceWorker';

ReactDOM.render(
    <HashRouter>
        <ScrollToTop>
            <App />
        </ScrollToTop>
    </HashRouter>,
    document.getElementById("root")
);

//registerServiceWorker();

serviceWorker.register({
    onUpdate: () => {
        window.__pageUpdated = true;
        window.dispatchEvent(new Event("pageUpdated"));
    }
});
