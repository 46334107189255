import MessagesUtil from "./MessagesUtil";

class ClipboardUtil {
    static copyString(string, displayMessage = true) {
        const el = document.createElement("textArea");
        el.value = string;
        el.style.display = "hidden";
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        if (displayMessage) {
            MessagesUtil.send(
                MessagesUtil.TYPES.GLOBAL,
                MessagesUtil.LEVELS.INFO,
                "msg_actionSuccess",
                "msg_copied"
            );
        }
        document.body.removeChild(el);
    }
}

export default ClipboardUtil;
