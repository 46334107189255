//@ts-check
import SettingsAction from "./SettingsAction";
import SettingsDefinition from "./SettingsDefinition";
import DefaultStoreUtil from "../../util/DefaultStoreUtil";

const Settings = {
    settings: DefaultStoreUtil.defaultReducerFunction(
        SettingsAction.CATEGORY,
        SettingsDefinition.defaultState
    )
};

export default Settings;
