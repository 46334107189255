import TasksDefinition from "./TasksDefinition";
import TasksAction from "./TasksAction";
import DefaultStoreUtil from "../../util/DefaultStoreUtil";

const Tasks = {
    tasks: DefaultStoreUtil.defaultReducerFunction(
        TasksAction.CATEGORY,
        TasksDefinition.defaultState
    )
};

export default Tasks;
