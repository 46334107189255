import ObjectUtil from "./ObjectUtil";

class DefaultStoreUtil {
    static defaultCreateAction = (category) => {
        return (changeType, actionType, ...data) => {
            return {
                category: category,
                changeType: changeType,
                type: actionType,
                data: data ? data : []
            };
        };
    };

    static defaultReducerFunction = (category, defaultState) => {
        return (originalState = defaultState, action) => {
            if (action.category === category) {
                const newState = ObjectUtil.deepDataCopy(originalState);
                if (Array.isArray(action.data)) {
                    action.data.forEach((data) => {
                        ObjectUtil.deepCopyChildren(data, newState);
                    });
                } else {
                    ObjectUtil.deepCopyChildren(action.data, newState);
                }
                return newState;
            }
            return originalState;
        };
    };
}

export default DefaultStoreUtil;
