//@ts-check
import DefaultStoreUtil from "../../util/DefaultStoreUtil";

class OrderAction {
    static CATEGORY = "ORDER";

    // #region overview types
    static LOADING = "ORDER_LOADING";
    static STATES = "ORDER_STATES";
    static TYPES = "ORDER_TYPES";
    static DATATABLE = "ORDER_DATATABLE";
    static SELECTION = "ORDER_SELECTION";
    static CLEAR = "ORDER_CLEAR";
    static DELETE = "ORDER_DELETE";
    // #endregion

    // #region detail types
    static DETAIL_ORDER = "ORDER_DETAIL_ORDER";
    static DETAIL_OPEN_ELEMENTS = "ORDER_DETAIL_OPEN_ELEMENTS";
    static DETAIL_LOADING = "ORDER_DETAIL_LOADING";
    static DETAIL_CLEAR = "ORDER_DETAIL_CLEAR";
    // #endregion

    // #region Action
    static create = DefaultStoreUtil.defaultCreateAction(OrderAction.CATEGORY);
    // #endregion
}

export default OrderAction;
