class PrivateObjectUtil {
    static findDiffFields(obj1, obj2, curField = "") {
        let fields = [];
        for (const key in obj1) {
            if (obj1[key] === obj2[key] || typeof obj1[key] === "function") {
                continue;
            }
            if (
                obj1[key] !== null &&
                typeof obj1[key] === "object" &&
                obj2[key] !== null &&
                typeof obj2[key] === "object"
            ) {
                fields = fields.concat(
                    PrivateObjectUtil.findDiffFields(
                        obj1[key],
                        obj2[key],
                        curField ? curField + "." + key : key
                    )
                );
                fields = fields.concat(
                    PrivateObjectUtil.findDiffFields(
                        obj2[key],
                        obj1[key],
                        curField ? curField + "." + key : key
                    )
                );
            } else if (
                (obj1[key] === null && obj2[key] !== null) ||
                (obj1[key] !== null && obj2[key] === null)
            ) {
                fields.push(
                    (curField ? curField + "." + key : key) +
                        ": " +
                        JSON.stringify(obj1[key]) +
                        JSON.stringify(obj2[key]) +
                        "[NULL]"
                );
            } else if (obj1[key] !== obj2[key]) {
                fields.push(
                    (curField ? curField + "." + key : key) +
                        ": " +
                        JSON.stringify(obj1[key]) +
                        JSON.stringify(obj2[key]) +
                        "[DIFF]"
                );
            }
            // fields.push((curField ? curField + "." + key : key) + ": not different");
        }
        return fields;
    }
}

class ObjectUtil {
    static deepDataCopy(data) {
        if (typeof data !== "undefined") {
            return JSON.parse(JSON.stringify(data));
        }
        return null;
    }

    static calcHash(value) {
        let hash = 0;
        const valueString = JSON.stringify(value);
        if (!valueString || valueString.length === 0) {
            return hash;
        }
        for (let i = 0; i < valueString.length; i++) {
            const char = valueString.charCodeAt(i);
            hash = (hash << 5) - hash + char;
            hash = hash & hash; // Convert to 32bit integer
        }
        return hash;
    }

    static isObjEmpty(object) {
        if (!object) {
            return true;
        }
        for (const key in object) {
            if (object.hasOwnProperty(key)) {
                return false;
            }
        }
        return true;
    }

    static shallowCopyChildren(from, to) {
        for (const key in from) {
            to[key] = ObjectUtil.deepDataCopy(from[key]);
        }
    }

    static deepCopyChildren(from, to) {
        const LOG = false;
        if (LOG) {
            console.group("DeepCopyChildren: " + JSON.stringify(from));
            console.info("DeepCopyChildren: ", from, "->", to);
        }
        for (const key in from) {
            if (from[key] !== null && typeof from[key] === "object" && !Array.isArray(from[key])) {
                if (!to[key]) {
                    to[key] = {};
                }
                if (LOG) {
                    console.info("CopyChild: ", from[key], "->", to[key]);
                }
                ObjectUtil.deepCopyChildren(from[key], to[key]);
            } else if (typeof from[key] === "function") {
                if (LOG) {
                    console.info("CopyFunction: ", from[key], "->", to[key]);
                }
                to[key] = from[key].bind(from[key].this);
            } else {
                if (LOG) {
                    console.info("CopyData: ", from[key], "->", to[key]);
                }
                to[key] = ObjectUtil.deepDataCopy(from[key]);
            }
        }
        if (LOG) {
            console.groupEnd();
        }
    }

    static getDiffFields(obj1, obj2) {
        const arr1 = PrivateObjectUtil.findDiffFields(obj1, obj2);
        const arr2 = PrivateObjectUtil.findDiffFields(obj2, obj1);
        return arr1.concat(arr2);
    }
}

export default ObjectUtil;
