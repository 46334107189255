import DefaultStoreUtil from "../../util/DefaultStoreUtil";

class AddressAction {
    static CATEGORY = "ADDRESS";

    // #region overview types
    static LOADING = "ADDRESS_LOADING";
    static DATATABLE = "ADDRESS_DATATABLE";
    static SELECTION = "ADDRESS_SELECTION";
    static ADDRESSSTATES = "ADDRESS_ADDRESSSTATES";
    static COUNTRIES = "ADDRESS_COUNTRIES";
    static CLEAR = "ADDRESS_CLEAR";
    static DELETE = "ADDRESS_DELETE";
    // #endregion

    // #region detail types
    static DETAIL_ADDRESS = "ADDRESS_DETAIL_ADDRESS";
    static DETAIL_CLEAR = "ADDRESS_DETAIL_CLEAR_STATE";
    static DETAIL_OPEN_ELEMENTS = "ADDRESS_DETAIL_OPEN_ELEMENTS";
    static DETAIL_LOADING = "ADDRESS_DETAIL_LOADING";

    // #endregion

    // #region Actions
    static create = DefaultStoreUtil.defaultCreateAction(AddressAction.CATEGORY);
    // #endregion
}

export default AddressAction;
