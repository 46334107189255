class PartnerTicketDefinition {
    // #region Ticket Detail
    static defaultDetailState = {
        id: "",
        element: {
            id: "",
            state: "New",
            condition: {
                done: false,
                doneWithoutRepair: false
            },
            licensePlate: "",
            vin: "",
            type: "",
            brand: "",
            partnerUUID: "",
            partnerContactUUID: "",
            dispoUserUUID: "",
            userUUID: "",
            solvency: {
                amount: 0,
                date: "",
                critical: ""
            },
            breakdownInfo: {
                extent: 0,
                location: {
                    country: "",
                    place: "",
                    zip: "",
                    street: ""
                },
                drivingDirection: "",
                loadStatus: "",
                contactPhone: "",
                repairDate: "",
                internalNote: ""
            },
            createdAt: null,
            updatedAt: null,
            user: {
                uuid: "",
                email: "",
                active: 0,
                role: "",
                type: "",
                passwordExpired: 0,
                creDat: "",
                modDat: "",
                seenDat: "",
                contact: {
                    uuid: "",
                    addressUUID: "",
                    userUUID: "",
                    title: "",
                    firstname: "",
                    lastname: "",
                    location: "",
                    phone: "",
                    mobile: "",
                    email: "",
                    active: 0,
                    creDat: "",
                    modDat: ""
                }
            },
            dispoUser: {
                uuid: "",
                addressUUID: "",
                userUUID: "",
                title: "",
                firstname: "",
                lastname: "",
                location: "",
                phone: "",
                mobile: "",
                email: "",
                active: 0,
                creDat: "",
                modDat: ""
            },
            partner: {
                uuid: "",
                referenceID: "",
                customerType: "",
                locationOwner: "",
                name1: "",
                name2: "",
                name3: "",
                shortcut: "",
                phone: "",
                email: "",
                web: "",
                location: {
                    country: "",
                    place: "",
                    zip: "",
                    street: ""
                },
                coordinates: {
                    latitude: "",
                    longitude: ""
                },
                active: 0,
                note: "",
                creDat: "",
                modDat: ""
            },
            partnerContact: {
                uuid: "",
                addressUUID: "",
                userUUID: "",
                title: "",
                firstname: "",
                lastname: "",
                location: "",
                phone: "",
                mobile: "",
                email: "",
                active: 0,
                creDat: "",
                modDat: ""
            },
            damages: [],
            files: []
        },
        changes: null
    };
    // #endregion

    // #region Ticket Overview
    static defaultFilter = {
        licensePlate: {
            name: "licensePlate",
            value: null,
            matchMode: "like"
        },
        type: {
            name: "type",
            matchMode: "in",
            value: null
        },
        damages: {
            name: "damages",
            matchMode: "oneOf",
            value: null
        },
        state: {
            name: "state",
            matchMode: "in",
            value: ["New"]
        },
        breakdownCountry: {
            name: "breakdownCountry",
            matchMode: "in",
            value: null
        },
        dispoUserName: {
            name: "dispoUserName",
            value: null,
            matchMode: "startswith"
        },
        userName: {
            name: "userName",
            value: null,
            matchMode: "startswith"
        },
        id: {
            name: "id",
            value: null,
            matchMode: "like"
        },
        ticketCreatedAt: {
            name: "ticketCreatedAt",
            value: null
        }
    };

    static defaultState = {
        entries: [],
        selectionIDs: [],
        detail: PartnerTicketDefinition.defaultDetailState,
        filter: PartnerTicketDefinition.defaultFilter,
        sort: {sortField: "createdAt", sortOrder: -1},
        rows: 50,
        first: 0,
        totalRecords: 0
    };
    // #endregion
}

export default PartnerTicketDefinition;
