import React, {Component} from "react";
import PropTypes from "prop-types";

import {InputText as PrimeInputText} from "primereact/inputtext";

import ViewUtil from "../../../util/ViewUtil";

const formatValue = (value, thousandSeparator, decimal, emptyValue, uppercase) => {
    if (value && value !== "") {
        if (decimal) value = ViewUtil.formatDecimal(value);
        if (thousandSeparator) value = ViewUtil.formatThousandSeparator(value);
        if (uppercase) value = value.toUpperCase();
    } else {
        value = emptyValue;
    }
    return value;
};

class InputText extends Component {
    // #region Set-Up Lifecycle
    constructor(props) {
        super(props);

        this.state = {
            formattedValue: ""
        };
    }

    componentDidMount() {
        if (this.props.value) {
            const formattedValue = formatValue(
                this.props.value,
                this.props.thousandSeparator,
                this.props.decimal,
                this.props.emptyValue,
                this.props.uppercase
            );
            this.setState({formattedValue: formattedValue});
        }
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.value !== this.props.value &&
            !(this.props.decimal && isNaN(prevProps.value) && isNaN(this.props.value))
        ) {
            const formattedValue = formatValue(
                this.props.value,
                this.props.thousandSeparator,
                this.props.decimal,
                this.props.emptyValue,
                this.props.uppercase
            );
            this.setState({formattedValue: formattedValue});
        }
    }

    handleChange = (e) => {
        let value = e.target.value;
        if (this.props.onInput) {
            this.props.onInput(e, value);
        }
        if (!this.props.maxLength || value.length <= this.props.maxLength) {
            if (this.props.uppercase) value = value.toUpperCase();
            this.setState({formattedValue: value});
        }
    };

    handleBlur = (e) => {
        let value = e.target.value;
        const formattedValue = formatValue(
            value,
            this.props.thousandSeparator,
            this.props.decimal,
            this.props.emptyValue,
            this.props.uppercase
        );
        this.setState({formattedValue: formattedValue});
        if (this.props.decimal) {
            value = parseFloat(value.replace(",", "."));
        }
        if (this.props.onChange) {
            this.props.onChange(e, value);
        }
    };

    handleFocus = () => {
        if (this.props.value && this.props.value !== 0 && this.props.decimal) {
            this.setState({formattedValue: this.props.value.toString().replace(".", ",")});
        } else if (this.state.formattedValue === "" && this.props.emptyValue === "") {
            this.setState({formattedValue: ""});
        } else {
            this.setState({formattedValue: this.state.value});
        }
    };
    render() {
        const {name, edit, style = {}, keyfilter, placeholder} = this.props;
        return (
            <PrimeInputText
                style={style}
                readOnly={!edit}
                name={name}
                value={this.state.formattedValue}
                onChange={this.handleChange}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                keyfilter={keyfilter}
                placeholder={placeholder}
            />
        );
    }
}

InputText.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    edit: PropTypes.bool,
    placeholder: PropTypes.string,
    thousandSeparator: PropTypes.bool,
    decimal: PropTypes.bool,
    emptyValue: PropTypes.string,
    uppercase: PropTypes.bool,
    style: PropTypes.object,
    onChange: PropTypes.func,
    onInput: PropTypes.func,
    keyfilter: PropTypes.any,
    maxLength: PropTypes.number
};

export default InputText;
