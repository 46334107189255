import React from "react";
import PropTypes from "prop-types";

import {Dropdown} from "primereact/dropdown";

import {withNamespaces as translate} from "react-i18next";
import LabeledField from "./LabeledField";
class IdentifierDropdownField extends React.PureComponent {
    static propTypes = {
        t: PropTypes.func,
        className: PropTypes.string,
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        options: PropTypes.arrayOf(PropTypes.object),
        optionLabel: PropTypes.string.isRequired,
        identifier: PropTypes.string.isRequired,
        value: PropTypes.object,
        clear: PropTypes.bool,
        edit: PropTypes.bool,
        error: PropTypes.bool,
        itemTemplate: PropTypes.func,
        onChange: PropTypes.func,
        translate: PropTypes.bool,
        scrollHeight: PropTypes.string,
        appendTo: PropTypes.any,
        hideAddon: PropTypes.bool,
        required: PropTypes.bool
    };

    render() {
        const translate = this.props.translate;
        const identifier = this.props.identifier || "key";
        const optionLabel = (translate ? "translated_" : "") + this.props.optionLabel;
        const options = translate
            ? this.props.options.map((option) => {
                  return {
                      ...option,
                      [optionLabel]: this.props.t(option[this.props.optionLabel])
                  };
              })
            : this.props.options;
        const identifiedValue =
            this.props.value && options
                ? options.find((option) => option[identifier] === this.props.value[identifier])
                : null;
        return (
            <LabeledField
                name={this.props.name}
                label={this.props.label}
                className={this.props.className}
                error={
                    this.props.error ||
                    (this.props.required &&
                        (!this.props.value ||
                            !(this.props.value.uuid || this.props.value.internalID)))
                }
                edit={this.props.edit}
                formattedValue={identifiedValue ? identifiedValue[optionLabel] : ""}
                hideAddon={this.props.hideAddon}
            >
                <Dropdown
                    name={this.props.name}
                    value={identifiedValue}
                    optionLabel={optionLabel}
                    options={options}
                    showClear={this.props.clear}
                    disabled={!this.props.edit}
                    onChange={
                        this.props.onChange ||
                        (() =>
                            console.error(
                                "IdentifierDropdown: There is no onChange method defined!"
                            ))
                    }
                    itemTemplate={this.props.itemTemplate}
                    autoWidth={false}
                    scrollHeight={this.props.scrollHeight}
                    appendTo={this.props.appendTo}
                />
            </LabeledField>
        );
    }
}

export default translate("fleet")(IdentifierDropdownField);
