import i18n from "i18next";
import Backend from "i18next-chained-backend";
import {reactI18nextModule} from "react-i18next";
import LocalStorageBackend from "i18next-localstorage-backend";
import XHR from "i18next-xhr-backend";
import config from "../config";
import {request} from "./RequestUtil";
import {Store} from "../store/Store";
import SessionAction from "../store/session/SessionAction";
import ChangeType from "../store/changeType/ChangeType";

const languages = ["de", "en", "it", "es"];
const initOptions = {
    whitelist: languages,
    lng:
        localStorage.getItem("language") || Store === undefined
            ? "en"
            : Store.getState().session.language,
    fallbackLng: "en",
    ns: ["fleet"],
    defaultNS: "fleet",
    interpolation: {
        escapeValue: false
    },
    postProcess: "keyLogger",

    backend: {
        backends: [LocalStorageBackend, XHR],
        backendOptions: [
            {
                prefix: "i18n_res_",
                expirationTime: 24 * 60 * 60 * 1000,
                versions: {
                    de: config.VERSION,
                    en: config.VERSION
                }
            },
            {
                crossDomain: true,
                loadPath: "{{lng}}",
                parse: (data) => data,
                ajax: async function(lng, _, callback) {
                    Store.dispatch(
                        SessionAction.create(ChangeType.UPDATE, SessionAction.INFORMATION, {
                            languageLoaded: false
                        })
                    );
                    const res = await request("/translator.lkw-walter/label?language=" + lng);
                    if (!res.ok) {
                        throw res;
                    }

                    const json = await res.json();
                    if (json.error) {
                        throw json;
                    }

                    const resources = {};
                    json.forEach((translation) => {
                        if (!translation.values[lng]) {
                            return;
                        }
                        resources[translation.label] = translation.values[lng].value;
                    });
                    callback(resources, {status: "200"});
                }
            }
        ]
    }
};
const initCallback = (err) => {
    if (!err) {
        Store.dispatch(
            SessionAction.create(ChangeType.UPDATE, SessionAction.INFORMATION, {
                languageLoaded: true
            })
        );
    } else {
        console.error(err);
    }
};

// eslint-disable-next-line
let translation = i18n
    .use(Backend)
    .use(reactI18nextModule)
    .use({
        type: "postProcessor",
        name: "keyLogger",
        process(value, keys) {
            if (Store && Store.getState().session.languageLoaded && keys) {
                if (keys.length === 1) {
                    const key = keys[0];
                    if (key.indexOf("_") > 0) {
                        const foundKeySet = new Set(
                            localStorage.getItem("lngFoundKeys")
                                ? JSON.parse(localStorage.getItem("lngFoundKeys"))
                                : []
                        );
                        const missingKeySet = new Set(
                            localStorage.getItem("lngMissingKeys")
                                ? JSON.parse(localStorage.getItem("lngMissingKeys"))
                                : []
                        );
                        if (key !== value) {
                            foundKeySet.add(key);
                        } else {
                            missingKeySet.add(key);
                        }
                        localStorage.setItem("lngFoundKeys", JSON.stringify(foundKeySet));
                        localStorage.setItem("lngMissingKeys", JSON.stringify(missingKeySet));
                    }
                } else {
                    const multipleKeySet = new Set(
                        localStorage.getItem("lngMultipleKeys")
                            ? JSON.parse(localStorage.getItem("lngMultipleKeys"))
                            : []
                    );
                    multipleKeySet.add(keys);
                    localStorage.setItem("multipleKeys", JSON.stringify(multipleKeySet));
                }
            }
            return value;
        }
    })
    .init(initOptions, initCallback);

class PrivateTranslationUtil {
    static t = null;
}

export default class TranslationUtil {
    static reinitialize() {
        // console.log("Reinitializing translation...");
        TranslationUtil.deleteStorageFiles();
        translation = translation.init(initOptions, initCallback);
        translation.language = Store
            ? Store.getState().session.language
            : TranslationUtil.initOptions.fallbackLng;
    }

    static getT() {
        if (!PrivateTranslationUtil.t) {
            PrivateTranslationUtil.t = translation.getFixedT();
        }
        return PrivateTranslationUtil.t;
    }

    static getLanguage() {
        return translation.language;
    }

    static changeLanguage(languageCode) {
        translation.changeLanguage(languageCode);
    }

    static deleteStorageFiles() {
        languages.forEach((lng) => localStorage.removeItem("i18n_res_" + lng + "-fleet"));
    }
}
