import React from "react";
import {withNamespaces as translate} from "react-i18next";
import {PropTypes} from "prop-types";

import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";

import {observeStore} from "../../../util/StoreUtil";
import TranslationDialogService from "../../../service/translation/TranslationDialogService";
import TranslationAction from "../../../store/translation/TranslationAction";
import ObjectUtil from "../../../util/ObjectUtil";
import ChangeType from "../../../store/changeType/ChangeType";

class TranslationDialog extends React.PureComponent {
    static propTypes = {
        t: PropTypes.func,
        type: PropTypes.string,
        uuid: PropTypes.string,
        onHide: PropTypes.func
    };

    // #region Set-Up Lifecycle
    constructor(props) {
        super(props);
        this.store = observeStore(
            TranslationDialogService.selectFunction,
            this.onStoreChange,
            ChangeType.UPDATE
        );
        this.state = {
            uuid: this.props.uuid,
            languages: ["de", "en"],
            languageMap: {},
            element: {},
            hash: 0
        };
    }
    // #endregion

    // #region Store Callback
    onStoreChange = (state) => {
        if (state) {
            if ("languages" in state) {
                this.setState({
                    languages: ObjectUtil.deepDataCopy(state.languages)
                });
            } else {
                console.warn("StoreState does not contain 'languages'");
            }
            if ("element" in state) {
                const element = state.element;
                if (element !== null) {
                    // const languageMap = element.values;

                    const languageMap = {};
                    if (element.values) {
                        element.values.forEach(
                            (value) =>
                                (languageMap[value.language] = {
                                    uuid: value.uuid,
                                    value: value.value
                                })
                        );
                    }
                    this.setState({
                        element: ObjectUtil.deepDataCopy(element),
                        hash: ObjectUtil.calcHash(element),
                        languageMap: languageMap
                    });
                }
            } else {
                console.warn("StoreState does not contain 'element' ");
            }
        }
    };
    // #endregion

    // #region UI Callbacks
    onTranslationChange = (e) => {
        // console.log("Trans change");
        const id = e.target.id;
        const value = e.target.value;
        if (id.startsWith("value_")) {
            const locale = id.slice(-2);
            const languageMap = ObjectUtil.deepDataCopy(this.state.languageMap);
            if (locale in languageMap) {
                languageMap[locale].value = value;
            } else {
                languageMap[locale] = {
                    uuid: null,
                    value: value
                };
            }
            this.setState({
                languageMap: languageMap
            });
        } else {
            const element = ObjectUtil.deepDataCopy(this.state.element);
            element[id] = value;
            this.setState({
                element: element
            });
        }
    };

    onTranslationSave = () => {
        const elementJson = this.convertTranslation();
        this.store.dispatch(
            TranslationAction.create(ChangeType.PERSIST, TranslationAction.DIALOG_TRANSLATION, {
                dialog: {
                    element: elementJson
                }
            })
        );
        this.props.onHide();
    };
    // #endregion

    // #region Class Functions
    convertTranslation = () => {
        const element = this.state.element || {};
        const elementJson = {
            uuid: element.uuid,
            label: element.label,
            namespace: element.namespace || "fleet",
            note: element.note || ""
        };
        const values = Object.keys(this.state.languageMap).map((key) => {
            const element = this.state.languageMap[key];
            return {
                uuid: element.uuid,
                language: key,
                value: element.value
            };
        });
        if (values.length > 0) {
            elementJson.values = values;
        }
        return elementJson;
    };

    isSaveDisabled = () => {
        //Missing state information
        if (!this.state || !this.state.element || ObjectUtil.isObjEmpty(this.state.element)) {
            console.info("Translation validation failed: State is unset");
            return true;
        }
        //Missing label
        const label = this.state.element.label;
        if (!label || label.trim() === "") {
            console.info("Translation validation failed: Label is missing");
            return true;
        }
        const languageMap = this.state.languageMap;
        //Need at least one language value
        if (!languageMap || ObjectUtil.isObjEmpty(languageMap)) {
            console.info("Translation validation failed: Language Value missing");
            return true;
        }
        let hasLngSet = false;
        for (const lng in languageMap) {
            if (languageMap[lng].value && languageMap[lng].value.trim() !== "") {
                hasLngSet = true;
            }
        }
        if (!hasLngSet) {
            return true;
        }

        const newHash = ObjectUtil.calcHash(this.convertTranslation());
        return this.state.hash === newHash ? true : false;
    };
    // #endregion

    // #region Render Function
    render() {
        const {t} = this.props;
        return (
            <Dialog
                header={this.renderHeader(t)}
                showHeader
                footer={this.renderFooter(t)}
                width="500px"
                modal
                responsive
                visible
                onHide={this.props.onHide}
            >
                <div className="p-grid p-fluid">
                    <div
                        className="p-grid form-group"
                        style={{paddingTop: "30px", lineHeight: "2.5em"}}
                    >
                        {this.renderLabelField(t)}
                        {this.renderNoteField(t)}
                        {this.renderLanguageFields(t)}
                    </div>
                </div>
                <TranslationDialogService uuid={this.props.uuid} type={this.props.type} />
            </Dialog>
        );
    }
    // #endregion

    // #region Render Co-Functions
    renderHeader = (t) => {
        return this.props.type === "add"
            ? t("translation_add")
            : this.props.type === "prod"
            ? t("translation_editProductive")
            : t("translation_edit");
    };

    renderFooter = (t) => {
        return (
            <div>
                <Button label={t("gen_cancel")} icon="pi pi-times" onClick={this.props.onHide} />
                <Button
                    label={t("gen_save")}
                    icon="pi pi-check"
                    disabled={this.isSaveDisabled()}
                    onClick={() => {
                        this.onTranslationSave();
                    }}
                />
            </div>
        );
    };

    renderLabelField = (t) => {
        const element = this.state.element;
        return (
            <div className="p-col-12">
                <span className="p-float-label">
                    <InputText
                        id="label"
                        defaultValue={element ? element.label : null}
                        onChange={this.onTranslationChange}
                    />
                    <label htmlFor="label">{t("translation_label")}</label>
                </span>
            </div>
        );
    };

    renderNoteField = (t) => {
        const element = this.state.element;
        return (
            <div className="p-col-12">
                <span className="p-float-label">
                    <InputText
                        id="note"
                        defaultValue={element ? element.note : null}
                        onChange={this.onTranslationChange}
                    />
                    <label htmlFor="note">{t("translation_note")}</label>
                </span>
            </div>
        );
    };

    renderLanguageFields = (t) => {
        const languageMap = this.state.languageMap;
        return this.state.languages.map((lang) => {
            return (
                <div key={"div_" + lang} className="p-col-12 p-md-6">
                    <span key={"span_" + lang} className="p-float-label">
                        <InputText
                            key={"value_" + lang}
                            id={"value_" + lang}
                            defaultValue={
                                languageMap && languageMap[lang] ? languageMap[lang].value : ""
                            }
                            onChange={this.onTranslationChange}
                        />
                        <label key={"label_" + lang} htmlFor={"value_" + lang}>
                            {t("translation_" + lang)}
                        </label>
                    </span>
                </div>
            );
        });
    };
    // #endregion

    // #region Tear-Down Lifecycle

    componentWillUnmount() {
        this.store.unsubscribe();
    }
    // #endregion
}
export default translate("fleet")(TranslationDialog);
