import DashboardDefinition from "./DashboardDefinition";
import DashboardAction from "./DashboardAction";
import DefaultStoreUtil from "../../util/DefaultStoreUtil";

const Dashboard = {
    dashboard: DefaultStoreUtil.defaultReducerFunction(
        DashboardAction.CATEGORY,
        DashboardDefinition.defaultState
    )
};

export default Dashboard;
