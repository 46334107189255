//@ts-check
import React from "react";
import moment from "moment";
import TranslationUtil from "./TranslationUtil";

const DATE_FORMAT = {
    DMY: "DMY",
    MDY: "MDY",
    YMD: "YMD"
};

const localeMap = {
    de: {
        name: "de",
        dateFormat: DATE_FORMAT.DMY,
        datePlaceholder: {
            d: "T",
            m: "M",
            y: "J"
        },
        dateDelimiter: ".",
        shortYear: false,
        fieldLocale: {
            firstDayOfWeek: 1,
            dayNames: [
                "Sonntag",
                "Montag",
                "Dienstag",
                "Mittwoch",
                "Donnerstag",
                "Freitag",
                "Samstag"
            ],
            dayNamesShort: ["Son", "Mon", "Die", "Mit", "Don", "Fre", "Sab"],
            dayNamesMin: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
            monthNames: [
                "Januar",
                "Februar",
                "März",
                "April",
                "Mai",
                "Juni",
                "Juli",
                "August",
                "September",
                "Oktober",
                "November",
                "Dezember"
            ],
            monthNamesShort: [
                "Jan",
                "Feb",
                "Mär",
                "Apr",
                "Mai",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Okt",
                "Nov",
                "Dez"
            ]
        }
    },
    en: {
        name: "en",
        dateFormat: DATE_FORMAT.YMD,
        datePlaceholder: {
            d: "d",
            m: "m",
            y: "y"
        },
        dateDelimiter: "/",
        shortYear: false,
        fieldLocale: {
            firstDayOfWeek: 1,
            dayNames: [
                "Sunday",
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday"
            ],
            dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
            dayNamesMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
            monthNames: [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December"
            ],
            monthNamesShort: [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec"
            ]
        }
    },
    it: {
        name: "it",
        dateFormat: DATE_FORMAT.DMY,
        datePlaceholder: {
            d: "g",
            m: "m",
            y: "a"
        },
        dateDelimiter: "/",
        shortYear: false,
        fieldLocale: {
            firstDayOfWeek: 1,
            dayNames: [
                "domenica",
                "lunedì",
                "martedì",
                "mercoledì",
                "giovedì",
                "venerdì",
                "sabato"
            ],
            dayNamesShort: ["dom", "lun", "mar", "mer", "gio", "ven", "sab"],
            dayNamesMin: ["do", "lu", "ma", "me", "gi", "ve", "sa"],
            monthNames: [
                "gennaio",
                "febbraio",
                "marzo",
                "aprile",
                "maggio",
                "giugno",
                "luglio",
                "agosto",
                "settembre",
                "ottobre",
                "novembre",
                "dicembre"
            ],
            monthNamesShort: [
                "gen",
                "feb",
                "mar",
                "apr",
                "mag",
                "giu",
                "lug",
                "ago",
                "set",
                "ott",
                "nov",
                "dic"
            ]
        }
    },
    es: {
        name: "es",
        dateFormat: DATE_FORMAT.DMY,
        datePlaceholder: {
            d: "j",
            m: "m",
            y: "a"
        },
        dateDelimiter: "/",
        shortYear: false,
        fieldLocale: {
            firstDayOfWeek: 1,
            dayNames: ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"],
            dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
            dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
            monthNames: [
                "enero",
                "febrero",
                "marzo",
                "abril",
                "mayo",
                "junio",
                "julio",
                "agosto",
                "septiembre",
                "octubre",
                "noviembre",
                "diciembre"
            ],
            monthNamesShort: [
                "ene",
                "feb",
                "mar",
                "abr",
                "may",
                "jun",
                "jul",
                "ago",
                "sep",
                "oct",
                "nov",
                "dic"
            ]
        }
    }
};

const padNumber = (number) => {
    if (number !== null && typeof number !== "undefined") {
        return number.toString().padStart(2, "0");
    }
    return "";
};

class DateUtil {
    static getLocale(languageKey) {
        return languageKey && localeMap[languageKey] ? localeMap[languageKey] : localeMap.de;
    }

    static determineLocale() {
        return DateUtil.getLocale(TranslationUtil.getLanguage());
    }

    static getDay(date) {
        return date
            .getDate()
            .toString()
            .padStart(2, "0");
    }

    static getMonth(date) {
        return (date.getMonth() + 1).toString().padStart(2, "0");
    }

    static getYear(date, locale = DateUtil.determineLocale()) {
        return locale.shortYear
            ? date
                  .getFullYear()
                  .toString()
                  .slice(-2)
                  .padStart(2, "0")
            : date
                  .getFullYear()
                  .toString()
                  .padStart(4, "0");
    }

    static formatDbDate(date, withTime, locale = DateUtil.determineLocale()) {
        const dbDate = DateUtil.fromDb(date);
        return withTime
            ? DateUtil.formatDateTime(dbDate, locale)
            : DateUtil.formatDate(dbDate, locale);
    }

    static formatDbDateTimeOptional(date, locale = DateUtil.determineLocale()) {
        return DateUtil.formatDateTimeOptional(DateUtil.fromDb(date), locale);
    }

    /**
     * returns a div with two child-spans, one for the date and one for the time
     * (according to the timeDisplaySettings) intended to be used in datatables.
     * @param {String} date
     * The database date string (UTC) e.g. 0000-00-00 00:00:00
     * @param {Number} timeDisplaySetting
     * The time can either be hidden, optional or always visible.
     * * 0 = hidden
     * * 1 = optional
     * * \>1 = always (default)
     * @param {Object} locale
     * The locale of the date, if non given it will automatically be determined.
     */
    static dbDateToCellJSX(date, timeDisplaySetting = 2, locale = DateUtil.determineLocale()) {
        const dateObj = DateUtil.fromDb(date);
        return (
            <div className="f-date-display">
                <span className="f-date-display-date">{DateUtil.formatDate(dateObj, locale)}</span>
                {timeDisplaySetting > 0 ? (
                    <span
                        className="f-date-display-time"
                        style={{
                            visibility:
                                timeDisplaySetting === 1 && !DateUtil.formatTime(dateObj, true)
                                    ? "hidden"
                                    : "visible"
                        }}
                    >
                        {DateUtil.formatTime(dateObj)}
                    </span>
                ) : (
                    ""
                )}
            </div>
        );
    }

    static formatDate(date, locale = DateUtil.determineLocale()) {
        if (locale && date) {
            return locale.dateFormat
                .split("")
                .map((partial) => {
                    switch (partial) {
                        case "D":
                            return DateUtil.getDay(date);
                        case "M":
                            return DateUtil.getMonth(date);
                        case "Y":
                            return DateUtil.getYear(date, locale);
                        default:
                            return "undefined";
                    }
                })
                .join(locale.dateDelimiter);
        }
        return "";
    }

    static formatTime(date, optionalTime = false, showSeconds = false) {
        if (
            date &&
            (!optionalTime ||
                ((date.getHours() !== 0 || date.getMinutes() !== 0) &&
                    (date.getUTCHours() !== 0 || date.getUTCMinutes() !== 0)))
        ) {
            const timeArray = [padNumber(date.getHours()), padNumber(date.getMinutes())];
            if (showSeconds) {
                timeArray.push(padNumber(date.getUTCSeconds()));
            }
            return timeArray.join(":");
        }
        return "";
    }

    static formatDateTimeSeconds(date, locale = DateUtil.determineLocale()) {
        return date
            ? DateUtil.formatDate(date, locale) + " " + DateUtil.formatTime(date, false, true)
            : "";
    }

    static formatDateTime(date, locale = DateUtil.determineLocale()) {
        return date ? DateUtil.formatDate(date, locale) + " " + DateUtil.formatTime(date) : "";
    }

    static formatDateTimeOptional(date, locale = DateUtil.determineLocale()) {
        const time = DateUtil.formatTime(date, true);
        return DateUtil.formatDate(date, locale) + (time ? " " + time : "");
    }

    static getPlaceholder(locale = DateUtil.determineLocale()) {
        if (locale && locale.datePlaceholder) {
            const placeholder = locale.datePlaceholder;
            const day = placeholder.d.padStart(2, placeholder.d);
            const month = placeholder.m.padStart(2, placeholder.m);
            const year = placeholder.y.padStart(locale.shortYear ? 2 : 4, placeholder.y);
            return locale.dateFormat
                .split("")
                .map((partial) => {
                    switch (partial) {
                        case "D":
                            return day;
                        case "M":
                            return month;
                        default:
                            return year;
                    }
                })
                .join(locale.dateDelimiter);
        }
        return "";
    }

    static getEmptyValue(locale = DateUtil.determineLocale()) {
        if (locale) {
            return locale.dateFormat
                .split("")
                .map((partial) => {
                    switch (partial) {
                        case "D":
                            return "00";
                        case "M":
                            return "00";
                        case "Y":
                            return locale.shortYear ? "00" : "0000";
                        default:
                            return "undefined";
                    }
                })
                .join(locale.dateDelimiter);
        }
        return "";
    }

    /**
     * Returns a Date instance of the given database date string (UTC) or
     * null if date is not a string or the empty dates (0000-00-00 00:00:00 and 0001-01-01T00:00:00Z)
     * @param {String} dbDate the database date string
     */
    static fromDb(dbDate) {
        if (
            dbDate &&
            typeof dbDate === "string" &&
            dbDate !== "0000-00-00 00:00:00" &&
            dbDate !== "0001-01-01T00:00:00Z"
        ) {
            let momentDate = moment.utc(dbDate);
            if (!momentDate.isValid()) {
                if (/^(\d{4})-(\d{2})-(\d{2})( (\d{2}):(\d{2}):(\d{2}))?/.test(dbDate)) {
                    momentDate = moment.utc(dbDate, "YYYY-MM-DD HH:mm:ss");
                }
                if (/^(\d{2})\.(\d{2})\.(\d{4})( (\d{2}):(\d{2}):(\d{2}))?/.test(dbDate)) {
                    momentDate = moment.utc(dbDate, "DD.MM.YYYY HH:mm:ss");
                }
                if (!momentDate.isValid()) {
                    console.error("DateUtil: Could not convert unvalid date: " + dbDate);
                    return null;
                }
            }
            return momentDate.toDate();
        }
        // @ts-ignore
        return dbDate instanceof Date ? dbDate : null;
    }

    /**
     * Formats the given date into the database date string
     * @param {Date} date the date to be formatted
     */
    static toDb(date) {
        if (date) {
            const result = [
                [date.getUTCFullYear(), date.getUTCMonth() + 1, date.getUTCDate()]
                    .map((number, index) => number.toString().padStart(index === 0 ? 4 : 2, "0"))
                    .join("-"),
                [date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()]
                    .map((number) => number.toString().padStart(2, "0"))
                    .join(":")
            ].join(" ");
            // console.log(result);
            return result;
        }
        return null;
    }

    /**
     * Formats the given date into the new database date string (e.g. 0000-00-00T00:00:00Z)
     * @param {Date} date the date to be formatted
     */
    static toDbNew(date) {
        if (date) {
            const result =
                [date.getUTCFullYear(), date.getUTCMonth() + 1, date.getUTCDate()]
                    .map((number, index) => number.toString().padStart(index === 0 ? 4 : 2, "0"))
                    .join("-") +
                "T" +
                [date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()]
                    .map((number) => number.toString().padStart(2, "0"))
                    .join(":") +
                "Z";
            return result;
        }
        return null;
    }

    static toDbNewEndOfDay(date) {
        if (date) {
            const result =
                [date.getUTCFullYear(), date.getUTCMonth() + 1, date.getUTCDate()]
                    .map((number, index) => number.toString().padStart(index === 0 ? 4 : 2, "0"))
                    .join("-") + "T21:59:00Z";
            return result;
        }
        return null;
    }
}

export default DateUtil;
