import DefaultStoreUtil from "../../util/DefaultStoreUtil";

class DashboardAction {
    static CATEGORY = "DASHBOARD";

    // #region overview types
    static LOADING = "DASHBOARD_LOADING";
    static CLEAR = "DASHBOARD_CLEAR";

    static OVERVIEW = "DASHBOARD_OVERVIEW";
    static TICKETSTATS = "DASHBOARD_TICKETSTATS";
    static ORDERSTATS = "DASHBOARD_ORDERSTATS";
    static DEBTORSTATS = "DASHBOARD_DEBTORSTATS";
    static INSPECTIONSTATS = "DASHBOARD_INSPECTIONSTATS";
    static TMSTATS = "DASHBOARD_TMSTATS";
    static WSSTATS = "DASHBOARD_WSSTATS";
    static NEWS = "DASHBOARD_NEWS";

    // #endregion

    // #region Actions
    static create = DefaultStoreUtil.defaultCreateAction(DashboardAction.CATEGORY);
    // #endregion
}

export default DashboardAction;
