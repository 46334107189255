import DefaultStoreUtil from "../../util/DefaultStoreUtil";

class TrailerAction {
    static CATEGORY = "TRAILER";

    // #region Trailer Overview
    static LOADING = "TRAILER_LOADING";
    static DATATABLE = "TRAILER_DATATABLE";
    static SELECTION = "TRAILER_SELECTION";
    static DAMAGETYPES = "TRAILER_DAMAGETYPES";
    static TRAILERTYPES = "TRAILER_TRAILERTYPES";
    static OWNERS = "TRAILER_OWNERS";
    static INSPECTION_COUNTRIES = "TRAILER_INSPECTION_COUNTRIES";
    static CLEAR = "TRAILER_CLEAR";
    static DELETE = "TRAILER_DELETE";
    // #endregion

    // #region Trailer Detail View
    static DETAIL_TRAILER = "TRAILER_DETAIL_TRAILER";
    static DETAIL_OPEN_ELEMENTS = "TRAILER_DETAIL_OPEN_ELEMENTS";
    static DETAIL_LOADING = "TRAILER_DETAIL_LOADING";
    static DETAIL_TIMELINE = "TRAILER_DETAIL_TIMELINE";
    static DETAIL_TRAILERTYPES = "TRAILER_DETAIL_TRAILERTYPES";
    static DETAIL_TRAILEROWNERS = "TRAILER_DETAIL_TRAILEROWNERS";
    static DETAIL_INSPECTIONCOUNTRIES = "TRAILER_DETAIL_INSPECTIONSCOUNTRIES";
    static DETAIL_SET_ACTIVE = "DETAIL_SET_ACTIVE";
    static DETAIL_SET_REPAIR = "DETAIL_SET_REPAIR";
    static DETAIL_RETIRE = "DETAIL_SET_RETIRE";
    static DETAIL_REASSIGN_LICENSEPLATE = "DETAIL_REASSIGN_LICENSEPLATE";
    static DETAIL_CLEAR = "TRAILER_DETAIL_CLEAR_STATE";
    // #endregion

    // #region Actions
    static create = DefaultStoreUtil.defaultCreateAction(TrailerAction.CATEGORY);
}

export default TrailerAction;
