import React, {Component} from "react";
import moment from "moment";
import {hasPermission, Permissions, Features} from "./util/PermissionUtil";
import ErrorUtil from "./util/ErrorUtil";
import SystemStatusRequest from "./requests/SystemStatusRequest";

export class AppFooter extends Component {
    constructor() {
        super();
        this.state = {
            loadingSystemState: false,
            systemState: null
        };
    }

    componentDidMount() {
        setTimeout(() => this.systemState(), 5000);
        this.interval = setInterval(() => this.systemState(), 60000);
    }

    systemState = () => {
        if (!this.state.loadingSystemState) {
            this.setState({loadingSystemState: true});
            SystemStatusRequest.loadStatus()
                .then((result) => {
                    this.setState({
                        systemState: result.payload,
                        loadingSystemState: false
                    });
                })
                .catch((err) => {
                    ErrorUtil.handleError("msg_loadSystemStateFailed", err, false);
                    this.setState({loadingSystemState: false});
                });
        }
    };

    stateColor = () => {
        switch (this.state.systemState) {
            case "error": {
                return "red";
            }
            case "warning": {
                return "orange";
            }
            case "healthy": {
                return "green";
            }
            default: {
                return "lightgray";
            }
        }
    };

    render() {
        return (
            <div
                className="footer"
                style={{
                    marginTop: "-10px",
                    float: "right",
                    paddingLeft: "1em",
                    paddingRight: "1em",
                    maxWidth: "100%"
                }}
            >
                <div
                    className="footer-text-right"
                    style={{
                        maxWidth: "100%",
                        fontSize: "10px",
                        whiteSpace: "nowrap",
                        overflowX: "hidden",
                        textOverflow: "ellipsis"
                    }}
                >
                    LKW-Walter FLEET by IT4S <big>©</big> {moment().year()} -All Rights Reserved
                    {hasPermission(Permissions.view, Features.development)
                        ? " [" + window.innerWidth + "x" + window.innerHeight + "]"
                        : ""}
                </div>

                <div
                    className="footer-text-right"
                    style={{
                        maxWidth: "100%",
                        marginRight: "1em"
                    }}
                    onClick={() => console.log("click")}
                >
                    {this.state.systemState ? (
                        <div
                            className="f-system-state"
                            style={{
                                backgroundColor: this.stateColor() || "none"
                            }}
                        />
                    ) : (
                        []
                    )}
                </div>
            </div>
        );
    }
}
