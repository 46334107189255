import React from "react";
import PropTypes from "prop-types";

import InputText from "./InputText";
import ViewUtil from "../../../util/ViewUtil";
import LabeledField from "./LabeledField";

const InputField = ({
    name,
    className,
    label,
    value,
    edit = true,
    required = false,
    thousandSeparator = false,
    decimal = false,
    emptyValue = "",
    uppercase = false,
    suffix = "",
    onSuffixClick = null,
    left = true,
    small = false,
    error = false,
    onChange,
    onInput,
    keyFilter,
    maxLength,
    placeholder,
    hideAddon
}) => {
    return (
        <LabeledField
            name={name}
            className={className}
            label={label}
            edit={edit}
            suffix={suffix}
            onSuffixClick={onSuffixClick}
            error={error || (required && !value)}
            formattedValue={
                typeof value === "number" && decimal
                    ? //@TODO: use user language
                      value.toLocaleString("de", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                      })
                    : value
            }
            hideAddon={hideAddon}
        >
            <InputText
                className={ViewUtil.classContent(left, small)}
                edit={edit}
                name={name}
                value={value}
                onChange={onChange}
                onInput={onInput}
                keyfilter={keyFilter}
                left={left}
                small={small}
                thousandSeparator={thousandSeparator}
                decimal={decimal}
                emptyValue={emptyValue}
                uppercase={uppercase}
                maxLength={maxLength}
                placeholder={placeholder}
            />
        </LabeledField>
    );
};

InputField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    className: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    edit: PropTypes.bool,
    required: PropTypes.bool,
    thousandSeparator: PropTypes.bool,
    decimal: PropTypes.bool,
    emptyValue: PropTypes.string,
    uppercase: PropTypes.bool,
    suffix: PropTypes.string,
    onSuffixClick: PropTypes.func,
    left: PropTypes.bool,
    small: PropTypes.bool,
    error: PropTypes.bool,
    onChange: PropTypes.func,
    onInput: PropTypes.func,
    keyFilter: PropTypes.any,
    maxLength: PropTypes.number,
    formattedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    placeholder: PropTypes.string,
    hideAddon: PropTypes.bool
};

export default InputField;
