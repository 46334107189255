import UriList from "../service/EndpointsList";
import {prioritizedRequest} from "../util/RequestUtil";
import {reject} from "q";
import ErrorUtil from "../util/ErrorUtil";

class TranslationRequest {
    static async loadTranslation(uuid, options) {
        if (uuid) {
            const response = await prioritizedRequest(
                UriList.TRANSLATION_TRANSLATION + "/" + uuid,
                options
            );
            return response;
        }
        return reject(ErrorUtil.createError(ErrorUtil.LEVEL.ERROR, "Missing uuid"));
    }

    static async loadLanguages() {
        const response = await prioritizedRequest(UriList.TRANSLATION_LANGUAGE + "?all=true");
        return response;
    }

    static async persistTranslation(uuid, translation, options) {
        if (!translation) {
            return reject(
                ErrorUtil.createError(ErrorUtil.LEVEL.ERROR, "Cannot persist an empty translation")
            );
        }
        const urlAddon = uuid ? "/" + uuid : "";
        const response = await prioritizedRequest(UriList.TRANSLATION_TRANSLATION + urlAddon, {
            method: uuid ? "PUT" : "POST",
            body: JSON.stringify(translation),
            formdata: true,
            ...options
        });
        return response;
    }

    static async deleteTranslation(uuid) {
        if (uuid) {
            const response = await prioritizedRequest(
                UriList.TRANSLATION_TRANSLATION + "/" + uuid,
                {
                    method: "DELETE"
                }
            );
            return response;
        }
        return reject(ErrorUtil.createError(ErrorUtil.LEVEL.ERROR, "Missing uuid"));
    }

    static async loadTranslations(params, options) {
        const response = await prioritizedRequest(UriList.TRANSLATION_TRANSLATION, {
            params: params,
            ...options
        });
        return response;
    }
}

export default TranslationRequest;
