import React from "react";
import PropTypes from "prop-types";

import {Dropdown} from "primereact/dropdown";
import LabeledField from "./LabeledField";

class DropdownField extends React.PureComponent {
    static propTypes = {
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        className: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        optionLabel: PropTypes.string,
        options: PropTypes.array,
        clear: PropTypes.bool,
        edit: PropTypes.bool,
        required: PropTypes.bool,
        error: PropTypes.bool,
        itemTemplate: PropTypes.func,
        onChange: PropTypes.func,
        appendTo: PropTypes.any,
        hideAddon: PropTypes.bool,
        suffix: PropTypes.any,
        onSuffixClick: PropTypes.func,
        filter: PropTypes.bool
    };

    render() {
        // eslint-disable-next-line react/prop-types
        if (this.props.translate) {
            console.error(
                "IMPORTANT: Translation is not longer supported for the DropdownField, use IdentifierDropdownField instead!"
            );
        }
        const displayValue =
            this.props.value && typeof this.props.value === "object"
                ? this.props.value[this.props.optionLabel]
                : this.props.value;
        // const tmpValue = {[this.props.optionLabel || "label"]: translatedDisplayValue};
        return (
            <LabeledField
                name={this.props.name}
                label={this.props.label}
                className={this.props.className}
                edit={this.props.edit}
                formattedValue={displayValue}
                // error={
                //     this.props.error ||
                //     // (this.props.required &&
                //     //     (!this.props.value ||
                //     //         !(this.props.value.uuid || this.props.value.internalID)))
                //     ((this.props.required && !this.props.value) ||
                //         !(this.props.value.uuid || this.props.value.internalID))
                // }
                error={this.props.error || (this.props.required && !this.props.value)}
                hideAddon={this.props.hideAddon}
                suffix={this.props.suffix}
                onSuffixClick={this.props.onSuffixClick}
            >
                <Dropdown
                    name={this.props.name}
                    value={this.props.value}
                    options={this.props.options}
                    optionLabel={this.props.optionLabel}
                    showClear={this.props.clear}
                    disabled={!this.props.edit}
                    onChange={this.props.onChange}
                    itemTemplate={this.props.itemTemplate}
                    autoWidth={false}
                    appendTo={this.props.appendTo}
                    filter={this.props.filter}
                />
            </LabeledField>
        );
    }
}

export default DropdownField;
