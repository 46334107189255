import ChangeType from "../changeType/ChangeType";

class MessagesAction {
    static CATEGORY = "MESSAGE";

    static SEND = "MESSAGE_SEND";
    static CLEAR = "MESSAGE_CLEAR";

    static send = (msgType, level, title, text, code) => {
        return {
            category: MessagesAction.CATEGORY,
            changeType: ChangeType.UPDATE,
            type: MessagesAction.SEND,
            data: {
                messageType: msgType,
                messageData: {
                    level: level,
                    title: title,
                    text: text,
                    code: code
                }
            }
        };
    };

    static clear = (msgType) => {
        return {
            category: MessagesAction.CATEGORY,
            changeType: ChangeType.UPDATE,
            type: MessagesAction.CLEAR,
            data: {messageType: msgType}
        };
    };

    static clearAll = () => {
        return {
            category: MessagesAction.CATEGORY,
            changeType: ChangeType.UPDATE,
            type: MessagesAction.CLEAR
        };
    };
}

export default MessagesAction;
