//@ts-check
class OrderDefinition {
    static defaultTireDismount = {
        dismountedDate: null,
        dismountedReason: "",
        dismountedTrailerMileage: 0,
        reasonIID: null,
        position: 0,
        side: ""
    };

    static defaultTireMount = {
        mountedDate: null,
        mountedTrailerMileage: 0,
        mountedTotalCosts: 0,
        position: 0,
        side: "",
        brandIID: null,
        name: "",
        serialNumber: "",
        isTypeWinter: true
    };

    static defaultTire = (side, position) => {
        return {
            key: side + position,
            label: "trailer_tire" + position + (side === "R" ? "ri" : "le"),
            selected: false,
            side: side,
            position: position,
            tireDismount: OrderDefinition.defaultTireDismount,
            tireMount: OrderDefinition.defaultTireMount
        };
    };

    static defaultRepairTires = {
        L1: OrderDefinition.defaultTire("L", 1),
        L2: OrderDefinition.defaultTire("L", 2),
        L3: OrderDefinition.defaultTire("L", 3),
        R1: OrderDefinition.defaultTire("R", 1),
        R2: OrderDefinition.defaultTire("R", 2),
        R3: OrderDefinition.defaultTire("R", 3)
    };

    static defaultDetailState = {
        uuid: "",
        token: "",
        element: {
            uuid: "",
            version: 0,
            userUUID: "",
            userFirstname: "",
            userLastname: "",
            userEmail: "",
            invoiceNumber: "",
            invoiceDate: null,
            invoiceAmount: 0,
            realCosts: 0,
            trailer: {
                vin: "",
                licensePlate: "",
                vehicleType: "",
                brand: ""
            },
            orderCondition: 0,
            orderState: {
                uuid: "",
                label: "",
                note: ""
            },
            ticket: {
                ticketDate: "",
                ticketCondition: 2,
                truckerKey: "",
                truckerName: "",
                driverName: "",
                dispoUserUUID: "",
                dispoUserFirstname: "",
                dispoUserLastname: "",
                ticketAddress: {
                    place: "",
                    street: "",
                    streetNumber: "",
                    zip: "",
                    country: "",
                    region: ""
                },
                latitude: 0,
                longitude: 0,
                addressTXT: null
            },
            telemetry: {
                internalID: 0,
                positionTime: null,
                mileage: 0
            },
            damage: [
                {
                    damageGroupUUID: "5bb12843-03e9-428f-98ec-4ec4f4e7103f",
                    damageGroupLabel: "damage_group00",
                    damageType: [
                        {
                            damageTypeUUID: "b1015807-f204-4557-a134-282ccbb2a8f7",
                            typeLabel: "damage_type01",
                            selected: false
                        },
                        {
                            damageTypeUUID: "0ff2be6c-a299-4c9a-815a-3e8bd6e1d7fb",
                            typeLabel: "damage_type02",
                            selected: false
                        },
                        {
                            damageTypeUUID: "8f9bc930-288f-41ab-a54e-c40809afe2e1",
                            typeLabel: "damage_type03",
                            selected: false
                        },
                        {
                            damageTypeUUID: "51339784-f845-471a-bad8-6a06e8063910",
                            typeLabel: "damage_type04",
                            selected: false
                        },
                        {
                            damageTypeUUID: "b0d46ade-8ae8-4178-9b16-b9326a9f08d4",
                            typeLabel: "damage_type05",
                            selected: false
                        },
                        {
                            damageTypeUUID: "73b4faff-6b7a-44fc-b40f-5303ac12fe6f",
                            typeLabel: "damage_type06",
                            selected: false
                        }
                    ]
                },
                {
                    damageGroupUUID: "e8827f5e-29cc-4825-a1e9-487d7b43f0cc",
                    damageGroupLabel: "damage_group10",
                    damageType: [
                        {
                            damageTypeUUID: "21acfcd7-7489-4e34-8b18-bcbc2e2bf819",
                            typeLabel: "damage_group10",
                            selected: false
                        }
                    ]
                },
                {
                    damageGroupUUID: "173c41d9-6377-4faf-805a-401bc21427b2",
                    damageGroupLabel: "damage_group20",
                    damageType: [
                        {
                            damageTypeUUID: "f07b233b-dbec-4592-8d16-c9e77f9d8455",
                            typeLabel: "damage_type20",
                            selected: false
                        }
                    ]
                },
                {
                    damageGroupUUID: "c5a456ce-e6df-42f3-8b41-5a84c74ca5a1",
                    damageGroupLabel: "damage_group30",
                    damageType: [
                        {
                            damageTypeUUID: "b3b27cc8-6fcc-4dcc-ba51-67d527051ed1",
                            typeLabel: "damage_type30",
                            selected: false
                        }
                    ]
                },
                {
                    damageGroupUUID: "d4c0a1cb-1439-45c6-bbfa-29675cf3de28",
                    damageGroupLabel: "damage_group40",
                    damageType: [
                        {
                            damageTypeUUID: "44e7b1a7-6065-4fc6-bcc0-5f84560a1450",
                            typeLabel: "damage_group40",
                            selected: false
                        }
                    ]
                },
                {
                    damageGroupUUID: "cfa97549-c924-4dde-a02b-7a2001c57ff8",
                    damageGroupLabel: "damage_group50",
                    damageType: [
                        {
                            damageTypeUUID: "c92f12e5-dbc0-40b7-8867-6b203122a241",
                            typeLabel: "damage_group50",
                            selected: false
                        }
                    ]
                },
                {
                    damageGroupUUID: "b9c14061-f1bd-4054-810d-2f11e87810f3",
                    damageGroupLabel: "damage_group60",
                    damageType: [
                        {
                            damageTypeUUID: "fbd95f51-4340-46c7-9966-e8ad93066a4c",
                            typeLabel: "damage_group60",
                            selected: false
                        }
                    ]
                },
                {
                    damageGroupUUID: "b737e993-5d3d-4966-855d-0f2cac986da3",
                    damageGroupLabel: "damage_group70",
                    damageType: [
                        {
                            damageTypeUUID: "74663b51-088d-45d7-9302-160b317991d1",
                            typeLabel: "damage_type71",
                            selected: false
                        },
                        {
                            damageTypeUUID: "d8d674fb-6716-4008-848c-84b69162d2bc",
                            typeLabel: "damage_type72",
                            selected: false
                        },
                        {
                            damageTypeUUID: "f4fa9686-2365-4ecd-972e-67c17fccdccb",
                            typeLabel: "damage_type73",
                            selected: false
                        }
                    ]
                },
                {
                    damageGroupUUID: "4308d753-76a4-403b-b302-335ede2c9bd1",
                    damageGroupLabel: "damage_group80",
                    damageType: [
                        {
                            damageTypeUUID: "ec4dedf0-f5d7-44be-8dd4-e23483ec7dc6",
                            typeLabel: "damage_group80",
                            selected: false
                        }
                    ]
                },
                {
                    damageGroupUUID: "2d88185d-d735-4c54-a298-049da5be5351",
                    damageGroupLabel: "damage_group90",
                    damageType: [
                        {
                            damageTypeUUID: "d8226dc2-fd6a-4335-ab0e-682f7dc7240e",
                            typeLabel: "damage_type90",
                            selected: false
                        }
                    ]
                }
            ],
            tireChange: {
                tiresDismount: [],
                tiresMount: []
            },
            inspection: null,
            orderFile: [],
            repair: {
                servicepartnerUUID: null,
                servicepartnerName1: "",
                servicepartnerReferenceID: 0,
                servicepartnerAgreement: 0,
                contactUUID: null,
                contact: "",
                scheduledDate: "",
                repairDate: "",
                reminder: "",
                billingReference: "",
                note: ""
            },
            currency: {
                uuid: "",
                exchangeRate: 0
            },
            spRequired: false,
            hasTire: false
        }
    };

    static defaultFilter = {};

    static defaultState = {
        uuid: null,
        entries: null,
        selectionUUIDs: [],
        sort: {sortField: "orderID", sortOrder: -1},
        filter: OrderDefinition.defaultFilter,
        detail: OrderDefinition.defaultDetailState
    };
}
export default OrderDefinition;
