import React, {Component} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import RedirectUtil from "./util/RedirectUtil";

class AppSubmenu extends Component {
    static defaultProps = {
        className: null,
        items: null,
        onMenuItemClick: null,
        onRootItemClick: null,
        root: false,
        layoutMode: null,
        menuActive: false,
        path: ""
    };

    static propTypes = {
        className: PropTypes.string,
        items: PropTypes.array,
        onMenuItemClick: PropTypes.func,
        onRootItemClick: PropTypes.func,
        root: PropTypes.bool,
        layoutMode: PropTypes.string,
        menuActive: PropTypes.bool,
        path: PropTypes.string
    };

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.updateActiveIndexToPath();
    }

    componentDidUpdate(prevProps) {
        if (this.isHorizontalOrSlim() && prevProps.menuActive && !this.props.menuActive) {
            this.setState({activeIndex: []});
        } else if (prevProps.path !== this.props.path) {
            this.updateActiveIndexToPath();
        }
    }

    updateActiveIndexToPath = () => {
        //Recursive path matching function
        const matchesPath = (entry) => {
            if (this.props.path && this.props.path.startsWith(entry.url)) {
                return true;
            }
            if (entry.items && entry.items.length) {
                return entry.items.find((x) => matchesPath(x));
            }
            return false;
        };
        if (this.props.items) {
            const activeIndex = [];
            this.props.items.forEach((entry, index) => {
                if (matchesPath(entry)) {
                    activeIndex.push(index);
                }
            });
            this.setState({activeIndex: activeIndex.length ? activeIndex : []});
        }
    };

    onMenuItemClick(event, item, index) {
        //avoid processing disabled items
        if (item.disabled) {
            event.preventDefault();
            return false;
        }

        //open new window/tab for middle mouse clicks in firefox
        if (event.nativeEvent && event.nativeEvent.button === 1 && item.url) {
            RedirectUtil.openInNewWindow(item.url);
            event.preventDefault();
            return true;
        }

        if (this.props.root && this.props.onRootItemClick) {
            this.props.onRootItemClick({
                originalEvent: event,
                item: item
            });
        }

        //execute command
        if (item.command) {
            item.command({originalEvent: event, item: item});
        }

        //prevent hash change
        if (item.items || !item.url) {
            event.preventDefault();
        }

        if (index >= 0 && this.state.activeIndex.includes(index))
            this.setState({activeIndex: this.state.activeIndex.filter((i) => i !== index)});
        else this.setState({activeIndex: this.state.activeIndex.concat([index])});

        if (this.props.onMenuItemClick) {
            this.props.onMenuItemClick({
                originalEvent: event,
                item: item
            });
        }

        return false;
    }

    onMenuItemMouseEnter(index) {
        if (this.props.root && this.props.menuActive && this.isHorizontalOrSlim()) {
            this.setState({activeIndex: this.state.activeIndex.concat([index])});
        }
    }

    isHorizontalOrSlim() {
        return this.props.layoutMode === "horizontal" || this.props.layoutMode === "slim";
    }

    render() {
        const items =
            this.props.items &&
            this.props.items.map((item, i) => {
                if (item.visible === false) {
                    return "";
                }
                const active = this.state.activeIndex && this.state.activeIndex.includes(i);
                const styleClass = classNames(item.badgeStyleClass, {
                    "active-menuitem": active
                });
                const badge = item.badge && (
                    <span
                        className="menuitem-badge"
                        style={{textAlign: "center", backgroundColor: "#00529e"}}
                    >
                        {item.badge}
                    </span>
                );
                const submenuIcon = item.items && (
                    <i className="material-icons submenu-icon">keyboard_arrow_down</i>
                );
                const tooltip = this.props.root && (
                    <div className="layout-menu-tooltip">
                        <div className="layout-menu-tooltip-arrow" />
                        <div className="layout-menu-tooltip-text">{item.label}</div>
                    </div>
                );

                return (
                    <li className={styleClass} key={i} title={item.label}>
                        <a
                            className={"ripplelink" + (item.disabled ? " f-menu-disabled" : "")}
                            href={item.url || "#"}
                            target={item.target}
                            onClick={(e) => this.onMenuItemClick(e, item, i)}
                            onMouseEnter={() => this.onMenuItemMouseEnter(i)}
                            disabled={item.disabled}
                        >
                            <i className="material-icons">{item.icon}</i>
                            <span>{item.label}</span>
                            {badge}
                            {submenuIcon}
                        </a>
                        {tooltip}
                        <AppSubmenu
                            items={item.items}
                            onMenuItemClick={this.props.onMenuItemClick}
                            layoutMode={this.props.layoutMode}
                            menuActive={this.props.menuActive}
                            path={this.props.path}
                        />
                    </li>
                );
            });

        return <ul className={this.props.className}>{items}</ul>;
    }
}

export class AppMenu extends Component {
    static defaultProps = {
        model: null,
        onMenuItemClick: null,
        onRootMenuItemClick: null,
        layoutMode: null,
        active: false,
        path: ""
    };

    static propTypes = {
        model: PropTypes.array,
        layoutMode: PropTypes.string,
        onMenuItemClick: PropTypes.func,
        onRootMenuItemClick: PropTypes.func,
        active: PropTypes.bool,
        path: PropTypes.string
    };

    render() {
        return (
            <AppSubmenu
                items={this.props.model}
                className="ultima-menu ultima-main-menu clearfix"
                menuActive={this.props.active}
                onRootItemClick={this.props.onRootMenuItemClick}
                onMenuItemClick={this.props.onMenuItemClick}
                root={true}
                layoutMode={this.props.layoutMode}
                path={this.props.path}
            />
        );
    }
}
