import React from "react";
import PropTypes from "prop-types";

class LabeledField extends React.PureComponent {
    static propTypes = {
        t: PropTypes.func,
        name: PropTypes.string.isRequired,
        label: PropTypes.string,
        hideLabel: PropTypes.bool,
        children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
        className: PropTypes.string,
        edit: PropTypes.bool,
        suffix: PropTypes.string,
        onSuffixClick: PropTypes.func,
        suffix2: PropTypes.string,
        onSuffixClick2: PropTypes.func,
        error: PropTypes.bool,
        formattedValue: PropTypes.any,
        multiline: PropTypes.bool,
        onClear: PropTypes.func,
        hideAddon: PropTypes.bool
    };

    render() {
        const labelClassNames = this.props.edit
            ? this.props.error
                ? " f-input-label-error"
                : " f-input-label-edit"
            : "";
        return (
            <div
                className={
                    (this.props.multiline ? "f-labeled-field-multiline" : "f-labeled-field") +
                    (this.props.className ? " " + this.props.className : "")
                }
            >
                <div key={this.props.name} className="f-input-group">
                    {!this.props.hideLabel ? (
                        <div
                            className={"f-input-label" + labelClassNames}
                            style={{display: this.props.label ? "inline-block" : "none"}}
                            title={this.props.label}
                        >
                            {this.props.label}:
                        </div>
                    ) : (
                        ""
                    )}
                    <div
                        className={
                            "f-input-content" +
                            (!this.props.edit ? " f-input-content-readonly" : "")
                        }
                        title={
                            !this.props.edit && !this.props.multiline
                                ? this.getFormattedValueText()
                                : null
                        }
                    >
                        {this.props.edit ? this.props.children : this.props.formattedValue}
                    </div>
                    {!this.props.multiline && !this.props.hideAddon ? (
                        <div className="f-input-addon">
                            {this.props.suffix ? (
                                <div
                                    className={
                                        "f-input-suffix" +
                                        (typeof this.props.onSuffixClick === "function"
                                            ? " f-input-suffix-clickable"
                                            : "")
                                    }
                                    onClick={this.props.onSuffixClick}
                                >
                                    {this.props.suffix.includes(":")
                                        ? this.buildIcon(this.props.suffix)
                                        : this.props.suffix}
                                </div>
                            ) : (
                                ""
                            )}
                            {this.props.suffix2 ? (
                                <div
                                    className={
                                        "f-input-suffix" +
                                        (typeof this.props.onSuffixClick2 === "function"
                                            ? " f-input-suffix-clickable"
                                            : "")
                                    }
                                    onClick={this.props.onSuffixClick2}
                                >
                                    {this.props.suffix2.includes(":")
                                        ? this.buildIcon(this.props.suffix2)
                                        : this.props.suffix2}
                                </div>
                            ) : (
                                ""
                            )}
                            {Boolean(
                                typeof this.props.onClear === "function" &&
                                    this.props.edit &&
                                    this.props.formattedValue
                            ) && (
                                <div
                                    className="f-input-clear pi-md-trash"
                                    style={{fontSize: "1.2em"}}
                                    onClick={this.props.onClear}
                                />
                            )}
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            </div>
        );
    }

    getFormattedValueText = () => {
        return this.props.formattedValue !== null &&
            typeof this.props.formattedValue !== "undefined" &&
            typeof this.props.formattedValue !== "object"
            ? typeof this.props.formattedValue === "string"
                ? this.props.formattedValue
                : JSON.stringify(this.props.formattedValue)
            : null;
    };

    buildIcon = (name) => {
        const p = name.split(":");
        if (p[0] === "i") {
            return (
                <i className="material-icons" style={{fontSize: "1.2em"}}>
                    {p[1]}
                </i>
            );
        }
        return <span className={p[1]} />;
    };
}

export default LabeledField;
