import TranslationAction from "./TranslationAction";
import TranslationDefinition from "./TranslationDefinition";
import DefaultStoreUtil from "../../util/DefaultStoreUtil";

const Translation = {
    translation: DefaultStoreUtil.defaultReducerFunction(
        TranslationAction.CATEGORY,
        TranslationDefinition.defaultState
    )
};

export default Translation;
// #endregion
