// @ts-check

class ShipmentDefinition {
    static defaultFilter = {
        licensePlate: {value: ""},
        serviceType: {value: ""},
        serviceDate: {value: ""},
        status: {value: ""},
        transportPosition: {value: ""},
        pnr: {value: ""},
        fromPlace: {value: ""},
        fromDate: {value: ""},
        toPlace: {value: ""},
        toDate: {value: ""},
        referenceExt: {value: ""},
        trucker: {value: ""},
        employeeID: {value: ""},
        creDat: {value: ""}
    };

    static defaultState = {
        entries: [],
        selectionUUIDs: [],
        filter: ShipmentDefinition.defaultFilter,
        sort: {sortField: "creDat", sortOrder: -1},
        rows: 50,
        first: 0,
        totalRecords: 0
    };
}

export default ShipmentDefinition;
