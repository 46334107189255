import {prioritizedRequest, simpleRequest} from "../util/RequestUtil";
import UriList from "../service/EndpointsList";
import ErrorUtil from "../util/ErrorUtil";
import TranslationUtil from "../util/TranslationUtil";

class SettingsRequest {
    static async loadFeatures(params) {
        const defaultSort = [
            {
                sortField: "sortOrder",
                sortOrder: 1
            }
        ];

        const response = await prioritizedRequest(UriList.SETTING_FEATURE, {
            params: {
                ...params,
                sort: params && params.sort && params.sort.length ? params.sort : defaultSort
            }
        });
        return response;
    }

    static async loadDocumentSettings(params) {
        const response = await prioritizedRequest(
            UriList.SETTING_DOCUMENT,
            params ? {params: params} : undefined
        );
        return response;
    }

    static async loadDocumentSettingsForFeature(featureUUID, params) {
        if (!featureUUID) {
            return Promise.reject(
                ErrorUtil.createError(ErrorUtil.LEVEL.ERROR, "Missing feature UUID!")
            );
        }
        const response = await prioritizedRequest(
            UriList.SETTING_DOCUMENT + "/" + featureUUID,
            params ? {params: params} : undefined
        );
        return response;
    }

    static async loadSelectableDocumentTypes(featureUUID) {
        if (!featureUUID) {
            return Promise.reject(
                ErrorUtil.createError(ErrorUtil.LEVEL.ERROR, "Missing feature UUID!")
            );
        }
        const response = await prioritizedRequest(UriList.SETTING_DOCUMENT_TYPE(featureUUID));
        const t = TranslationUtil.getT();
        response.data = response.data.data
            .map((entry) => {
                entry.translation = t(entry.label);
                return entry;
            })
            .sort((typeA, typeB) => {
                return typeA.translation > typeB.translation ? 1 : -1;
            });
        return response;
    }

    static async persistDocumentSettings(featureUUID, settings) {
        if (!featureUUID) {
            return Promise.reject(
                ErrorUtil.createError(ErrorUtil.LEVEL.ERROR, "Missing feature UUID!")
            );
        }
        if (!settings || !settings.length) {
            return Promise.reject(
                ErrorUtil.createError(ErrorUtil.LEVEL.ERROR, "Cannot persist a empty settings!")
            );
        }
        const response = await prioritizedRequest(UriList.SETTING_DOCUMENT + "/" + featureUUID, {
            method: "PUT",
            body: JSON.stringify({data: settings}),
            formdata: true
        });
        return response;
    }

    static async persistInspectionSettings(country, settings) {
        if (!country || country.length !== 2) {
            return Promise.reject(
                ErrorUtil.createError(
                    ErrorUtil.LEVEL.ERROR,
                    "Missing or incorrenct country code: " + country
                )
            );
        }
        if (!settings || !settings.length) {
            return Promise.reject(
                ErrorUtil.createError(ErrorUtil.LEVEL.ERROR, "Cannot persist a empty settings!")
            );
        }
        const response = await prioritizedRequest(UriList.SETTING_INSPECTION + "/" + country, {
            method: "PUT",
            body: JSON.stringify({data: settings}),
            formdata: true
        });
        return response;
    }

    static async persistTireSettingsBrand(brands) {
        const response = await prioritizedRequest(UriList.SETTING_TIRE_BRAND, {
            method: "POST",
            body: JSON.stringify({data: brands}),
            formdata: true
        });
        return response;
    }

    static async persistTireSettingsExchangeReason(settings) {
        const response = await prioritizedRequest(UriList.SETTING_TIRE_PARTNER_REASON, {
            method: "PUT",
            body: JSON.stringify(settings),
            formdata: true
        });
        return response;
    }

    static async persistMiscSettings(settings) {
        const response = await prioritizedRequest(UriList.SETTING_SETTING + "/" + settings.uuid, {
            method: "PUT",
            body: JSON.stringify(settings),
            formdata: true
        });
        return response;
    }

    static async persistTrailer4Sale(data) {
        const response = await prioritizedRequest(UriList.SETTING_TRAILER4SALE, {
            method: "PUT",
            body: JSON.stringify(data),
            formdata: true
        });
        return response;
    }

    static async loadInspectionSettings(params) {
        const response = await prioritizedRequest(
            UriList.SETTING_INSPECTION,
            params ? {params: params} : undefined
        );
        return response;
    }

    static async loadTireBrands() {
        const response = await prioritizedRequest(UriList.SETTING_TIRE_BRAND);
        return response;
    }

    static async disableTireBrand(id) {
        const response = await prioritizedRequest(
            UriList.SETTING_TIRE_BRAND + "/" + id + "/deactivate",
            {
                method: "PUT"
            }
        );
        return response;
    }

    static async enableTireBrand(id) {
        const response = await prioritizedRequest(
            UriList.SETTING_TIRE_BRAND + "/" + id + "/activate",
            {
                method: "PUT"
            }
        );
        return response;
    }

    static async loadTirePartnerReasons() {
        const response = await prioritizedRequest(UriList.SETTING_TIRE_PARTNER_REASON);
        return response;
    }

    static async loadTireFleetReasons() {
        const response = await prioritizedRequest(UriList.SETTING_TIRE_FLEET_REASON);
        return response;
    }

    static async loadOrderDeliveryMethod() {
        const response = await simpleRequest(UriList.SETTING_DELIVER_METHOD);
        return response;
    }

    static async loadDamageClose() {
        const response = await simpleRequest(UriList.SETTING_DAMAGE_CLOSE);
        return response;
    }

    static async loadOrderClose() {
        const response = await simpleRequest(UriList.SETTING_ORDER_CLOSE);
        return response;
    }

    static async loadTicketClose() {
        const response = await simpleRequest(UriList.SETTING_TICKET_CLOSE);
        return response;
    }

    static async loadTrailer4Sale() {
        const response = await prioritizedRequest(UriList.SETTING_TRAILER4SALE);
        return response;
    }

    static async loadCreditRatingLimit() {
        const response = await prioritizedRequest(UriList.SETTING_CREDIT_RATING_LIMIT);
        return response;
    }

    static async loadCreditRatingFuelCard() {
        const response = await prioritizedRequest(UriList.SETTING_CREDIT_RATING_FUEL_CARD);
        return response;
    }
}

export default SettingsRequest;
