// @ts-check

class DebtorDefinition {
    static defaultCauser = {
        uuid: null,
        servicepartnerUUID: null,
        servicepartnerReferenceID: "",
        servicepartnerName1: "",
        servicepartnerEmail: "",
        servicepartnerCountry: "",
        servicepartnerPlace: "",
        servicepartnerZIP: "",
        freeAddress: false,
        sendAck: 0,
        flagHH: 0,
        flagKV: 0,
        flagGU: 0,
        flagAKZ: 0,
        flagABL: 0,
        flagABGR: 0
    };

    static defaultCauserFree = {
        uuid: null,
        servicepartnerUUID: null,
        servicepartnerReferenceID: "",
        servicepartnerName1: "",
        servicepartnerEmail: "",
        servicepartnerCountry: "",
        servicepartnerPlace: "",
        servicepartnerZIP: "",
        freeAddress: true,
        sendAck: 0,
        flagHH: 0,
        flagKV: 0,
        flagGU: 0,
        flagAKZ: 0,
        flagABL: 0,
        flagABGR: 0
    };

    static defaultDetailState = {
        uuid: null,
        element: {
            uuid: null,
            debtorID: "",
            vehicleUUID: null,
            condition: 0,
            assignDate: null,
            note: "",
            reminderDate: null,
            user: {
                uuid: null,
                fullname: "",
                firstname: "",
                lastname: ""
            },
            state: {
                uuid: null,
                label: ""
            },
            trailer: {
                uuid: null,
                licensePlate: "",
                iluCode: "",
                vin: "",
                assetType: "",
                firstRegistration: null,
                stateColor: ""
            },
            ticket: {
                uuid: null,
                ticketID: "",
                ticketLocation: "",
                ticketDat: null
            },
            order: {
                uuid: null,
                orderID: "",
                orderLocation: "",
                orderDat: null
            },
            causer: [],
            shipment: {
                transportPosition: 0,
                services: []
            },
            damages: [],
            files: []
        }
    };

    static defaultFilter = {
        user: {value: ""},
        stateUUID: {
            name: "stateUUID",
            value: ["e1afcc5d-0a17-4435-98e1-6e759adcae79", "ee62049c-e6df-4051-9aa5-fc06948314c8"],
            matchMode: "in"
        }
    };

    static defaultState = {
        entries: [],
        selectionUUIDs: [],
        detail: DebtorDefinition.defaultDetailState,
        filter: DebtorDefinition.defaultFilter,
        sort: {sortField: "debtorID", sortOrder: -1},
        rows: 50,
        first: 0,
        totalRecords: 0
    };
}

export default DebtorDefinition;
