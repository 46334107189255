import React from "react";
import MessagesUtil from "../../util/MessagesUtil";
import MessagesConsumer from "./MessagesConsumer";
import config from "../../config";

class GlobalMessagesDisplay extends React.PureComponent {
    parseMessage = (message) => {
        return {
            severity: message.level,
            summary: message.title,
            detail: message.text,
            sticky: message.level === "error" ? true : false,
            life:
                message.level === "success"
                    ? config.LIFETIME_GLOBAL_SUCCESS_MSG
                    : config.LIFETIME_GLOBAL_MSG
        };
    };

    render() {
        return (
            <div className="f-global-messages">
                <MessagesConsumer
                    messageType={MessagesUtil.TYPES.GLOBAL}
                    parseMessage={this.parseMessage}
                    translate
                />
            </div>
        );
    }
}

export default GlobalMessagesDisplay;
