import DefaultStoreUtil from "../../util/DefaultStoreUtil";

class TranslationAction {
    static CATEGORY = "TRANSLATION";

    // #region Translation Overview

    static DATATABLE = "TRANSLATION_DATATABLE";
    static SELECTION = "TRANSLATION_SELECTION";
    static CLEAR = "TRANSLATION_CLEAR";
    static LOADING = "TRANSLATION_LOADING";
    static DELETE = "TRANSLATION_DELETE";
    // #endregion

    // #region Translation Dialog
    static DIALOG_LANGUAGES = "TRANSLATION_DIALOG_LANGUAGES";
    static DIALOG_TRANSLATION = "TRANSLATION_DIALOG_TRANSLATION";
    static DIALOG_CLEAR = "TRANSLATION_DIALOG_CLEAR";
    // #endregion

    static create = DefaultStoreUtil.defaultCreateAction(TranslationAction.CATEGORY);
}

export default TranslationAction;
