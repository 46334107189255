import {simpleRequest, prioritizedRequest} from "../util/RequestUtil";
import UriList from "../service/EndpointsList";
import TranslationUtil from "../util/TranslationUtil";

class FileRequest {
    static async requestFileToken(currentToken) {
        if (currentToken) {
            const validCheck = await simpleRequest(UriList.FILE_TOKEN + "/" + currentToken);
            if (validCheck.token === "valid") {
                return currentToken;
            }
        }
        const response = await simpleRequest(UriList.FILE_TOKEN);
        return response.token;
    }

    static async loadDocumentTypes(reqParams) {
        const response = await simpleRequest(
            UriList.FILE_DOCTYPE,
            reqParams ? {params: reqParams} : undefined
        );

        const t = TranslationUtil.getT();
        const fixed = response.filter((t) => t.sort > 0);
        const alphabetic = response.filter((t) => !t.sort);

        fixed.sort((a, b) => (a.sort < b.sort ? -1 : 1));
        alphabetic.sort((a, b) => (t(a.label).toUpperCase() < t(b.label).toUpperCase() ? -1 : 1));

        return fixed.concat(alphabetic);
    }

    static async sendFiles(uuid, sendingInformation) {
        const emailData = Object.assign(sendingInformation, {
            referenceType: "email",
            vehicleUUID: uuid,
            body: sendingInformation.note,
            to: sendingInformation.to,
            cc: sendingInformation.cc
        });
        const response = await prioritizedRequest(UriList.EMAIL_FILE, {
            method: "POST",
            body: JSON.stringify(emailData),
            formdata: true
        });
        return response;
    }
}

export default FileRequest;
