import moment from "moment";
import DateUtil from "../../util/DateUtil";

//@ts-check
class DashboardDefinition {
    static data = () => {
        return {
            total: 0,
            chartData: {
                labels: [],
                datasets: []
            },
            detail: []
        };
    };

    static defaultExporter = {
        ticket: [],
        order: [],
        debtor: [],
        inspection: [],
        trailer: []
    };

    static defaultStats = (additionalFilter) => {
        return {
            loadingStats: false,
            kuf: DashboardDefinition.data(),
            wnd: DashboardDefinition.data(),
            query: {
                filter: {
                    locationOwner: {
                        name: "locationOwner",
                        matchMode: "endswith",
                        value: ""
                    },
                    ...additionalFilter
                },
                first: 0,
                totalRecords: 0
            }
        };
    };

    static defaultState = {
        ticket: DashboardDefinition.defaultStats({
            ticketDat: {
                name: "ticketDat",
                matchMode: "<=",
                value: DateUtil.toDb(
                    moment()
                        .subtract(1, "days")
                        .endOf("day")
                        .toDate()
                )
            }
        }),
        order: DashboardDefinition.defaultStats(),
        debtor: DashboardDefinition.defaultStats(),
        inspection: DashboardDefinition.defaultStats({
            sendDate: {
                fromDate: {
                    name: "sendDate",
                    matchMode: ">=",
                    value: DateUtil.toDb(
                        moment()
                            .startOf("month")
                            .startOf("day")
                            .toDate()
                    )
                },
                toDate: {
                    name: "sendDate",
                    matchMode: "<=",
                    value: DateUtil.toDb(
                        moment()
                            .endOf("month")
                            .endOf("day")
                            .toDate()
                    )
                }
            }
        }),
        trailer: DashboardDefinition.defaultStats({
            customerState: {
                name: "customerState",
                matchMode: "!=",
                value: "R"
            }
        }),
        tm: {
            data: {
                summaryOrder: {},
                summaryTicket: {},
                detailOrder: {},
                detailTicket: {}
            },
            loadingStats: false
        },
        ws: {
            data: {
                summaryOrder: {},
                summaryTicket: {},
                detailOrder: {},
                detailTicket: {}
            },
            loadingStats: false
        }
    };
}

export default DashboardDefinition;
