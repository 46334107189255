import DefaultStoreUtil from "../../util/DefaultStoreUtil";
import InspectionAction from "./InspectionAction";
import InspectionDefinition from "./InspectionDefinition";

const Inspection = {
    inspection: DefaultStoreUtil.defaultReducerFunction(
        InspectionAction.CATEGORY,
        InspectionDefinition.defaultState
    )
};
export default Inspection;
// #endregion
