class TasksDefinition {
    static defaultFilter = {
        isDone: {
            value: ["0"],
            matchMode: "In",
            name: "isDone",
        }
    };

    static defaultState = {
        filter: TasksDefinition.defaultFilter,
        sort: null,
        rows: 50,
        first: 0,
        totalRecords: 0,
        selection: "",
        selectionUUIDs: [],
        entries: [],
        activeTasks: 0
    };

    static defaultDetailState = {};
    static defaultDialogState = {};
}

export default TasksDefinition;
